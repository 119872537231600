import { autoinject, observable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { TableHeader } from 'components/table/table-content';
import { PaginationHandler } from 'elements/pagination-handler';
import {
  CreateUpdateMooringSalesOrder,
  MooringSalesOrderLineListItem,
  MooringSalesOrderListItem,
  UnitOfMeasure,
} from 'models/mooring';
import { ErrorService } from 'services/error-service';
import { ToastService } from 'services/toast-service';
import { Router } from 'aurelia-router';
import { DataFilter } from 'lib/tables/DataFilter';
import { PubSub } from 'lib/event/PubSub';
import { getCallbackData } from 'elements/filterer';
import { Filters, filterTypes } from 'elements/Filter';
import { DialogService } from 'aurelia-dialog';
import { MooringSalesOrderService } from 'services/mooring-sales-order-service';
import { SalesOrderDialog } from 'views/mooring/mooring-salesorder/salesorder-dialog';
import { CustomerService } from 'services/customer-service';

type TableFilter = {
  customerIds?: string;
  batchNumber?: string;
} & DataFilter;

type OrderLines = Omit<MooringSalesOrderListItem & MooringSalesOrderLineListItem, 'OrderLines'> & {
  _identifier: number;
  _lineCount?: number;
  _orderIndex?: number;
};

@autoinject()
export class MooringSalesOrdersContainer {
  protected salesOrders: MooringSalesOrderListItem[] = [];
  protected orders: OrderLines[] = [];

  protected dataFilter: TableFilter = new DataFilter();

  protected tableHeaders: (TableHeader<MooringSalesOrderListItem & MooringSalesOrderLineListItem & { actions: '' }> & {
    noColumnMainRow?: boolean;
  })[] = [
    { key: 'CertificatesZipId', label: '', sortable: false },
    { key: 'AquacomSyncStatus', label: 'A', sortable: false },
    { key: 'Id', label: 'general.id', sortable: true },
    { key: 'CustomerName', label: 'general.customer', sortable: true },
    { key: 'LocalityName', label: 'general.locality', sortable: true },
    { key: 'OrderNumber', label: 'general.orderNumber', sortable: true },
    { key: 'InvoiceNumber', label: 'general.invoicenr', sortable: true },
    { key: 'ContactName', label: 'general.contactPerson', sortable: true },
    // { key: 'ContactEmail', label: 'general.email', sortable: true },
    { key: 'Note', label: 'general.note', sortable: true },
    { key: 'ArticleNumber', label: 'general.articleno', sortable: false },
    { key: 'ProductName', label: 'general.product', sortable: false, noColumnMainRow: true },
    { key: 'BatchNumber', label: 'general.batchNumber', sortable: false, noColumnMainRow: true },
    { key: 'CertificateId', label: 'general.certificate', sortable: false, noColumnMainRow: true },
    { key: 'Quantity', label: 'general.quantity', sortable: false, noColumnMainRow: true },
    { key: 'UnitOfMeasure', label: 'general.unitOfMeasure', sortable: false, noColumnMainRow: true },
    { key: 'actions', label: '', sortable: false },
  ];

  protected visibleOrders: { [key: number]: boolean } = {};

  protected toggleVisibleOrder(id: number) {
    if (this.visibleOrders[id]) {
      this.visibleOrders[id] = false;
    } else {
      this.visibleOrders[id] = true;
    }
  }

  constructor(
    private errorService: ErrorService,
    private salesOrderService: MooringSalesOrderService,
    private toast: ToastService,
    private router: Router,
    private pagination: PaginationHandler,
    private dialogService: DialogService,
    private pubsub: PubSub,
    private customerService: CustomerService,
    private t: I18N
  ) {}

  protected setupPagination() {
    const routeParams = this.router.currentInstruction?.queryParams;
    let currentPage = 1;
    if (routeParams && routeParams.currentPage) {
      currentPage = +routeParams.currentPage;
    }

    this.pubsub.sub('filter-section:search-text-changed', (data) => {
      if (data.context !== this.context) return;
      this.dataFilter.searchText = data.searchText;
      void this.getSalesOrders();
    });

    this.pubsub.sub('filter-section:reset-filters', (data) => {
      if (data.context !== this.context) return;
      this.filterClearAll();
    });

    this.pubsub.sub('filter-section:list-settings-changed', (data) => {
      if (this.dataFilter.top === data.settings.pageSize) return;

      this.dataFilter.top = data.settings.pageSize;
      this.pagination.pageSize = data.settings.pageSize;
      this.pagination.currentPage = 1;
      void this.getSalesOrders();
    });

    this.pubsub.sub('export-list', (data) => {
      if (data === 'mooring-orders') {
        void this.getSalesOrders({ export: true });
      }
    });
    this.pubsub.sub('list-entity:created', () => {
      void this.getSalesOrders();
    });
    this.pubsub.sub('list-entity:updated', () => {
      void this.getSalesOrders();
    });

    this.pagination.init({
      currentPage: currentPage,
      totalItems: 0,
      onPageChanged: (_, pagination) => {
        this.dataFilter.skip = pagination.skip;
        this.dataFilter.top = pagination.top;
        void this.getSalesOrders();
      },
    });
  }

  protected async onDelete(id: number) {
    try {
      if (id) {
        this.toast.showSuccess(this.t.tr('general.deleted'));
        await this.getSalesOrders();
      }
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected async onSave(order: CreateUpdateMooringSalesOrder) {
    try {
      if (order.Id) {
        await this.salesOrderService.update(order, order.Id);
      } else {
        await this.salesOrderService.create(order);
      }
      this.toast.showSuccess(this.t.tr('general.saved'));
      await this.getSalesOrders();
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected editSalesOrder(_: number, id: number) {
    void this.dialogService
      .open({
        viewModel: SalesOrderDialog,
        model: {
          salesOrderId: id,
        },
        position: () => {
          return {
            top: 0,
          };
        },
      })
      .whenClosed(() => {
        document.querySelector('html').style.overflowY = null;
        void this.getSalesOrders();
      });
  }

  protected getUnitOfMeasure(unit: UnitOfMeasure) {
    if (unit == UnitOfMeasure.meter) return this.t.tr('general.meter_short');
    if (unit == UnitOfMeasure.pieces) return this.t.tr('general.qty');
    return '';
  }

  protected attached() {
    this.setupPagination();
    void this.getSalesOrders();
  }

  protected detached() {
    this.pubsub.unsub();
  }

  protected setOrderByColumn(column: string) {
    this.dataFilter.setOrderByAndFlipOrderDirectionIfSameKey(column);
    void this.getSalesOrders();
  }

  protected context = 'mooring_sales_orders';
  protected filterClearAll: () => void;
  protected filterClearSingle: (name: string) => void;
  protected filterToggleVisible: () => void;
  protected filterGetFiltersQuery: () => Promise<{ customerIds?: string[] }>;
  protected setFilterValueByName: (name: string, data: any) => void;

  protected defaultFilters = {
    customerIds: true,
  };

  protected getFilterKey() {
    return 'MOORING_SALES_ORDERS_TABLE';
  }

  protected onFilterChanged() {
    void this.getSalesOrders();
  }

  getFilterValues() {
    return getCallbackData(this.filterGetFiltersQuery);
  }

  @observable
  protected batchNumberSearch: string | undefined = undefined;
  batchNumberSearchChanged(newValue: string) {
    this.dataFilter.batchNumber = newValue;
    this.onFilterChanged();
  }

  protected async setupFilters() {
    return await new Promise((res: (v: Filters) => void) => {
      setTimeout(async () => {
        const filters: Filters = {
          customerIds: {
            name: 'customerIds',
            label: this.t.tr('general.customer'),
            type: filterTypes.CHECKBOX,
            query: 'customerIds',
            options: (await this.customerService.getAll())
              .filter((x) => !x.IsDeleted)
              .map((x) => ({ Id: x.Id, Name: x.Name })),
          },
        };
        res(filters);
      });
    });
  }

  protected async getSalesOrders(options?: { export: boolean }) {
    try {
      const filters = await this.filterGetFiltersQuery?.();
      this.dataFilter.export = options?.export || false;
      this.dataFilter.customerIds = filters?.customerIds?.join(',');
      // this.dataFilter.locale = this.i18n.getLocale() as 'en' | 'nb' | 'es';

      const response = await this.salesOrderService.table(this.dataFilter);
      if (!response) return;
      this.pagination.totalItems = +response.headers.get('x-total-count') || 0;
      this.salesOrders = response.data;

      const lines: OrderLines[] = [];
      let i = 0;
      for (const order of this.salesOrders) {
        lines.push({
          Id: order.Id,
          _lineCount: order.OrderLines.length || order.FrameOrderLines.length || 0,
          _identifier: order.Id,
          _orderIndex: i++,
          CustomerName: order.CustomerName,
          LocalityName: order.LocalityName,
          OrderNumber: order.OrderNumber,
          InvoiceNumber: order.InvoiceNumber,
          ContactName: order.ContactName,
          ContactEmail: order.ContactEmail,
          Note: order.Note,
          SalesOrderId: undefined,
          ArticleProductId: undefined,
          UnitOfMeasure: undefined,
          CertificateId: undefined,
          Quantity: undefined,
          CertificatesZipId: order.CertificatesZipId,
          ArticleNumber: '',
          ProductName: '',
          BatchNumber: '',
          BatchNumberTo: '',
          Category: '',
          Type: '',
          Product: '',
          AquacomSyncStatus: order.AquacomSyncStatus,
          PrimaryDimension: undefined,
          PrimaryDimensionType: undefined,
          SecondaryDimension: undefined,
          SecondaryDimensionType: undefined,
          Dimension: undefined,
          ProductId: undefined,
          FrameOrderLines: [],
        });

        for (const line of order.OrderLines) {
          lines.push({
            Id: undefined,
            _identifier: order.Id,
            CustomerName: '',
            LocalityName: '',
            OrderNumber: '',
            InvoiceNumber: '',
            ContactName: '',
            Category: '',
            Type: '',
            Product: '',
            SalesOrderId: line.SalesOrderId,
            ArticleProductId: line.ArticleProductId,
            UnitOfMeasure: line.UnitOfMeasure,
            CertificateId: line.CertificateId,
            Quantity: line.Quantity,
            ArticleNumber: line.ArticleNumber,
            ProductName: line.ProductName,
            BatchNumber: line.BatchNumber,
            BatchNumberTo: line.BatchNumberTo,
            ActualBatchNumber: line.ActualBatchNumber,
            PrimaryDimension: undefined,
            PrimaryDimensionType: undefined,
            SecondaryDimension: undefined,
            SecondaryDimensionType: undefined,
            Dimension: undefined,
            ProductId: line.ProductId,
            FrameOrderLines: [],
          });
        }

        for (const line of order.FrameOrderLines) {
          lines.push({
            Id: undefined,
            _identifier: order.Id,
            CustomerName: '',
            LocalityName: '',
            OrderNumber: '',
            InvoiceNumber: '',
            ContactName: '',
            Category: '',
            Type: '',
            Product: '',
            ProductId: undefined,
            PrimaryDimension: undefined,
            PrimaryDimensionType: undefined,
            SecondaryDimension: undefined,
            SecondaryDimensionType: undefined,
            Dimension: undefined,
            ArticleProductId: 0,
            SalesOrderId: line.SalesOrderId,
            UnitOfMeasure: line.UnitOfMeasure,
            CertificateId: line.CertificateId,
            Quantity: line.Quantity,
            ArticleNumber: line.ArticleNumber,
            ProductName: line.ProductName,
            BatchNumber: line.BatchNumber,
            BatchNumberTo: line.BatchNumberTo,
            ActualBatchNumber: line.ActualBatchNumber,
            FrameOrderLines: [],
          });
        }
      }
      this.orders = lines;
    } catch (error) {
      this.errorService.handleError(error);
    }
  }
}
