import { autoinject } from 'aurelia-framework';
import { MooringCertificateTemplate } from 'models/mooring';
import { AuthHttpClient } from 'services/auth-http-client';
import { BaseApiService } from 'services/base-api-service';

@autoinject
export class MooringCertificateTemplateService extends BaseApiService<MooringCertificateTemplate> {
  constructor(httpClient: AuthHttpClient) {
    super(httpClient, MooringCertificateTemplate);
  }

  public async getForMooringStation(mooringStationId: number) {
    return await this.httpClient.exec<MooringCertificateTemplate[]>(
      `${MooringCertificateTemplate.ApiUrl}/mooring-station/${mooringStationId}`,
      {
        method: 'GET',
      }
    );
  }
}
