import { autoinject } from 'aurelia-framework';
import { MooringSupplier } from 'models/mooring/MooringSupplier';
import { AuthHttpClient } from 'services/auth-http-client';
import { BaseApiService } from 'services/base-api-service';

@autoinject
export class SupplierService extends BaseApiService<MooringSupplier> {
  constructor(httpClient: AuthHttpClient) {
    super(httpClient, MooringSupplier);
  }
}
