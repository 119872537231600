import { autoinject } from 'aurelia-framework';
import { NetlogHttpClient } from 'lib/http';
import { AnalysisCompany } from 'models/AnalysisCompany';

@autoinject
export class AnalysisCompanyService {
  constructor(private client: NetlogHttpClient) {}

  async getCompany(id: number) {
    return await this.client.get<AnalysisCompany>({
      url: `api/analysis-companies/${id}`,
      model: AnalysisCompany,
    });
  }

  async getCompanies() {
    return await this.client.get<AnalysisCompany[]>({
      url: `api/analysis-companies`,
      model: AnalysisCompany,
    });
  }
}
