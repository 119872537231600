import { Exclude, Expose } from 'class-transformer';
import { CreateMooringSiteSetupDto, MooringSiteSetupDto } from './MooringSiteSetup';

export const MooringOfferStatus = {
  Playground: 1,
  InProgress: 2,
  Review: 3,
  ReviewApproved: 4,
  RequiresCorrection: 5,
  Sent: 6,
  Accepted: 7,
  Rejected: 8,
  Cancelled: 9,
} as const;

export type MooringOfferStatus = (typeof MooringOfferStatus)[keyof typeof MooringOfferStatus];

export const MooringOfferStatusTranslation: { [key in MooringOfferStatus]: string } = {
  '1': 'mooring.status.playground',
  '2': 'mooring.status.inProgress',
  '3': 'mooring.status.review',
  '4': 'mooring.status.reviewApproved',
  '5': 'mooring.status.requiresCorrection',
  '6': 'mooring.status.sent',
  '7': 'mooring.status.accepted',
  '8': 'mooring.status.rejected',
  '9': 'mooring.status.cancelled',
};

export const ValidStatusForReview = [MooringOfferStatus.InProgress, MooringOfferStatus.RequiresCorrection, MooringOfferStatus.Playground];

@Exclude()
export class MooringOfferListItemDto {
  @Expose() Id: number;
  @Expose() Title: string;
  @Expose() Status: number;
  @Expose() Created: string;

  @Expose() Contact?: string;
  @Expose() ContactId?: number;
  @Expose() CrmReference?: string;

  @Expose() Customer?: string;
  @Expose() CustomerId?: number;

  @Expose() Locality?: string;
  @Expose() LocalityId?: number;
  @Expose() LocalityNumber?: string;
}

export class PersonInfoDto {
  Id: string;
  Name: string;
  Email: string;
}

export class CustomerInfoDto {
  Id: string;
  Name: string;
}

@Exclude()
export class MooringOfferDto {
  @Expose() Id: number;
  @Expose() Title: string;

  @Expose() CustomerId: number;
  @Expose() Customer: CustomerInfoDto;

  @Expose() ContactId: number;
  @Expose() Contact: PersonInfoDto;

  @Expose() SalesPersonId: string;
  @Expose() SalesPerson: PersonInfoDto;

  @Expose() SiteId: number;

  @Expose() MooringId: number;
  @Expose() SiteSetupId: number;

  @Expose() SiteSetup: MooringSiteSetupDto;

  @Expose() ActiveReviewId?: number;

  @Expose() SiteNumber: string;
  @Expose() AnalysisReference: string;

  @Expose() AnalysisCompanyId: number;
  @Expose() AnalysisCompanyName: string;

  @Expose() AnalysisAttachmentId: string;
  @Expose() CrmReference: string;

  @Expose() Hs: number;
  @Expose() Vc: number;

  @Expose() Status: MooringOfferStatus;
  @Expose() StatusName: string;

  @Expose()
  CreatedAt: Date;

  @Expose()
  UpdatedAt: Date;
}

@Exclude()
export class CreateMooringOfferDto {
  @Expose() CustomerId: number;
  @Expose() ContactId: number;
  @Expose() SiteId: number;
  @Expose() SalesPersonId: string;
  @Expose() Title: string;
  @Expose() SiteNumber: string;
  @Expose() CrmReference: string;
  @Expose() AnalysisReference: string;
  @Expose() AnalysisCompanyId: number;
  @Expose() Hs: number;
  @Expose() Vc: number;
  @Expose() StatusId: number;
  @Expose() SiteSetup: CreateMooringSiteSetupDto;
}

@Exclude()
export class UpdateMooringOfferDto extends CreateMooringOfferDto {}
