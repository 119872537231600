import { Expose } from 'class-transformer';

export class BaseEntity {
  @Expose()
  Id: number;
  @Expose()
  Created?: Date;
  @Expose()
  Updated?: Date;
  @Expose()
  IsDeleted: boolean;
}

export class BaseEntityWithMultilanguageName extends BaseEntity {
  @Expose()
  Name: string;
  @Expose()
  NameEn: string;
  @Expose()
  NameEs: string;
}

export class CreatedEntity {
  @Expose()
  Id: number;
}
