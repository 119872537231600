import { Exclude, Expose } from 'class-transformer';

@Exclude()
export class MooringSiteSetupDto {
  @Expose() Id: number;
  @Expose() CenterPositionLat: string;
  @Expose() CenterPositionLong: string;

  @Expose() NWPositionLat: string;
  @Expose() NWPositionLong: string;

  @Expose() NEPositionLat: string;
  @Expose() NEPositionLong: string;

  @Expose() SEPositionLat: string;
  @Expose() SEPositionLong: string;

  @Expose() SWPositionLat: string;
  @Expose() SWPositionLong: string;

  @Expose() NoCagesWidth: number;
  @Expose() NoCagesLength: number;

  @Expose() NoAnchorLines: number;
  @Expose() NoBridles: number;
  @Expose() CageSize: number;

  @Expose() PenCircumference: number;

  @Expose() MiddleGapWidth: number;
  @Expose() HasGapMiddle: boolean;

  @Expose() GridLineLength: number;
  @Expose() MooringDepth: number;
  @Expose() NetDepth: number;
  @Expose() ReliabilityClass: number;
}

@Exclude()
export class CreateMooringSiteSetupDto {
  @Expose() CenterPositionLat: string;
  @Expose() CenterPositionLong: string;

  @Expose() NWPositionLat: string;
  @Expose() NWPositionLong: string;

  @Expose() NEPositionLat: string;
  @Expose() NEPositionLong: string;

  @Expose() SEPositionLat: string;
  @Expose() SEPositionLong: string;

  @Expose() SWPositionLat: string;
  @Expose() SWPositionLong: string;

  @Expose() NoCagesWidth: number;
  @Expose() NoCagesLength: number;
  @Expose() NoAnchorLines: number;
  @Expose() NoBridles: number;
  @Expose() CageSize: number;

  @Expose() PenCircumference: number;

  @Expose() MiddleGapWidth: number;
  @Expose() HasGapMiddle: boolean;

  @Expose() GridLineLength: number;
  @Expose() MooringDepth: number;
  @Expose() NetDepth: number;
  @Expose() ReliabilityClass: number;
}
