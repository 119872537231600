import { autoinject, bindable } from 'aurelia-framework';
import { ListField } from 'models/ListSettings';
import { CommentForEntity, MooringBuoy, MooringComponent } from 'models/mooring';
import { MooringArticleService } from 'services';
import { MooringComponentSearchHandler } from 'services/mooring';
import { toKg, toM } from 'lib/ui/ValueDisplayFormatters';
import { MooringTableBase } from '../table-base';
import { TableEntryManager } from 'services/ui-helpers';
import { DialogService } from 'aurelia-dialog';
import { PubSub } from 'lib/event/PubSub';
import { ConfirmDialog } from 'components/dialogs/confirm/confirm-dialog';

@autoinject
export class BuoysTable extends MooringTableBase<MooringBuoy> {
  protected tableColumns: ListField[] = tableColumns;

  @bindable
  protected onSave: (args: { entries: MooringBuoy[]; canNavigateOnSave: boolean }) => void;

  constructor(
    dialogService: DialogService,
    mooringArticleService: MooringArticleService,
    // Used in HTML for article search
    mooringComponentSearchHandler: MooringComponentSearchHandler,
    tableEntryManager: TableEntryManager,
    pubsub: PubSub,
    confirmation: ConfirmDialog
  ) {
    super('buoy', dialogService, mooringComponentSearchHandler, tableEntryManager, mooringArticleService, pubsub, confirmation);

    pubsub.sub('list-entity:updated', (data) => {
      if (!data) return;
      if (data.name === 'mooring-article-component') {
        this.onSave({
          entries: this.entries,
          canNavigateOnSave: false,
        });
      }
    });

    pubsub.sub('tabs:change-guard', async () => {
      if (this.changeTracker.areEqual(this.entries)) {
        this.pubsub.publish('tabs:change-guard-ok', null);
        return;
      }

      const res = await this.confirmation.confirmSave();

      if (res == 'cancel') {
        return;
      } else if (res === 'discard-continue') {
        this.pubsub.publish('tabs:change-guard-ok', null);
      } else {
        this.onSave({ entries: this.entries, canNavigateOnSave: true });
      }
    });
  }

  async attached() {
    await super.attached();
  }

  protected sortTable() {
    console.log('sortTable');
  }

  protected save() {
    this.onSave({
      entries: this.entries,
      canNavigateOnSave: false,
    });
  }

  protected addEntryComment(component: MooringBuoy) {
    this.openCommentDialog({
      mooringId: this.mooringId,
      isReadOnly: false,
      componentId: component.Id,
      componentType: CommentForEntity.Buoy,
    });
  }

  protected addComponentComment(component: MooringComponent) {
    this.openCommentDialog({
      mooringId: this.mooringId,
      isReadOnly: false,
      componentId: component.Id,
      componentType: CommentForEntity.BuoyComponent,
    });
  }

  protected unbind() {
    this.pubsub.unsub();
    super.unbind();
  }
}

const tableColumns: ListField[] = [
  {
    field: 'MoorlogNo',
    title: 'mooring.buoyNo',
    selected: true,
    visible: true,
    filterType: 'TEXT',
    transform: (value: string) => value,
    inputType: 'text',
  },
  {
    field: 'AnalysisNo',
    title: 'mooring.analysisNo',
    selected: true,
    visible: true,
    filterType: 'TEXT',
    transform: (value: string) => value,
    inputType: 'text',
  },
  {
    field: 'SizeKg',
    title: 'mooring.sizeKg',
    selected: true,
    visible: true,
    filterType: 'TEXT',
    transform: toKg,
    inputType: 'float',
  },
  {
    field: 'PullDownKg',
    title: 'mooring.pullDownKg',
    selected: true,
    visible: true,
    filterType: 'TEXT',
    transform: toKg,
    inputType: 'float',
  },
  {
    field: 'ResidualDriftKg',
    title: 'mooring.residualDrift',
    selected: true,
    visible: true,
    filterType: 'TEXT',
    transform: toKg,
    inputType: 'float',
  },
  {
    field: 'PullDownMeter',
    title: 'mooring.pullDownM',
    selected: true,
    visible: true,
    filterType: 'TEXT',
    transform: toM,
    inputType: 'float',
  },
  {
    field: 'ResidualPullDownMeter',
    title: 'mooring.residualPullDownM',
    selected: true,
    visible: true,
    filterType: 'TEXT',
    transform: toM,
    inputType: 'float',
  },
  {
    field: 'actions',
    title: '',
    selected: true,
    visible: true,
    filterType: 'none',
  },
];
