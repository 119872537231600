import { autoinject } from 'aurelia-framework';
import { Models } from 'models/core';
import { AuthHttpClient } from 'services/auth-http-client';
import { BaseApiService } from 'services/base-api-service';

@autoinject
export class DisinfectionTypeService extends BaseApiService<Models.DisinfectionType> {
  constructor(httpClient: AuthHttpClient) {
    super(httpClient, Models.DisinfectionType);
  }

  public async checkDuplicate(data: Models.CheckDuplicateDisinfectionType) {
    return await this.httpClient.exec<{ IsDuplicate: boolean }>(`${Models.DisinfectionType.ApiUrl}/checkDuplicate`, {
      method: 'POST',
      body: JSON.stringify(data),
    });
  }
}
