import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { ConfirmDialog } from 'components/dialogs/confirm/confirm-dialog';
import { MooringArticleCategory } from 'models/mooring';
import { MooringArticleCategoryService } from 'services';
import { ErrorService } from 'services/error-service';
import { ToastService } from 'services/toast-service';

@autoinject()
export class MooringArticleCategoryContainer {
  protected headers: {
    title: string;
  }[] = [];

  constructor(
    private errorService: ErrorService,
    private mooringArticleCategoryService: MooringArticleCategoryService,
    private toast: ToastService,
    private t: I18N,
    private confirm: ConfirmDialog
  ) {}

  protected attached() {
    this.createHeaders();
    void this.getCategories();
  }

  protected categories: MooringArticleCategory[] = [];
  protected async getCategories() {
    this.categories = await this.mooringArticleCategoryService.getAll('?$expand=MooringArticleCategoryType');
  }

  protected async onDelete(id: number) {
    try {
      if (id) {
        const confirmed = await this.confirm.confirmDelete();
        if (!confirmed) {
          return;
        }
        await this.mooringArticleCategoryService.delete(id);
        this.toast.showSuccess(this.t.tr('general.deleted'));
        await this.getCategories();
      }
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected async onSave(category: MooringArticleCategory) {
    try {
      if (category.Id) {
        delete category.MooringArticleCategoryType;
        await this.mooringArticleCategoryService.put(category, category.Id);
      } else {
        await this.mooringArticleCategoryService.post(category);
      }
      this.toast.showSuccess(this.t.tr('general.saved'));
      await this.getCategories();
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected isCreating = false;
  protected editingCategory: number | undefined = undefined;
  protected setEditingCategory(id: number) {
    this.isCreating = false;
    this.editingCategory = id;
  }

  protected setIsCreatingCategory(isCreating: boolean) {
    this.editingCategory = undefined;
    this.isCreating = isCreating;
  }

  private createHeaders() {
    this.headers = [
      { title: this.t.tr('general.name') },
      { title: this.t.tr('general.name-en') },
      { title: this.t.tr('general.name-es') },
      { title: this.t.tr('general.type') },
      { title: this.t.tr('general.aquacomId') },
      { title: this.t.tr('general.actions') },
    ];
  }
}
