import './MnTabs.scss';
import { Router } from 'aurelia-router';

import { autoinject, bindable } from 'aurelia-framework';
import { PubSub } from 'lib/event/PubSub';

export type Tab = {
  title: string;
  view: string;
  name: string;
  // If a guard is set, an event of tabs:change-guard will be emitted, and the tab is not changed
  // until it receives change-guard-ok.
  guard?: boolean;
  data?: any;
};

@autoinject()
export class MnTabs {
  protected activeTabIndex = -1;
  protected guardedTab?: number | undefined;

  @bindable
  protected tabs: Tab[] = [];

  protected activeView: string;
  protected activeTabData: any;

  constructor(
    private element: Element,
    private router: Router,
    private pubsub: PubSub
  ) {}

  attached() {
    this.initTabs();

    this.pubsub.sub('tabs:change-guard-ok', () => {
      if (this.guardedTab === undefined || this.guardedTab < 0) {
        console.error('Guard index is not set.');
        return;
      }
      const tabs = this.element.querySelectorAll('.mn-tab');

      const target = this.guardedTab;
      this.guardedTab = undefined;

      this.setActiveTab(tabs, target, true);
    });
  }

  protected initTabs() {
    const url = new URL(window.location.href.replace('#/', '/'));
    const tabNameFromQuery = url.searchParams.get('tab');

    let tabIndex = 0;
    if (tabNameFromQuery) {
      tabIndex = this.tabs.findIndex((x) => x.name === tabNameFromQuery);
    }
    this.activeView = this.tabs[tabIndex].view;

    const tabs = this.element.querySelectorAll('.mn-tab');
    this.setActiveTab(tabs, tabIndex);
  }

  protected activateTab({ target }: { target: HTMLElement }) {
    const parent = target.parentElement;
    const tabs = parent.querySelectorAll('.mn-tab');
    const index = Array.from(tabs).indexOf(target);
    this.setActiveTab(tabs, index);
  }

  protected async setActiveTab(tabs: NodeList, index: number, fromGuard?: boolean) {
    if (this.activeTabIndex >= 0 && this.tabs[this.activeTabIndex].guard && !fromGuard) {
      this.guardedTab = index;
      this.pubsub.publish('tabs:change-guard', null);
      return;
    }

    for (let i = 0; i < tabs.length; i++) {
      const tab = tabs[i] as HTMLElement;

      if (i === index) {
        tab.classList.add('active');
      } else {
        tab.classList.remove('active');
      }
    }

    const { view, data, name } = this.tabs[index];
    this.activeView = view;
    this.activeTabData = data;
    this.activeTabIndex = index;

    if (name) {
      const searchParams = new URLSearchParams(this.router.currentInstruction.queryParams);
      searchParams.set('tab', name);

      this.router.navigate(this.router.currentInstruction.fragment + `?${searchParams.toString()}`, { replace: true });
    }
  }

  protected unbind() {
    this.pubsub.unsub();
  }
}
