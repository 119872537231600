import { PLATFORM } from 'aurelia-framework';
import { roles } from 'routes';

export const base_routes = [
  {
    route: ['', '/'],
    name: 'start-component',
    settings: { searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/start-component') },
      side: { moduleId: null },
    },
    route_base: 'start-component',
  },
  {
    route: ['dashboard'],
    name: 'dashboard',
    settings: { roles: [roles.sales], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/dashboard/sales-dashboard') },
      side: { moduleId: null },
    },
    nav: true,
    title: 'general.myOverview',
    route_base: 'dashboard',
    roles: [roles.sales, roles.productioncoordinator],
  },
  {
    route: ['noaccess'],
    name: 'noaccess',
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/user/no-access') },
      side: { moduleId: null },
    },
  },
  {
    route: ['login'],
    name: 'login',
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/account/login-main') },
      side: { moduleId: null },
    },
  },
];
