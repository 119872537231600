import { BaseEntity, BaseEntityWithMultilanguageName } from '../BaseEntity';
import { MooringArticle } from './MooringArticle';

export class CriteriaForValidity extends BaseEntity {
  Years: number;
  MaterialFactor: number;
  CriteriaForValidityCategoryId: number;
  CriteriaForValidityCategory?: CriteriaForValidityCategory;
}

export class CreateCriteriaForValidity {
  Years: number;
  MaterialFactor: number;
  CriteriaForValidityCategoryId?: number;
}

export class CriteriaForValidityCategory extends BaseEntityWithMultilanguageName {
  static ApiUrl = 'api/criteria-for-validity-category';
  CriteriaForValidity?: CriteriaForValidity[];
  MooringArticles?: MooringArticle[];
}

export class CreateCriteriaForValidityCategory {
  Id?: number;
  Name: string;
  NameEn: string;
  NameEs: string;
  CriteriaForValidity: CreateCriteriaForValidity[];
}
