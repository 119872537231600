import { autoinject, bindable } from 'aurelia-framework';
import { PubSub } from 'lib/event/PubSub';
import { AsyncErrorHandler } from 'lib/ui';
import { CreateEntityNoteDto, EntityNoteDto } from 'models';
import { MooringOfferService } from 'services/mooring';

@autoinject
export class Notes {
  @bindable
  offerId: number;

  notes: EntityNoteDto[] = [];
  newNote?: CreateEntityNoteDto = undefined;
  createNote = false;

  constructor(
    private mooringOfferService: MooringOfferService,
    private pubSub: PubSub
  ) {
    pubSub.sub('entity:deleted', (event) => {
      if (event?.name !== 'entity-note') return;

      void this.getNotes();
    });

    pubSub.sub('entity:created', (event) => {
      if (event?.name !== 'entity-note') return;
      this.cancelNewNote();
      void this.getNotes();
    });

    pubSub.sub('entity:updated', (event) => {
      if (event?.name !== 'entity-note') return;

      void this.getNotes();
    });
  }

  protected attached() {
    void this.getNotes();
  }

  @AsyncErrorHandler
  protected async getNotes() {
    const { data } = await this.mooringOfferService.getNotes(this.offerId);
    this.notes = data.sort((a, b) => b.Id - a.Id);
  }

  protected addNewNote() {
    this.createNote = true;
    setTimeout(() => {
      this.newNote = new CreateEntityNoteDto();
    }, 10);
  }

  protected cancelNewNote() {
    this.createNote = false;
    this.newNote = undefined;
  }

  detatched() {
    this.pubSub.unsub();
  }
}
