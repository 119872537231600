import { autoinject, bindable } from 'aurelia-framework';
import { DeleteDialogService } from './../../services/delete-dialog-service';
import { AsyncErrorHandler } from 'lib/ui';
import { FileAttachment } from 'models';
import { saveAs } from 'file-saver';
import { AttachmentHandler } from 'services/attachments';
import { PdfPreviewController } from 'elements/pdf-preview-controller';

@autoinject()
export class Attachments {
  @bindable
  public simple: boolean;

  @bindable
  public disabled: boolean;

  @bindable
  public onlyRead: boolean;

  @bindable
  public handler: AttachmentHandler;
  handlerChanged() {
    void this.getMetadata();
  }

  // TODO: Implement this
  // Should delete the file then upload the new one...
  // @bindable
  // public single: boolean = true;

  protected selectedFile: File | undefined;

  private files: FileAttachment[] = [];

  constructor(
    private deleteDialogService: DeleteDialogService,
    private pdfPreviewController: PdfPreviewController
  ) {}

  attached() {
    if (this.handler) {
      void this.getMetadata();
    }
  }

  protected async onFileSelected(event: InputEvent) {
    const input = event.target as HTMLInputElement;
    const file = input.files?.[0];
    if (!file) {
      return;
    }

    await this.upload(file);
  }

  @AsyncErrorHandler
  private async getMetadata() {
    const files = await this.handler.metadata();
    this.files = files.map((file) => {
      file['isPdf'] = file.FileName?.endsWith('.pdf');
      return file;
    });
  }

  @AsyncErrorHandler
  protected async preview(file: FileAttachment) {
    const res = await this.handler.download(file.Id);
    void this.pdfPreviewController.openPdfPreview(res);
  }

  @AsyncErrorHandler
  async save(file: FileAttachment) {
    const res = await this.handler.download(file.Id);
    saveAs(res.blob, res.fileName);
  }

  @AsyncErrorHandler
  async deleteFile(file: { Id: string | number }) {
    await this.deleteDialogService.confirmBeforeDelete(async () => {
      await this.handler.delete(file.Id);
    });
    this.files = this.files.filter((x) => x.Id !== file.Id);
  }

  @AsyncErrorHandler
  async upload(file: File) {
    await this.handler.upload(file);
    this.selectedFile = undefined;
    await this.getMetadata();
  }
}
