import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Models } from 'models/core';
import { DetergentTypeService } from 'services/detergent-type-service';
import { ErrorService } from 'services/error-service';
import { ToastService } from 'services/toast-service';
import { DeleteDialogService } from './../../services/delete-dialog-service';

@autoinject
export class DetergentTypeDetail {
  private detergentType: Models.DetergentType;
  protected checkDuplicateData: Models.CheckDuplicateDetergentType = new Models.CheckDuplicateDetergentType();

  private constructor(
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private router: Router,
    private toastService: ToastService,
    private detergentTypeService: DetergentTypeService,
    private deleteDialogService: DeleteDialogService
  ) {}

  async activate(params: { Id: number }) {
    try {
      this.detergentType = await this.detergentTypeService.get(params.Id);
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  async updateDetergentType() {
    try {
      if (await this.checkDuplicate()) return;

      await this.detergentTypeService.put(this.detergentType, this.detergentType.Id);
      this.eventAggregator.publish('detergentTypeListReset', 1);
      this.toastService.showSuccess('detergentType.updated');
      this.router.navigateToRoute('detergent-type-list');
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected async checkDuplicate() {
    this.checkDuplicateData.Id = this.detergentType.Id;
    this.checkDuplicateData.ProductId = this.detergentType.ProductId;
    const { IsDuplicate } = await this.detergentTypeService.checkDuplicate(this.checkDuplicateData);
    this.eventAggregator.publish('detergentTypeIsDuplicate', IsDuplicate);
    return IsDuplicate;
  }

  deleteDetergentType() {
    void this.deleteDialogService.confirmBeforeDelete(async () => {
      try {
        await this.detergentTypeService.delete(this.detergentType.Id);
        this.eventAggregator.publish('detergentTypeListReset', 1);
        this.toastService.showSuccess('detergentType.deleted');
        this.router.navigateToRoute('detergent-type-list');
      } catch (error) {
        this.errorService.handleError(error);
      }
    });
  }

  gotoParentView() {
    this.eventAggregator.publish('detergentTypeListReset', 1);
    this.router.navigateToRoute('detergent-type-list');
  }
}
