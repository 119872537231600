/* eslint-disable @typescript-eslint/no-explicit-any */

export type Field = {
  field: string;
  title?: string;
  disabled?: boolean;
  visible?: boolean;
  titleShort?: string;
  selected?: boolean;
  isDate?: boolean;
  highlightIfSet?: boolean;
  quickEdit?: boolean;
  function?: (row: any) => void;
  orderByName?: string;
  exportable?: boolean;
  checkDatePassed?: boolean;
};

type Args = {
  customerid: number | undefined;
  showServiceCard: (id: number, event: any) => void;
};

export const getListFields = (args: Args): Field[] => {
  return [
    {
      field: 'Id',
      title: 'general.id',
      selected: true,
      disabled: true,
      visible: false,
    },
    {
      field: 'ServiceId',
      title: 'general.id',
      selected: false,
      disabled: false,
      visible: true,
    },
    {
      field: 'NetIsSpecialProduct',
      title: 'net.isSpecialProduct',
      selected: true,
      disabled: true,
      visible: false,
    },
    {
      field: 'NetId',
      title: 'general.id',
      selected: true,
      disabled: true,
      visible: false,
    },
    {
      field: 'NetIdentifier',
      title: 'net.netidentifier',
      selected: true,
      disabled: false,
    },
    {
      field: 'CustomerNetId',
      title: 'net.customerNetId',
      selected: true,
      disabled: false,
    },
    {
      field: 'NetStatusId',
      title: 'NetStatusId',
      selected: true,
      disabled: true,
      visible: false,
    },
    {
      field: 'NetSpecialProductTypeName',
      title: 'specialProduct.specialProductType',
      selected: false,
      disabled: false,
    },
    {
      field: 'NetTypeName',
      title: 'service.netType',
      selected: true,
      disabled: false,
    },
    {
      field: 'CustomerName',
      title: 'general.customer',
      selected: !args.customerid,
      disabled: false,
    },
    {
      field: 'MeshSize',
      title: 'service.meshSize',
      selected: true,
      disabled: false,
    },
    {
      field: 'NetShapeName',
      title: 'notlogg.netshape',
      selected: true,
      disabled: false,
    },
    {
      field: 'DepthBottom',
      title: 'order.depthBottomRope',
      selected: false,
      disabled: false,
    },
    {
      field: 'Circumference',
      title: 'net.circumference',
      selected: true,
      disabled: false,
    },
    {
      field: 'NetDeliveryDate',
      title: 'net.producedDate',
      selected: false,
      isDate: true,
      disabled: false,
    },
    {
      field: 'NetIntentionName',
      title: 'net.intent',
      selected: false,
      disabled: false,
    },
    {
      field: 'ProducerName',
      title: 'net.producer',
      selected: false,
      disabled: false,
    },
    {
      field: 'ServiceNumber',
      title: 'service.number',
      selected: false,
      disabled: false,
    },
    {
      field: 'OrderNumber',
      title: 'service.id',
      titleShort: 'shortTitle.service.number',
      selected: false,
      disabled: false,
    },
    {
      field: 'ServiceStationName',
      title: 'service.serviceStation',
      selected: true,
      disabled: false,
    },
    {
      field: 'ServicePipelineName',
      title: 'service.activepipeline',
      selected: false,
      disabled: false,
    },
    {
      field: 'ServiceDate',
      title: 'service.serviceDate',
      selected: false,
      disabled: false,
      isDate: true,
    },
    {
      field: 'ReceivedDate',
      title: 'service.receivedDate',
      selected: true,
      disabled: false,
      isDate: true,
    },
    {
      field: 'ReceivedFromSiteName',
      title: 'service.receivedFromSite',
      selected: true,
      disabled: false,
    },
    {
      field: 'PlannedDeliveryDate',
      title: 'service.plannedDeliveryDate',
      titleShort: 'shortTitle.service.plannedDeliveryDate',
      selected: true,
      disabled: false,
      isDate: true,
      highlightIfSet: true,
      quickEdit: true,
    },
    {
      field: 'WashedDate',
      title: 'service.washedDate',
      titleShort: 'shortTitle.service.washedDate',
      selected: true,
      disabled: false,
      isDate: true,
      highlightIfSet: true,
      quickEdit: true,
    },
    {
      field: 'TestedDate',
      title: 'service.testedDate',
      selected: true,
      disabled: false,
      isDate: true,
      highlightIfSet: true,
      quickEdit: true,
    },
    {
      field: 'RepairedDate',
      title: 'service.repairedDate',
      selected: true,
      disabled: false,
      isDate: true,
      highlightIfSet: true,
      quickEdit: true,
    },
    {
      field: 'AntifouledDate',
      title: 'service.antifouledDate',
      titleShort: 'shortTitle.service.antifouledDate',
      selected: true,
      disabled: false,
      isDate: true,
      highlightIfSet: true,
      quickEdit: true,
    },
    {
      field: 'SpaghettiDate',
      title: 'service.spaghettiDate',
      titleShort: 'shortTitle.service.spaghettiDate',
      selected: true,
      disabled: false,
      isDate: true,
      highlightIfSet: true,
      quickEdit: true,
    },
    {
      field: 'DeliveredDate',
      title: 'service.deliveredDate',
      selected: true,
      disabled: false,
      isDate: true,
      highlightIfSet: true,
    },
    {
      field: 'DeliveredToSiteName',
      title: 'service.deliverToSite',
      selected: true,
      disabled: false,
    },
    {
      field: 'ServiceStationStorageName',
      title: 'service.storagePlacement',
      titleShort: 'shortTitle.service.storagePlacement',
      selected: true,
      disabled: false,
    },
    {
      field: 'ApprovedDate',
      title: 'service.approvedDate',
      selected: false,
      disabled: false,
      isDate: true,
    },
    {
      field: 'HoursReparation',
      title: 'service.hoursReparation',
      selected: false,
      disabled: false,
      isDate: false,
    },
    {
      field: 'ServiceAntifoulingLiters',
      title: 'service.actualImpregnationLiters',
      selected: false,
      disabled: false,
      isDate: false,
    },
    {
      field: 'ServiceAntifoulingTypeName',
      title: 'service.impregnationType',
      selected: false,
      disabled: false,
      isDate: false,
    },
    {
      field: 'InvoicedWashingStatus',
      title: 'service.invoicedWashingStatus',
      selected: false,
      disabled: false,
      quickEdit: true,
      orderByName: 'InvoicedWashing',
    },
    {
      field: 'InvoicedTestingStatus',
      title: 'service.invoicedTestingStatus',
      selected: false,
      disabled: false,
      quickEdit: true,
      orderByName: 'InvoicedTesting',
    },
    {
      field: 'InvoicedRepairStatus',
      title: 'service.invoicedRepairStatus',
      selected: false,
      disabled: false,
      quickEdit: true,
      orderByName: 'InvoicedRepair',
    },
    {
      field: 'InvoicedAntifoulingStatus',
      title: 'service.invoicedAntifoulingStatus',
      selected: false,
      disabled: false,
      quickEdit: true,
      orderByName: 'InvoicedAntifouling',
    },
    {
      field: 'InvoicedSpaghettiStatus',
      title: 'service.invoicedSpaghettiStatus',
      selected: false,
      disabled: false,
      quickEdit: true,
      orderByName: 'InvoicedSpaghetti',
    },
    {
      field: 'InvoicedDeliveryStatus',
      title: 'service.invoicedDeliveryStatus',
      selected: false,
      disabled: false,
      orderByName: 'InvoicedDelivery',
    },
    {
      field: 'InternalComment',
      title: 'service.internalComment',
      selected: false,
      disabled: false,
    },
    {
      field: 'CircumferenceMeasured',
      title: 'service.circumferenceMeasuredShort',
      selected: true,
      disabled: false,
    },
    {
      field: 'NetValidTo',
      title: 'net.validto',
      selected: false,
      disabled: false,
      isDate: true,
      checkDatePassed: true,
    },
    {
      field: 'ServiceCount',
      title: 'net.numberOfServices',
      selected: false,
      disabled: false,
    },
    {
      field: 'DeliveredRecyclingCompany',
      title: 'service.deliveredRecycling',
      isDate: true,
      selected: false,
      disabled: false,
    },
    {
      field: 'RecyclingCompanyName',
      title: 'service.recyclingCompany',
      selected: false,
      disabled: false,
    },
    {
      field: 'ShowServiceCard',
      title: 'service.serviceCard',
      function: (row) => args.showServiceCard(row.Id, null),
      selected: false,
      disabled: false,
      exportable: false,
    },
    {
      field: 'ServiceStatusId',
      title: 'service.serviceStatusId',
      selected: true,
      disabled: true,
      visible: false,
    },
    {
      field: 'ServiceStatusName',
      title: 'service.serviceStatus',
      selected: true,
      disabled: false,
    },
    {
      field: 'OrderedWashing',
      title: 'service.orderedWashing',
      selected: true,
      disabled: true,
      visible: false,
    },
    {
      field: 'OrderedMeasuring',
      title: 'service.orderedMeasuring',
      selected: true,
      disabled: true,
      visible: false,
    },
    {
      field: 'OrderedTesting',
      title: 'service.orderedTesting',
      selected: true,
      disabled: true,
      visible: false,
    },
    {
      field: 'OrderedRepair',
      title: 'service.orderedRepair',
      selected: true,
      disabled: true,
      visible: false,
    },
    {
      field: 'OrderedAntifouling',
      title: 'service.orderedAntifouling',
      selected: true,
      disabled: true,
      visible: false,
    },
    {
      field: 'OrderedDelivery',
      title: 'service.orderedDelivery',
      selected: true,
      disabled: true,
      visible: false,
    },
    {
      field: 'Locked',
      title: 'service.locked',
      selected: true,
      disabled: true,
      visible: false,
    },
    {
      field: 'SpecialProductLength',
      title: 'service.specialProductLength',
      selected: false,
      disabled: false,
      visible: true,
    },
    {
      field: 'SpecialProductWidth',
      title: 'service.specialProductWidth',
      selected: false,
      disabled: false,
      visible: true,
    },
    {
      field: 'MoistureNetBeforeImpregnation',
      title: 'service.moistureNetBeforeImpregnation',
      selected: false,
      disabled: false,
    },
    {
      field: 'MoistureNetAfterImpregnationAndDrying',
      title: 'service.moistureNetAfterImpregnationAndDrying',
      selected: false,
      disabled: false,
    },
    {
      field: 'MeasuredDate',
      title: 'service.measuredDate',
      selected: false,
      disabled: false,
      isDate: true,
    },
    {
      field: 'ApprovedNumberOfMonths',
      title: 'general.approvedNumberOfMonths',
      selected: true,
      disabled: false,
    },
    {
      field: 'ApprovedExtendedPeriod',
      title: 'service.approvedExtendedPeriod',
      selected: false,
      disabled: false,
      isDate: true,
    },
    {
      field: 'IsCollect',
      title: 'general.isMorenotCollect',
      selected: false,
      disabled: false,
      visible: true,
    },
  ];
};
