import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Models } from 'models/core';
import { DetergentTypeService } from 'services/detergent-type-service';
import { ErrorService } from 'services/error-service';
import { ToastService } from 'services/toast-service';

@autoinject
export class DetergentTypeNew {
  private detergentType: Models.DetergentType = new Models.DetergentType();

  protected checkDuplicateData: Models.CheckDuplicateDetergentType = new Models.CheckDuplicateDetergentType();

  private constructor(
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private router: Router,
    private toastService: ToastService,
    private detergentTypeService: DetergentTypeService
  ) {}

  async createDetergentType() {
    try {
      if (await this.checkDuplicate()) return;

      const detergent = await this.detergentTypeService.post(this.detergentType);
      this.eventAggregator.publish('detergentTypeListReset', 1);
      this.toastService.showSuccess('detergentType.created');
      this.router.navigateToRoute('detergent-type-detail', { Id: detergent.Id });
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected async checkDuplicate() {
    this.checkDuplicateData.ProductId = this.detergentType.ProductId;
    const { IsDuplicate } = await this.detergentTypeService.checkDuplicate(this.checkDuplicateData);
    this.eventAggregator.publish('detergentTypeIsDuplicate', IsDuplicate);
    return IsDuplicate;
  }
}
