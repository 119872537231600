import { CreateUpdateMooringComponent, MooringComponent } from './MooringComponent';

class BaseMooringAnchorLine {
  MoorlogNo: string;
  AnalysisNo: string;
  DimForce?: number;
  IsAccident: boolean;
  MinMblRopesStraps?: number;
  MinMblChainShackleConnectionPlates?: number;
  MinMblAnchoring?: number;
  BottomAnchoringUplift?: number;
  DepthAtAnchoringPoint?: number;

  Slope?: number;

  StartPositionLat: string;
  StartPositionLong: string;

  StopPositionLat: string;
  StopPositionLong: string;

  HoldingPowerAnchoring?: number;
  LengthOfBottomRope: number;
  LengthOfRope: number;
  TotalLength: number;
}

export class UpdateMooringAnchorLineDto extends BaseMooringAnchorLine {
  Id: number;
  Components: CreateUpdateMooringComponent[];
}

export class MooringAnchorLine extends BaseMooringAnchorLine {
  Id: number;
  Components: MooringComponent[];
  HasUnresolvedComments: boolean;
  HasUnresolvedComponentComments: boolean;
  HasComments: boolean;
}
