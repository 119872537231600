import { autoinject } from 'aurelia-framework';
import { CriteriaForValidityCategory } from 'models/mooring';
import { AuthHttpClient, BaseApiService } from 'services';

@autoinject
export class CriteriaForValidityService extends BaseApiService<CriteriaForValidityCategory> {
  constructor(httpClient: AuthHttpClient) {
    super(httpClient, CriteriaForValidityCategory);
  }
}
