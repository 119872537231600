export class CreateMooringTemplateDto {
  Name: string;
  Components: CreateMooringComponentTemplateEntryDto[];
}

export class CreateMooringComponentTemplateEntryDto {
  ArticleId: number;
  HasLoadConstraint: boolean;
  SortIndex: number;
}

export class UpdateMooringTemplateDto {
  Id: number;
  Name: string;
  Components: UpdateMooringComponentTemplateEntryDto[];
}

export class UpdateMooringComponentTemplateEntryDto extends CreateMooringComponentTemplateEntryDto {
  Id?: number;
}

export class MooringTemplateListItemDto {
  Id: number;
  Name: string;
  CreatedBy: string;
  Created: Date;
}

export class MooringTemplateDto {
  Id: number;
  Name: string;
  CreatedBy: string;
  Created: Date;
  Components: MooringComponentTemplateEntryDto[];
}

export class MooringComponentTemplateEntryDto {
  Id: number;

  ArticleId: number;
  ProductName: string;

  HasLoadConstraint: boolean;

  CategoryName: string;
  TypeName: string;
  ProductTypeName?: string;

  Quantity: number;

  MBL?: number;
  WLL?: number;

  SortIndex: number;
}
