import { EventAggregator } from 'aurelia-event-aggregator';
import { inject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import * as toastr from 'toastr';

@inject(EventAggregator, I18N)
export class ToastService {
  private options = {
    positionClass: 'toast-top-right',
    showEasing: 'swing',
    hideEasing: 'swing',
    showMethod: 'fadeIn',
    hideMethod: 'slideUp',
    preventDuplicates: false,
    closeButton: false,
    hideDuration: 250,
    timeOut: 2200,
    extendedTimeOut: 5000,
  };

  constructor(
    private eventAggregator: EventAggregator,
    private i18n: I18N
  ) {
    this.eventAggregator = eventAggregator;
    this.eventAggregator.subscribe('eToastSuccess', this.showSuccess);
    this.eventAggregator.subscribe('eToastError', this.showError);
  }

  public show(
    type: string,
    messageContentKey: string = null,
    messageTitleKey?: string,
    messageDynamic?: string,
    boldFirstWord: boolean = false
  ) {
    toastr.options = this.options;
    let content: string = '';
    let title: string;

    if (messageDynamic) {
      content += messageDynamic;
    } else if (messageContentKey) {
      content += this.i18n.tr(messageContentKey);
    }

    if (boldFirstWord) {
      const text = content.split(' ');
      const first = text.shift();
      content = '<strong>' + first + ' </strong>' + text.join(' ');
    }

    if (messageTitleKey) {
      title = this.i18n.tr(messageTitleKey);
    }

    content = content.replace('Error: ', '');

    switch (type) {
      case 'success':
        toastr.success(content, title, { closeButton: true });
        break;

      case 'error':
        toastr.error(content, title, { closeButton: true, timeOut: 0, extendedTimeOut: 0 });
        break;
      case 'loading':
        toastr.success(content, title, { timeOut: 0, extendedTimeOut: 0 });
        break;
      case 'warning':
        toastr.warning(content, title, { timeOut: 3500, closeButton: true });
        break;
    }
  }
  public showDeleted() {
    this.show('success', 'general.entryDeleted', 'general.deleted', undefined, false);
  }

  public showUpdated() {
    this.show('success', 'general.entryUpdated', 'general.updated', undefined, false);
  }

  public showCreated() {
    this.show('success', 'general.entryCreated', 'general.created', undefined, false);
  }

  public showSuccess(messageContentKey: string = null, messageTitleKey?: string, messageDynamic?: string, boldFirstWord: boolean = false) {
    this.show('success', messageContentKey, messageTitleKey, messageDynamic, boldFirstWord);
  }

  public showError(messageContentKey: string = null, messageTitleKey?: string, messageDynamic?: string, boldFirstWord: boolean = false) {
    this.show('error', messageContentKey, messageTitleKey, messageDynamic, boldFirstWord);
  }

  public showLoading(messageContentKey: string = null, messageTitleKey?: string, messageDynamic?: string, boldFirstWord: boolean = false) {
    this.show('loading', messageContentKey, messageTitleKey, messageDynamic, boldFirstWord);
  }

  public showWarning(messageContentKey: string = null, messageTitleKey?: string, messageDynamic?: string, boldFirstWord: boolean = false) {
    this.show('warning', messageContentKey, messageTitleKey, messageDynamic, boldFirstWord);
  }

  public clear() {
    toastr.clear();
  }
}
