import { autoinject } from 'aurelia-framework';
import { MooringFrameOrderCateogry } from 'models/mooring';
import { AuthHttpClient } from 'services/auth-http-client';
import { BaseApiService } from 'services/base-api-service';

@autoinject
export class MooringFrameOrderCategoryService extends BaseApiService<MooringFrameOrderCateogry> {
  constructor(httpClient: AuthHttpClient) {
    super(httpClient, MooringFrameOrderCateogry);
  }

  public async getAllByMooringOrderType(typeId: number) {
    return await this.httpClient.execWithResponseMeta<MooringFrameOrderCateogry[]>(
      `${MooringFrameOrderCateogry.ApiUrl}/getAll/${typeId}`,
      {
        method: 'GET',
      }
    );
  }
}
