import { bindable } from 'aurelia-framework';
import { autoinject } from 'aurelia-framework';
import { Models } from 'models/core';
import { CustomerService } from 'services/customer-service';
import { SiteService } from 'services/site-service';
import { ServiceStationService } from 'services/service-station-service';
import { ContactService } from 'services/contact-service';
import { DepartmentService } from 'services/department-service';
import { ErrorService } from 'services/error-service';
import { NetService } from 'services/net-service';
import { ServiceService } from 'services/service-service';
import { UserService } from 'services/user-service';
import { NetStandardService } from 'services/net-standard-service';

@autoinject
export class ServiceFormBasic {
  @bindable private net: any = new Models.Net();
  @bindable private service: Models.Service;
  @bindable private extendValidity = false;

  private isFishery = false;
  private customerid: number;
  private userdata: any;
  private disableServicestationDropdown = false;
  private disableNetStandardDropdown = true;
  private disableSites = false;
  private customers: Models.Customer[];
  private departments: Models.Department[];
  private sites: Models.Site[];
  private departmentSites: Models.Site[];
  private servicestations: Models.ServiceStation[];
  private customer: Models.Customer;
  private contacts: Array<Models.Contact>;
  private openServices: Array<Models.Service>;
  private netStandards: Models.NetStandard[] = [];

  constructor(
    private errorService: ErrorService,
    private netService: NetService,
    private serviceService: ServiceService,
    private customerService: CustomerService,
    private departmentService: DepartmentService,
    private netStandardService: NetStandardService,
    private siteService: SiteService,
    private userService: UserService,
    private serviceStationService: ServiceStationService,
    private element: Element,
    private contactService: ContactService
  ) {}

  private attached() {
    if (this.service.NetId) {
      this.getNet(this.service.NetId);
    }

    void this.getNetStandards();
    void this.getCurrentUser();
  }

  private netIdSet(event) {
    this.getNet(event.detail.value);
    const selectedEvent = new CustomEvent('net-selected', {
      detail: event.detail.value,
      bubbles: true,
    });

    this.element.dispatchEvent(selectedEvent);
  }

  private netIdCleared() {
    this.net = {};
    this.customer = null;
  }

  private async getCurrentUser() {
    try {
      const currentUser = await this.userService.getCurrentUser();

      this.userdata = currentUser;
      if (this.userdata.ServiceStationId == null && this.userdata.ServiceStationId !== '') {
        this.disableServicestationDropdown = false;
      } else {
        // if this is a new service, set the servicestation based on the users service station
        if (!this.service.Id) {
          this.service.ServiceStationId = this.userdata.ServiceStationId;
        }
        this.disableServicestationDropdown = true;
      }
      await this.setIsFishery(this.userdata.ServiceStationId);
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  private async setIsFishery(serviceStationId: number) {
    try {
      if (!serviceStationId) {
        return;
      }
      const serviceStation = await this.serviceStationService.get(`${serviceStationId}?$expand=Industry`);
      if (serviceStation.Industry.Name.toLowerCase().includes('fishery')) {
        this.isFishery = true;
      } else {
        this.isFishery = false;
      }
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  private getNet(NetId) {
    this.netService
      .get(NetId)
      .then((res) => {
        this.net = res;
        this.getCustomer(res.CustomerId);
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private async getNetStandards() {
    //NetStandard RES 1821 (Chile) is temporary only visible and used in used net so hide here
    this.netStandards = await this.netStandardService.getAll('?$filter=Id ne ' + Models.NetStandardId.RES_1821);

    if (this.service.NetStandardId === null || this.service.NetStandardId === undefined) {
      // Set defualt standard.
      const standard = this.netStandards.find((ns) => ns.Version === Models.NetStandardVersion.NS9415_2021);
      this.service.NetStandardId = Number.parseInt(`${standard.Id}`);
    }
  }

  private getCustomer(id) {
    this.customerService
      .get(id)
      .then((res) => {
        this.customer = res;
        this.loadCustomerData(this.customer.Id);
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private loadCustomerData(CustomerId) {
    this.getDepartments(CustomerId);
    this.getContacts(CustomerId);
    this.getSites(CustomerId);
  }

  private getDepartments(customerId) {
    this.departmentService
      .getAll('?$filter=CustomerId eq ' + customerId)
      .then((res) => {
        this.departments = res;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private getSites(CustomerId) {
    this.siteService
      .getAll('?$filter=CustomerId eq ' + CustomerId)
      .then((res) => {
        this.sites = res;
        if (!this.service.ReceivedFromDepartmentId) {
          this.departmentSites = JSON.parse(JSON.stringify(this.sites));
        } else {
          this.departmentSites = JSON.parse(JSON.stringify(this.sites.filter((x) => x.DepartmentId == this.service.ReceivedFromDepartmentId)));
        }
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private getContacts(CustomerId) {
    this.contactService
      .getAll('?$filter=CustomerId eq ' + CustomerId)
      .then((res) => {
        this.contacts = res.filter((x) => x.IsResponsibleForOffers || (!x.IsResponsibleForOffers && !x.IsResponsibleForUnloading));
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private updateServiceStation(event) {
    this.service.ServiceStationId = event.detail.value;
  }

  private updateNetStandard(event) {
    this.service.NetStandardId = Number.parseInt(event.detail.value);
  }

  private changeReceivedFromDepartment(event) {
    this.service.ReceivedFromDepartmentId = event.detail.value;

    if (this.service.ReceivedFromSiteId) {
      const site = this.departmentSites.find((x) => x.Id == this.service.ReceivedFromSiteId);
      if (site && site.DepartmentId == event.detail.value) {
        // we have already set the locality before selecting the department, so dont filter out
        // localities as that will clear the selection
        return;
      } else if (site && site.DepartmentId != event.detail.value) {
        this.service.ReceivedFromSiteId = null;
      }
    }

    // hack to make select2 update
    this.departmentSites = [];
    setTimeout(() => {
      if (!this.service.ReceivedFromDepartmentId) {
        this.departmentSites = JSON.parse(JSON.stringify(this.sites));
      } else {
        this.departmentSites = JSON.parse(
          JSON.stringify(
            this.sites.filter(
              (x) =>
                x.DepartmentId &&
                this.service.ReceivedFromDepartmentId &&
                x.DepartmentId.toString() == this.service.ReceivedFromDepartmentId.toString()
            )
          )
        );
      }
    });
  }

  changeReceivedFromSite(event) {
    this.service.ReceivedFromSiteId = event.detail.value;

    const site = this.sites.find((x) => x.Id == this.service.ReceivedFromSiteId);

    if (site && site.DepartmentId) {
      if (this.service.ReceivedFromDepartmentId != site.DepartmentId) {
        this.service.ReceivedFromDepartmentId = site.DepartmentId;
      }
    }
  }
}
