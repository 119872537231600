import { DialogService } from 'aurelia-dialog';
import { autoinject, PLATFORM } from 'aurelia-framework';
import { AsyncErrorHandler, ViewLoadingFader } from 'lib/ui';
import { MooringAttachmentType, MooringOfferDto } from 'models/mooring';
import { MooringOfferService } from 'services/mooring';
import { AttachmentHandlerFactory, MooringDocumentsHandler } from 'services/attachments';

import './offer-view.scss';
import { MooringCreateOfferDialog } from 'features/mooring/offers/mooring-create-offer-dialog';
import { PubSub } from 'lib/event/PubSub';
import { saveAs } from 'file-saver';
import { MooringOfferStatus, ValidStatusForReview } from 'models/mooring/MooringOffers';
import { Tab } from 'elements/tabs/MnTabs';
import { I18N } from 'aurelia-i18n';

@autoinject
export class OfferView {
  protected offerId: number;
  protected offer: MooringOfferDto;

  protected analysisAttachmentHandler: MooringDocumentsHandler;

  protected canSendForReview = false;
  protected isInReview = false;

  protected tabsReady = false;
  protected tabs: Tab[] = [
    {
      name: 'overview',
      title: this.t.tr('mooring.offer'),
      view: PLATFORM.moduleName('views/mooring/mooring-offers/offer/overview/tab'),
      data: {},
    },
    {
      name: 'anchor-lines',
      title: this.t.tr('mooring.anchorLine_plural'),
      view: PLATFORM.moduleName('views/mooring/mooring-offers/offer/anchorLines/tab'),
      guard: true,
      data: {},
    },
    {
      name: 'grid-lines',
      title: this.t.tr('mooring.gridLine_plural'),
      view: PLATFORM.moduleName('views/mooring/mooring-offers/offer/gridLines/tab'),
      guard: true,
      data: {},
    },
    {
      name: 'buoys',
      title: this.t.tr('mooring.buoy_plural'),
      view: PLATFORM.moduleName('views/mooring/mooring-offers/offer/buoys/tab'),
      guard: true,
      data: {},
    },
    {
      name: 'bridles',
      title: this.t.tr('mooring.bridle_plural'),
      view: PLATFORM.moduleName('views/mooring/mooring-offers/offer/bridles/tab'),
      guard: true,
      data: {},
    },
    {
      name: 'review',
      title: this.t.tr('mooring.review'),
      view: PLATFORM.moduleName('views/mooring/mooring-offers/offer/review/tab'),
      data: {},
    },
  ] as const;

  constructor(
    protected element: Element,
    private mooringOfferService: MooringOfferService,
    private dialogService: DialogService,
    private attachmentHandlerFactory: AttachmentHandlerFactory,
    private t: I18N,
    pubSub: PubSub
  ) {
    pubSub.sub('entity:updated', this.getOffer.bind(this));
  }

  activate(params: { id: number }) {
    this.offerId = params.id;
  }

  @ViewLoadingFader
  async attached() {
    await this.getOffer();

    this.analysisAttachmentHandler = this.attachmentHandlerFactory.mooringAttachmentHandler({
      mooringId: this.offer.MooringId,
      attachmentType: MooringAttachmentType.Analysis,
      attachmentIds: [this.offer.AnalysisAttachmentId],
    });
  }

  @AsyncErrorHandler
  async getOffer() {
    const { data } = await this.mooringOfferService.getOffer(this.offerId);
    this.offer = data;

    for (const tab of this.tabs) {
      if (!tab.data) tab.data = {};
      tab.data = {
        mooringId: data.MooringId,
        offerId: data.Id,
        activeReviewId: data.ActiveReviewId,
      };
    }
    this.tabsReady = true;
    this.setReviewState();
  }

  protected setReviewState() {
    if (!this.offer) return;
    this.canSendForReview = ValidStatusForReview.some((x) => x === this.offer.Status);

    if (this.offer.Status == MooringOfferStatus.Review) {
      this.isInReview = true;
    }
  }

  protected async exportNavision() {
    const b = await this.mooringOfferService.exportOfferNavision(this.offerId);
    saveAs(b.blob, b.fileName);
  }

  @AsyncErrorHandler
  protected async sendForReview() {
    await this.mooringOfferService.sendForReview(this.offerId);
    await this.getOffer();
  }

  protected async abortReview() {
    // const b = await this.mooringOfferService.exportOfferNavision(this.offerId);
    // saveAs(b.blob, b.fileName);
  }

  protected async completeReview() {
    // const b = await this.mooringOfferService.exportOfferNavision(this.offerId);
    // saveAs(b.blob, b.fileName);
  }

  protected canDeactivate() {}

  editDetails() {
    void this.dialogService.open({
      viewModel: MooringCreateOfferDialog,
      model: {
        id: this.offerId,
      },
    });
  }
}
