import { bindable } from 'aurelia-framework';

export class UtilizationFactor {
  @bindable
  utilizationFactor?: number;

  status = 'error';

  attached() {
    const factor = +this.utilizationFactor;
    if (Number.isNaN(factor)) {
      return;
    }

    if (factor < 25) {
      this.status = 'ok';
    } else if (factor < 50) {
      this.status = 'info';
    } else if (factor < 75) {
      this.status = 'warning';
    } else {
      this.status = 'error';
    }
  }
}
