export const MooringAttachmentType = {
  Analysis: 1,
  Other: 99,
} as const;

export type MooringAttachmentType = (typeof MooringAttachmentType)[keyof typeof MooringAttachmentType];

export class MooringAttachmentAttachment {
  MooringId: number;
  StorageFileId: number;
  AttachmentType: MooringAttachmentType;
  Id: number;
  Created: Date;
  Updated: Date;
  Deleted: Date;
}
