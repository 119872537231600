import { BaseEntityWithMultilanguageName } from '../BaseEntity';

export enum MooringCertificateTemplateType {
  Danline = 0,
  SinkRope = 1,
  Flexilink = 2,
  Solidlink = 4,
}

export class MooringCertificateTemplate extends BaseEntityWithMultilanguageName {
  static ApiUrl = 'api/mooring-certificate-templates';

  UserManual: string;
  ForUseIn: string;
  Standard: string;
  CertificationSystem: string;
  Regulation: string;
  CriteriaForValidity: string;
  Location: string;
  TemplateType: MooringCertificateTemplateType;
}

export class MooringCertificateTemplateListItem extends BaseEntityWithMultilanguageName {
  static ApiUrl = 'api/mooring-certificate-templates';
}
