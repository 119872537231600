import { ListSetting } from 'elements/list-settings';

export type QueryFilter = {
  searchText?: string;

  orderBy: string;
  orderByDirection: 'ASC' | 'DESC';

  top: number;
  skip: number;

  export: boolean;
  locale: 'nb' | 'en' | 'es';

  asQueryString: string;
};

export class DataFilter implements QueryFilter {
  searchText?: string;

  orderBy: string;
  orderByDirection: 'ASC' | 'DESC' = 'DESC';

  top = 25;
  skip = 0;

  export = false;
  locale: 'nb' | 'en' | 'es' = 'nb';

  filters: { [key: string]: string };

  constructor(initData?: Partial<DataFilter>) {
    Object.assign(this, initData);
    this.getGlobalListSettings();
  }

  private getGlobalListSettings() {
    const globalListSettings = localStorage.getItem('LIST_SETTINGS_GLOBAL');

    if (globalListSettings) {
      const listSettings = JSON.parse(globalListSettings) as ListSetting;
      this.top = listSettings.pageSize;
    }
  }

  setOrderByAndFlipOrderDirectionIfSameKey(orderBy: string) {
    if (this.orderBy == orderBy) {
      this.flipOrderBy();
    }
    this.orderBy = orderBy;
  }

  private flipOrderBy() {
    this.orderByDirection = this.orderByDirection === 'ASC' ? 'DESC' : 'ASC';
  }

  get asQueryString() {
    const query = Object.entries(this).filter(([, v]) => !(v === null || v === undefined || v?.length == 0 || typeof v === 'object'));
    const filters = Object?.entries(this.filters ?? {}) ?? [];
    return '?' + new URLSearchParams([...query, ...filters]).toString();
  }

  toQueryString() {
    return '?' + new URLSearchParams(Object.entries(this).filter(([, v]) => !(v === null || v === undefined || v?.length == 0))).toString();
  }
}
