import { PLATFORM } from 'aurelia-framework';
import { roles } from 'routes';

export const mooring_routes = [
  {
    route: ['mooring/component-templates'],
    name: 'mooring-component-templates',
    settings: { roles: [roles.mooringcalculation, roles.mooringUser, roles.mooringAdministrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('views/mooring/mooring-component-templates/template-view') },
      side: { moduleId: null },
    },
    nav: true,
    title: 'mooring.componentTemplates',
    route_base: 'mooring/component-templates',
    section: 'mooring',
  },
  {
    route: ['mooring/offers'],
    name: 'mooring-offers-view',
    settings: { roles: [roles.mooringcalculation, roles.mooringUser, roles.mooringAdministrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('views/mooring/mooring-offers/offers-view') },
      side: { moduleId: null },
    },
    nav: true,
    title: 'mooring.offer',
    route_base: 'mooring/offers',
    section: 'mooring',
  },
  {
    route: ['mooring/offers/:id'],
    name: 'mooring-offer-view',
    settings: { roles: [roles.mooringcalculation, roles.mooringUser, roles.mooringAdministrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('views/mooring/mooring-offers/offer-view') },
      side: { moduleId: null },
    },
    route_base: 'mooring/offers',
    section: 'mooring',
  },
  {
    route: ['mooring/orders'],
    name: 'mooring-salesorder-view',
    settings: { roles: [roles.mooringcalculation, roles.mooringUser, roles.mooringAdministrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('views/mooring/mooring-salesorder/salesorder-view') },
      side: { moduleId: null },
    },
    nav: true,
    title: 'general.salesOrder',
    route_base: 'mooring/orders',
    section: 'mooring',
  },
  {
    route: ['mooring/certificates'],
    name: 'certificate-view',
    settings: { roles: [roles.mooringcalculation, roles.mooringUser, roles.mooringAdministrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('views/mooring/mooring-certificate/certificate-view') },
      side: { moduleId: null },
    },
    nav: true,
    title: 'general.certificates',
    route_base: 'mooring/certificates',
    section: 'mooring',
  },
  {
    route: ['mooring/calculation-report'],
    name: 'mooring-calculation-report',
    settings: { roles: [roles.mooringcalculation, roles.mooringAdministrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/mooring-calculation-report/mooring-calculation-report-new') },
      side: { moduleId: null },
    },
    nav: true,
    title: 'mooringCalculationReport.title',
    route_base: 'mooring/calculation-report',
    section: 'mooring',
  },
];
