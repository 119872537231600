import { autoinject } from 'aurelia-framework';
import { NetlogHttpClient } from 'lib/http';
import { AquacomComponentProduct, MooringProductType } from 'models/mooring';
import { AuthHttpClient } from 'services/auth-http-client';
import { BaseApiService } from 'services/base-api-service';

@autoinject
export class MooringProductTypeService extends BaseApiService<MooringProductType> {
  constructor(httpClient: AuthHttpClient, private client: NetlogHttpClient) {
    super(httpClient, MooringProductType);
  }

  async getAquacomTypes() {
    return (
      await this.client.get<AquacomComponentProduct[]>({
        url: `${MooringProductType.ApiUrl}/aquacom`,
        model: AquacomComponentProduct,
      })
    ).data;
  }
}
