import './mooring-component-comment-dialog.scss';

import { DialogController } from 'aurelia-dialog';
import { autoinject } from 'aurelia-framework';
import { AsyncErrorHandler } from 'lib/ui';
import { MooringService } from 'services/mooring';
import { connectTo } from 'aurelia-store';
import { ApplicationState } from 'lib/state';
import { ConfirmDialog } from 'components/dialogs/confirm/confirm-dialog';
import { ToastService } from 'services';
import { StandardValidationBuilder } from 'lib/validation';
import { ValidationController, Validator } from 'aurelia-validation';
import { ResolveComponentCommentDto, CommentForEntity, CreateComponentCommentDto, UpdateComponentCommentDto } from 'models/mooring';

type Comment = {
  Id?: number;
  Comment?: string;
  ResolveComment?: string;

  CreatedBy?: string;
  ResolvedBy?: string;
  MustBeResolved: boolean;

  Created?: Date;

  CanEdit: boolean;
  CanResolve: boolean;
  CanDelete: boolean;
};

export type MooringComponentCommentDialogArgs = {
  isReadOnly: boolean;
  mooringId: number;
  componentId: number;
  componentType: CommentForEntity;
};

@autoinject
@connectTo()
export class MooringComponentCommentDialog {
  args: MooringComponentCommentDialogArgs;

  comments: Comment[] = [];

  private state: ApplicationState;

  constructor(
    private toastService: ToastService,
    private confirm: ConfirmDialog,
    private mooringService: MooringService,
    private dialogController: DialogController,
    private validationController: ValidationController,
    private validationBuilder: StandardValidationBuilder,
    protected validator: Validator
  ) {}

  protected applyValidationRules() {
    for (const comment of this.comments) {
      if (!comment) continue;
      let b = this.validationBuilder.with(comment, (comment.Id || Math.random()).toString());

      if (comment.CanEdit) {
        b = b.required('Comment');
      }
      b.done();
    }
  }

  @AsyncErrorHandler
  async getComments() {
    const { data } = await this.mooringService.getComponentComments({
      componentId: this.args.componentId,
      mooringId: this.args.mooringId,
      entity: this.args.componentType,
    });
    if (data?.length) {
      this.comments = data
        .sort((a, b) => b.CommentId - a.CommentId)
        .map(
          (x) =>
            ({
              Id: x.CommentId,
              Comment: x.Comment,

              CanDelete: this.state.user.Id === x.CreatedById,
              CanEdit: this.state.user.Id === x.CreatedById,
              CanResolve: x.MustBeResolved,

              ResolvedBy: x.ResolvedBy,
              ResolveComment: x.ResolvedComment,
              MustBeResolved: x.MustBeResolved,

              Created: new Date(),
              CreatedBy: x.CreatedBy,
            }) satisfies Comment
        );
      this.applyValidationRules();
    }
  }

  @AsyncErrorHandler
  async save(comment: Comment) {
    const { mooringId, componentId, isReadOnly, componentType } = this.args;
    if (isReadOnly) return;

    if (comment?.Id) {
      const dto = new UpdateComponentCommentDto();
      dto.Comment = comment.Comment;
      dto.MustBeResolved = comment.MustBeResolved;
      await this.mooringService.updateComponentComment({
        dto,
        mooringId,
        commentId: comment.Id,
      });
      await this.getComments();
      this.toastService.showUpdated();
    } else {
      const dto = new CreateComponentCommentDto();
      dto.Comment = comment.Comment;
      dto.MustBeResolved = comment.MustBeResolved;
      dto.Entity = componentType;
      dto.EntityId = componentId;
      await this.mooringService.createComponentComment({
        dto,
        mooringId,
        componentId,
      });
      await this.getComments();
      this.toastService.showCreated();
    }
  }

  @AsyncErrorHandler
  async resolve(comment: Comment) {
    const { valid } = await this.validationController.validate();
    const { mooringId, isReadOnly } = this.args;
    if (!valid || isReadOnly) return;

    const dto = new ResolveComponentCommentDto();
    dto.Comment = comment.ResolveComment;
    await this.mooringService.resolveComponentComment({
      dto,
      mooringId,
      commentId: comment.Id,
    });
    await this.getComments();
    this.toastService.showUpdated();
  }

  @AsyncErrorHandler
  async delete(comment: Comment, index: number) {
    const res = await this.confirm.confirmDelete();
    if (!res) return;

    if (!comment.Id) {
      this.comments.splice(index, 1);
      return;
    }

    const { mooringId, isReadOnly } = this.args;
    if (isReadOnly) return;

    await this.mooringService.deleteComponentComment({ commentId: comment.Id, mooringId });
    this.toastService.showDeleted();
    this.dialogController.cancel();
  }

  // Aurelia hook
  activate(args: MooringComponentCommentDialogArgs) {
    this.args = args;
    void this.getComments();
  }

  addComment() {
    this.comments.unshift({
      ResolvedBy: '',
      CreatedBy: '',
      CanEdit: true,
      CanDelete: true,
      CanResolve: false,
      MustBeResolved: true,
      Comment: '',
      Id: undefined,
      Created: undefined,
    } satisfies Comment);
  }
}
