import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Filters } from 'elements/Filter';
import { getCallbackData } from 'elements/filterer';
import { PaginationHandler } from 'elements/pagination-handler';
import { DataFilter } from 'lib/tables/DataFilter';
import { MooringStationListItem } from 'models/mooring';
import { MooringStationService, ErrorService } from 'services';

@autoinject()
export class MooringsStationsListView {
  protected ready = false;

  protected mooringStations: MooringStationListItem[] = [];
  protected dataFilter = new DataFilter();

  constructor(
    private mooringStationsService: MooringStationService,
    private errorService: ErrorService,
    private pagination: PaginationHandler,
    private router: Router
  ) {}

  protected attached() {
    this.setupPagination();
    void this.getMooringStations();
  }

  deactivate() {}

  editMooringStation(id: number) {
    this.router.navigateToRoute('edit-mooring-station', { Id: id });
  }

  private async getMooringStations() {
    try {
      const { data } = await this.mooringStationsService.table(this.dataFilter);
      this.mooringStations = data;
    } catch (error) {
      this.errorService.handleError(error);
    }
    this.ready = true;
  }

  protected setupPagination() {
    const routeParams = this.router.currentInstruction?.queryParams;
    let currentPage = 1;
    if (routeParams && routeParams.currentPage) {
      currentPage = +routeParams.currentPage;
    }

    this.pagination.init({
      currentPage: currentPage,
      totalItems: 0,
      onPageChanged: (_, pagination) => {
        this.dataFilter.skip = pagination.skip;
        this.dataFilter.top = pagination.top;
        void this.getMooringStations();
      },
    });
  }

  protected setOrderByColumn(column: string) {
    this.dataFilter.setOrderByAndFlipOrderDirectionIfSameKey(column);
    void this.getMooringStations();
  }

  protected context = 'mooring_articles';
  protected filterClearAll: () => void;
  protected filterClearSingle: (name: string) => void;
  protected filterToggleVisible: () => void;
  protected filterGetFiltersQuery: () => Promise<{ serviceStationId?: number; customerIds?: string[] }>;
  protected setFilterValueByName: (name: string, data: any) => void;

  protected defaultFilters = {};

  protected getFilterKey() {
    return 'MOORING_STATIONS_TABLE';
  }

  protected onFilterChanged() {
    void this.getMooringStations();
  }

  getFilterValues() {
    return getCallbackData(this.filterGetFiltersQuery);
  }

  protected async setupFilters() {
    return await new Promise((res: (v: Filters) => void) => {
      setTimeout(() => {
        const filters: Filters = {};
        res(filters);
      });
    });
  }
}
