import { PLATFORM } from 'aurelia-framework';
import { roles } from 'routes';

export const admin_routes = [
  {
    route: ['users'],
    name: 'user-list',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/user/user-list') },
      side: { moduleId: null },
    },
    nav: true,
    title: 'general.user_plural',
    route_base: 'user',
  },
  {
    route: ['user/:Id'],
    name: 'user-detail',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/user/user-detail') },
      side: { moduleId: null },
    },
  },
  {
    route: ['user/new'],
    name: 'user-new',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/user/user-new') },
      side: { moduleId: null },
    },
    title: 'users.new',
  },
  {
    route: ['admin/admin-overview'],
    name: 'admin-overview',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/admin/admin-overview') },
      side: { moduleId: null },
    },
    nav: true,
    title: 'general.administration',
    route_base: 'admin',
    section: 'admin',
  },
  {
    route: ['admin/service-stations'],
    name: 'service-station-list',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/service-station/service-station-list') },
      side: { moduleId: null },
    },
  },
  {
    route: ['admin/service-station/:Id'],
    name: 'service-station-main',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/service-station/service-station-main') },
      side: { moduleId: null },
    },
  },
  {
    route: ['admin/service-stations/new'],
    name: 'service-station-new',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/service-station/service-station-new') },
      side: { moduleId: null },
    },
  },
  {
    route: ['admin/mesh-sizes'],
    name: 'mesh-size-list',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/mesh-size/mesh-size-list') },
      side: { moduleId: null },
    },
  },
  {
    route: ['admin/mesh-size/:Id'],
    name: 'mesh-size-detail',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/mesh-size/mesh-size-list') },
      side: { moduleId: PLATFORM.moduleName('components/mesh-size/mesh-size-detail') },
    },
  },
  {
    route: ['admin/mesh-size/new'],
    name: 'mesh-size-new',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/mesh-size/mesh-size-list') },
      side: { moduleId: PLATFORM.moduleName('components/mesh-size/mesh-size-new') },
    },
  },
  {
    route: 'admin/dimension-classes',
    name: 'dimension-class-list',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/dimension-class/dimension-class-list') },
      side: { moduleId: null },
    },
  },
  {
    route: 'admin/dimension-class/:Id',
    name: 'dimension-class-detail',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/dimension-class/dimension-class-list') },
      side: { moduleId: PLATFORM.moduleName('components/dimension-class/dimension-class-detail') },
    },
  },
  {
    route: 'admin/dimension-class/new',
    name: 'dimension-class-new',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/dimension-class/dimension-class-list') },
      side: { moduleId: PLATFORM.moduleName('components/dimension-class/dimension-class-new') },
    },
  },
  {
    route: 'admin/collector',
    name: 'collector-list',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/collector/collector-list') },
      side: { moduleId: null },
    },
  },
  {
    route: 'admin/collector/:Id',
    name: 'collector-detail',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/collector/collector-list') },
      side: { moduleId: PLATFORM.moduleName('components/collector/collector-detail') },
    },
  },
  {
    route: 'admin/collector/new',
    name: 'collector-new',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/collector/collector-list') },
      side: { moduleId: PLATFORM.moduleName('components/collector/collector-new') },
    },
  },
  {
    route: 'admin/service-status',
    name: 'service-status-list',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/service-status/service-status-list') },
      side: { moduleId: null },
    },
  },
  {
    route: 'admin/service-status/:Id',
    name: 'service-status-detail',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/service-status/service-status-list') },
      side: { moduleId: PLATFORM.moduleName('components/service-status/service-status-detail') },
    },
  },
  {
    route: 'admin/service-status/new',
    name: 'service-status-new',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/service-status/service-status-list') },
      side: { moduleId: PLATFORM.moduleName('components/service-status/service-status-new') },
    },
  },
  {
    route: 'admin/service-checkpoint-type',
    name: 'service-checkpoint-type-list',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/service-checkpoint-type/service-checkpoint-type-list') },
      side: { moduleId: null },
    },
  },
  {
    route: 'admin/service-checkpoint-type/:Id',
    name: 'service-checkpoint-type-detail',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/service-checkpoint-type/service-checkpoint-type-list') },
      side: { moduleId: PLATFORM.moduleName('components/service-checkpoint-type/service-checkpoint-type-detail') },
    },
  },
  {
    route: 'admin/service-checkpoint-type/new',
    name: 'service-checkpoint-type-new',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/service-checkpoint-type/service-checkpoint-type-list') },
      side: { moduleId: PLATFORM.moduleName('components/service-checkpoint-type/service-checkpoint-type-new') },
    },
  },
  {
    route: 'admin/offer-status',
    name: 'offer-status-list',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/offer-status/offer-status-list') },
      side: { moduleId: null },
    },
  },
  {
    route: 'admin/offer-status/:Id',
    name: 'offer-status-detail',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/offer-status/offer-status-list') },
      side: { moduleId: PLATFORM.moduleName('components/offer-status/offer-status-detail') },
    },
  },
  {
    route: 'admin/offer-status/new',
    name: 'offer-status-new',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/offer-status/offer-status-list') },
      side: { moduleId: PLATFORM.moduleName('components/offer-status/offer-status-new') },
    },
  },
  {
    route: 'admin/order-status',
    name: 'order-status-list',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/order-status/order-status-list') },
      side: { moduleId: null },
    },
  },
  {
    route: 'admin/order-status/:Id',
    name: 'order-status-detail',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/order-status/order-status-list') },
      side: { moduleId: PLATFORM.moduleName('components/order-status/order-status-detail') },
    },
  },
  {
    route: 'admin/order-status/new',
    name: 'order-status-new',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/order-status/order-status-list') },
      side: { moduleId: PLATFORM.moduleName('components/order-status/order-status-new') },
    },
  },
  {
    route: 'admin/producer',
    name: 'producer-list',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/producer/producer-list') },
      side: { moduleId: null },
    },
  },
  {
    route: 'admin/producer/:Id',
    name: 'producer-detail',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/producer/producer-detail') },
      side: { moduleId: null },
    },
  },
  {
    route: 'admin/producer/new',
    name: 'producer-new',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/producer/producer-new') },
      side: { moduleId: null },
    },
  },
  {
    route: 'admin/design-type',
    name: 'design-type-list',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/design-type/design-type-list') },
      side: { moduleId: null },
    },
  },
  {
    route: 'admin/design-type/:Id',
    name: 'design-type-detail',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/design-type/design-type-list') },
      side: { moduleId: PLATFORM.moduleName('components/design-type/design-type-detail') },
    },
  },
  {
    route: 'admin/design-type/new',
    name: 'design-type-new',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/design-type/design-type-list') },
      side: { moduleId: PLATFORM.moduleName('components/design-type/design-type-new') },
    },
  },
  {
    route: 'admin/impregnation-type',
    name: 'impregnation-type-list',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/impregnation-type/impregnation-type-list') },
      side: { moduleId: null },
    },
  },
  {
    route: 'admin/impregnation-type/:Id',
    name: 'impregnation-type-detail',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/impregnation-type/impregnation-type-list') },
      side: { moduleId: PLATFORM.moduleName('components/impregnation-type/impregnation-type-detail') },
    },
  },
  {
    route: 'admin/impregnation-type/new',
    name: 'impregnation-type-new',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/impregnation-type/impregnation-type-list') },
      side: { moduleId: PLATFORM.moduleName('components/impregnation-type/impregnation-type-new') },
    },
  },
  {
    route: 'admin/impregnation-method',
    name: 'impregnation-method-list',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/impregnation-method/impregnation-method-list') },
      side: { moduleId: null },
    },
  },
  {
    route: 'admin/impregnation-method/:Id',
    name: 'impregnation-method-detail',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/impregnation-method/impregnation-method-list') },
      side: { moduleId: PLATFORM.moduleName('components/impregnation-method/impregnation-method-detail') },
    },
  },
  {
    route: 'admin/impregnation-method/new',
    name: 'impregnation-method-new',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/impregnation-method/impregnation-method-list') },
      side: { moduleId: PLATFORM.moduleName('components/impregnation-method/impregnation-method-new') },
    },
  },
  {
    route: 'admin/lace-thread-dimension',
    name: 'lace-thread-dimension-list',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/lace-thread-dimension/lace-thread-dimension-list') },
      side: { moduleId: null },
    },
  },
  {
    route: 'admin/lace-thread-dimension/:Id',
    name: 'lace-thread-dimension-detail',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/lace-thread-dimension/lace-thread-dimension-list') },
      side: { moduleId: PLATFORM.moduleName('components/lace-thread-dimension/lace-thread-dimension-detail') },
    },
  },
  {
    route: 'admin/lace-thread-dimension/new',
    name: 'lace-thread-dimension-new',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/lace-thread-dimension/lace-thread-dimension-list') },
      side: { moduleId: PLATFORM.moduleName('components/lace-thread-dimension/lace-thread-dimension-new') },
    },
  },
  {
    route: 'admin/lace-thread-type',
    name: 'lace-thread-type-list',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/lace-thread-type/lace-thread-type-list') },
      side: { moduleId: null },
    },
  },
  {
    route: 'admin/lace-thread-type/:Id',
    name: 'lace-thread-type-detail',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/lace-thread-type/lace-thread-type-list') },
      side: { moduleId: PLATFORM.moduleName('components/lace-thread-type/lace-thread-type-detail') },
    },
  },
  {
    route: 'admin/lace-thread-type/new',
    name: 'lace-thread-type-new',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/lace-thread-type/lace-thread-type-list') },
      side: { moduleId: PLATFORM.moduleName('components/lace-thread-type/lace-thread-type-new') },
    },
  },
  {
    route: 'admin/net-intent',
    name: 'net-intent-list',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/net-intent/net-intent-list') },
      side: { moduleId: null },
    },
  },
  {
    route: 'admin/net-intent/:Id',
    name: 'net-intent-detail',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/net-intent/net-intent-list') },
      side: { moduleId: PLATFORM.moduleName('components/net-intent/net-intent-detail') },
    },
  },
  {
    route: 'admin/net-intent/new',
    name: 'net-intent-new',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/net-intent/net-intent-list') },
      side: { moduleId: PLATFORM.moduleName('components/net-intent/net-intent-new') },
    },
  },
  {
    route: 'admin/marking-type',
    name: 'marking-type-list',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/marking-type/marking-type-list') },
      side: { moduleId: null },
    },
  },
  {
    route: 'admin/marking-type/:Id',
    name: 'marking-type-detail',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/marking-type/marking-type-list') },
      side: { moduleId: PLATFORM.moduleName('components/marking-type/marking-type-detail') },
    },
  },
  {
    route: 'admin/marking-type/new',
    name: 'marking-type-new',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/marking-type/marking-type-list') },
      side: { moduleId: PLATFORM.moduleName('components/marking-type/marking-type-new') },
    },
  },
  {
    route: 'admin/marking-color',
    name: 'marking-color-list',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/marking-color/marking-color-list') },
      side: { moduleId: null },
    },
  },
  {
    route: 'admin/marking-color/:Id',
    name: 'marking-color-detail',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/marking-color/marking-color-list') },
      side: { moduleId: PLATFORM.moduleName('components/marking-color/marking-color-detail') },
    },
  },
  {
    route: 'admin/marking-color/new',
    name: 'marking-color-new',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/marking-color/marking-color-list') },
      side: { moduleId: PLATFORM.moduleName('components/marking-color/marking-color-new') },
    },
  },
  {
    route: 'admin/net-type',
    name: 'net-type-list',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/net-type/net-type-list') },
      side: { moduleId: null },
    },
  },
  {
    route: 'admin/net-type/:Id',
    name: 'net-type-detail',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/net-type/net-type-list') },
      side: { moduleId: PLATFORM.moduleName('components/net-type/net-type-detail') },
    },
  },
  {
    route: 'admin/net-type/new',
    name: 'net-type-new',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/net-type/net-type-list') },
      side: { moduleId: PLATFORM.moduleName('components/net-type/net-type-new') },
    },
  },
  {
    route: 'admin/customer-net-type',
    name: 'customer-net-type-list',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/customer-net-type/customer-net-type-list') },
      side: { moduleId: null },
    },
  },
  {
    route: 'admin/customer-net-type/:Id',
    name: 'customer-net-type-detail',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/customer-net-type/customer-net-type-list') },
      side: { moduleId: PLATFORM.moduleName('components/customer-net-type/customer-net-type-detail') },
    },
  },
  {
    route: 'admin/customer-net-type/new',
    name: 'customer-net-type-new',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/customer-net-type/customer-net-type-list') },
      side: { moduleId: PLATFORM.moduleName('components/customer-net-type/customer-net-type-new') },
    },
  },
  {
    route: 'admin/net-shape',
    name: 'net-shape-list',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/net-shape/net-shape-list') },
      side: { moduleId: null },
    },
  },
  {
    route: 'admin/net-shape/:Id',
    name: 'net-shape-detail',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/net-shape/net-shape-list') },
      side: { moduleId: PLATFORM.moduleName('components/net-shape/net-shape-detail') },
    },
  },
  {
    route: 'admin/net-shape/new',
    name: 'net-shape-new',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/net-shape/net-shape-list') },
      side: { moduleId: PLATFORM.moduleName('components/net-shape/net-shape-new') },
    },
  },
  {
    route: 'admin/netting-type',
    name: 'netting-type-list',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/netting-type/netting-type-list') },
      side: { moduleId: null },
    },
    title: 'notlogg.nettingtype_plural',
  },
  {
    route: 'admin/netting-type/:Id',
    name: 'netting-type-detail',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/netting-type/netting-type-list') },
      side: { moduleId: PLATFORM.moduleName('components/netting-type/netting-type-detail') },
    },
  },
  {
    route: 'admin/netting-type/new',
    name: 'netting-type-new',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/netting-type/netting-type-list') },
      side: { moduleId: PLATFORM.moduleName('components/netting-type/netting-type-new') },
    },
  },
  {
    route: 'admin/placement',
    name: 'placement-list',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/placement/placement-list') },
      side: { moduleId: null },
    },
  },
  {
    route: 'admin/placement/:Id',
    name: 'placement-detail',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/placement/placement-list') },
      side: { moduleId: PLATFORM.moduleName('components/placement/placement-detail') },
    },
  },
  {
    route: 'admin/placement/new',
    name: 'placement-new',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/placement/placement-list') },
      side: { moduleId: PLATFORM.moduleName('components/placement/placement-new') },
    },
  },
  {
    route: 'admin/rope-dimension',
    name: 'rope-dimension-list',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/rope-dimension/rope-dimension-list') },
      side: { moduleId: null },
    },
  },
  {
    route: 'admin/rope-dimension/:Id',
    name: 'rope-dimension-detail',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/rope-dimension/rope-dimension-list') },
      side: { moduleId: PLATFORM.moduleName('components/rope-dimension/rope-dimension-detail') },
    },
  },
  {
    route: 'admin/rope-dimension/new',
    name: 'rope-dimension-new',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/rope-dimension/rope-dimension-list') },
      side: { moduleId: PLATFORM.moduleName('components/rope-dimension/rope-dimension-new') },
    },
  },
  {
    route: 'admin/rope-placement',
    name: 'rope-placement-list',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/rope-placement/rope-placement-list') },
      side: { moduleId: null },
    },
  },
  {
    route: 'admin/rope-placement/:Id',
    name: 'rope-placement-detail',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/rope-placement/rope-placement-list') },
      side: { moduleId: PLATFORM.moduleName('components/rope-placement/rope-placement-detail') },
    },
  },
  {
    route: 'admin/rope-placement/new',
    name: 'rope-placement-new',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/rope-placement/rope-placement-list') },
      side: { moduleId: PLATFORM.moduleName('components/rope-placement/rope-placement-new') },
    },
  },
  {
    route: 'admin/rope-type',
    name: 'rope-type-list',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/rope-type/rope-type-list') },
      side: { moduleId: null },
    },
    title: 'notlogg.ropetype_plural',
  },
  {
    route: 'admin/rope-type/:Id',
    name: 'rope-type-detail',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/rope-type/rope-type-list') },
      side: { moduleId: PLATFORM.moduleName('components/rope-type/rope-type-detail') },
    },
  },
  {
    route: 'admin/rope-type/new',
    name: 'rope-type-new',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/rope-type/rope-type-list') },
      side: { moduleId: PLATFORM.moduleName('components/rope-type/rope-type-new') },
    },
  },
  {
    route: 'admin/material',
    name: 'material-list',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/material/material-list') },
      side: { moduleId: null },
    },
  },
  {
    route: 'admin/material/:Id',
    name: 'material-detail',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/material/material-list') },
      side: { moduleId: PLATFORM.moduleName('components/material/material-detail') },
    },
  },
  {
    route: 'admin/material/new',
    name: 'material-new',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/material/material-list') },
      side: { moduleId: PLATFORM.moduleName('components/material/material-new') },
    },
  },
  {
    route: 'admin/thread-type',
    name: 'thread-type-list',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/thread-type/thread-type-list') },
      side: { moduleId: null },
    },
  },
  {
    route: 'admin/thread-type/:Id',
    name: 'thread-type-detail',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/thread-type/thread-type-list') },
      side: { moduleId: PLATFORM.moduleName('components/thread-type/thread-type-detail') },
    },
  },
  {
    route: 'admin/thread-type/new',
    name: 'thread-type-new',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/thread-type/thread-type-list') },
      side: { moduleId: PLATFORM.moduleName('components/thread-type/thread-type-new') },
    },
  },
  {
    route: 'admin/nylon-type',
    name: 'nylon-type-list',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/nylon-type/nylon-type-list') },
      side: { moduleId: null },
    },
  },
  {
    route: 'admin/nylon-type/:Id',
    name: 'nylon-type-detail',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/nylon-type/nylon-type-list') },
      side: { moduleId: PLATFORM.moduleName('components/nylon-type/nylon-type-detail') },
    },
  },
  {
    route: 'admin/nylon-type/new',
    name: 'nylon-type-new',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/nylon-type/nylon-type-list') },
      side: { moduleId: PLATFORM.moduleName('components/nylon-type/nylon-type-new') },
    },
  },
  {
    route: 'admin/net-intent',
    name: 'net-intent-list',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/net-intent/net-intent-list') },
      side: { moduleId: null },
    },
  },
  {
    route: 'admin/net-intent/:Id',
    name: 'net-intent-detail',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/net-intent/net-intent-list') },
      side: { moduleId: PLATFORM.moduleName('components/net-intent/net-intent-detail') },
    },
  },
  {
    route: 'admin/net-intent/new',
    name: 'net-intent-new',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/net-intent/net-intent-list') },
      side: { moduleId: PLATFORM.moduleName('components/net-intent/net-intent-new') },
    },
  },
  {
    route: 'admin/equipment-type',
    name: 'equipment-type-list',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/equipment-type/equipment-type-list') },
      side: { moduleId: null },
    },
  },
  {
    route: 'admin/equipment-type/:Id',
    name: 'equipment-type-detail',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/equipment-type/equipment-type-detail') },
      side: { moduleId: null },
    },
  },
  {
    route: 'admin/equipment-type/new',
    name: 'equipment-type-new',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/equipment-type/equipment-type-new') },
      side: { moduleId: null },
    },
  },
  {
    route: 'admin/recycling-company',
    name: 'recycling-company-list',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/recycling-company/recycling-company-list') },
      side: { moduleId: null },
    },
  },
  {
    route: 'admin/recycling-company/:Id',
    name: 'recycling-company-detail',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/recycling-company/recycling-company-list') },
      side: { moduleId: PLATFORM.moduleName('components/recycling-company/recycling-company-detail') },
    },
  },
  {
    route: 'admin/recycling-company/new',
    name: 'recycling-company-new',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/recycling-company/recycling-company-list') },
      side: { moduleId: PLATFORM.moduleName('components/recycling-company/recycling-company-new') },
    },
  },
  {
    route: 'admin/loop-position-horizontal',
    name: 'loop-position-horizontal-list',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/loop-position-horizontal/loop-position-horizontal-list') },
      side: { moduleId: null },
    },
  },
  {
    route: 'admin/loop-position-horizontal/:Id',
    name: 'loop-position-horizontal-detail',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/loop-position-horizontal/loop-position-horizontal-list') },
      side: { moduleId: PLATFORM.moduleName('components/loop-position-horizontal/loop-position-horizontal-detail') },
    },
  },
  {
    route: 'admin/loop-position-horizontal/new',
    name: 'loop-position-horizontal-new',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/loop-position-horizontal/loop-position-horizontal-list') },
      side: { moduleId: PLATFORM.moduleName('components/loop-position-horizontal/loop-position-horizontal-new') },
    },
  },
  {
    route: 'admin/loop-position-vertical',
    name: 'loop-position-vertical-list',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/loop-position-vertical/loop-position-vertical-list') },
      side: { moduleId: null },
    },
  },
  {
    route: 'admin/loop-position-vertical/:Id',
    name: 'loop-position-vertical-detail',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/loop-position-vertical/loop-position-vertical-list') },
      side: { moduleId: PLATFORM.moduleName('components/loop-position-vertical/loop-position-vertical-detail') },
    },
  },
  {
    route: 'admin/loop-position-vertical/new',
    name: 'loop-position-vertical-new',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/loop-position-vertical/loop-position-vertical-list') },
      side: { moduleId: PLATFORM.moduleName('components/loop-position-vertical/loop-position-vertical-new') },
    },
  },
  {
    name: 'coupling-list',
    route: 'admin/coupling',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/coupling/coupling-list') },
      side: { moduleId: null },
    },
  },
  {
    route: 'admin/coupling/:Id',
    name: 'coupling-detail',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/coupling/coupling-list') },
      side: { moduleId: PLATFORM.moduleName('components/coupling/coupling-detail') },
    },
  },
  {
    route: 'admin/coupling/new',
    name: 'coupling-new',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/coupling/coupling-list') },
      side: { moduleId: PLATFORM.moduleName('components/coupling/coupling-new') },
    },
  },
  {
    route: 'admin/cutting-strap-type',
    name: 'cutting-strap-type-list',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/cutting-strap-type/cutting-strap-type-list') },
      side: { moduleId: null },
    },
  },
  {
    route: 'admin/cutting-strap-type/:Id',
    name: 'cutting-strap-type-detail',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/cutting-strap-type/cutting-strap-type-list') },
      side: { moduleId: PLATFORM.moduleName('components/cutting-strap-type/cutting-strap-type-detail') },
    },
  },
  {
    route: 'admin/cutting-strap-type/new',
    name: 'cutting-strap-type-new',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/cutting-strap-type/cutting-strap-type-list') },
      side: { moduleId: PLATFORM.moduleName('components/cutting-strap-type/cutting-strap-type-new') },
    },
  },
  {
    route: 'admin/loop-type',
    name: 'loop-type-list',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/loop-type/loop-type-list') },
      side: { moduleId: null },
    },
  },
  {
    route: 'admin/loop-type/:Id',
    name: 'loop-type-detail',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/loop-type/loop-type-list') },
      side: { moduleId: PLATFORM.moduleName('components/loop-type/loop-type-detail') },
    },
  },
  {
    route: 'admin/loop-type/new',
    name: 'loop-type-new',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/loop-type/loop-type-list') },
      side: { moduleId: PLATFORM.moduleName('components/loop-type/loop-type-new') },
    },
  },
  {
    route: 'admin/preparation-rope-knot',
    name: 'preparation-rope-knot-list',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/preparation-rope-knot/preparation-rope-knot-list') },
      side: { moduleId: null },
    },
  },
  {
    route: 'admin/preparation-rope-knot/:Id',
    name: 'preparation-rope-knot-detail',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/preparation-rope-knot/preparation-rope-knot-list') },
      side: { moduleId: PLATFORM.moduleName('components/preparation-rope-knot/preparation-rope-knot-detail') },
    },
  },
  {
    route: 'admin/preparation-rope-knot/new',
    name: 'preparation-rope-knot-new',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/preparation-rope-knot/preparation-rope-knot-list') },
      side: { moduleId: PLATFORM.moduleName('components/preparation-rope-knot/preparation-rope-knot-new') },
    },
  },
  {
    route: 'admin/preparation-rope-placement',
    name: 'preparation-rope-placement-list',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: {
        moduleId: PLATFORM.moduleName('components/preparation-rope-placement/preparation-rope-placement-list'),
      },
      side: { moduleId: null },
    },
  },
  {
    route: 'admin/preparation-rope-placement/:Id',
    name: 'preparation-rope-placement-detail',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: {
        moduleId: PLATFORM.moduleName('components/preparation-rope-placement/preparation-rope-placement-list'),
      },
      side: {
        moduleId: PLATFORM.moduleName('components/preparation-rope-placement/preparation-rope-placement-detail'),
      },
    },
  },
  {
    route: 'admin/preparation-rope-placement/new',
    name: 'preparation-rope-placement-new',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: {
        moduleId: PLATFORM.moduleName('components/preparation-rope-placement/preparation-rope-placement-list'),
      },
      side: { moduleId: PLATFORM.moduleName('components/preparation-rope-placement/preparation-rope-placement-new') },
    },
  },
  {
    route: 'admin/strap-type',
    name: 'strap-type-list',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/strap-type/strap-type-list') },
      side: { moduleId: null },
    },
  },
  {
    route: 'admin/strap-type/:Id',
    name: 'strap-type-detail',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/strap-type/strap-type-list') },
      side: { moduleId: PLATFORM.moduleName('components/strap-type/strap-type-detail') },
    },
  },
  {
    route: 'admin/strap-type/new',
    name: 'strap-type-new',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/strap-type/strap-type-list') },
      side: { moduleId: PLATFORM.moduleName('components/strap-type/strap-type-new') },
    },
  },
  {
    route: 'admin/packaging-type',
    name: 'packaging-type-list',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/packaging-type/packaging-type-list') },
      side: { moduleId: null },
    },
  },
  {
    route: 'admin/packaging-type/:Id',
    name: 'packaging-type-detail',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/packaging-type/packaging-type-list') },
      side: { moduleId: PLATFORM.moduleName('components/packaging-type/packaging-type-detail') },
    },
  },
  {
    route: 'admin/packaging-type/new',
    name: 'packaging-type-new',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/packaging-type/packaging-type-list') },
      side: { moduleId: PLATFORM.moduleName('components/packaging-type/packaging-type-new') },
    },
  },
  {
    route: 'admin/packaging-method',
    name: 'packaging-method-list',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/packaging-method/packaging-method-list') },
      side: { moduleId: null },
    },
  },
  {
    route: 'admin/packaging-method/:Id',
    name: 'packaging-method-detail',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/packaging-method/packaging-method-list') },
      side: { moduleId: PLATFORM.moduleName('components/packaging-method/packaging-method-detail') },
    },
  },
  {
    route: 'admin/packaging-method/new',
    name: 'packaging-method-new',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/packaging-method/packaging-method-list') },
      side: { moduleId: PLATFORM.moduleName('components/packaging-method/packaging-method-new') },
    },
  },
  {
    route: 'admin/netting-color',
    name: 'netting-color-list',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/netting-color/netting-color-list') },
      side: { moduleId: null },
    },
  },
  {
    route: 'admin/netting-color/:Id',
    name: 'netting-color-detail',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/netting-color/netting-color-list') },
      side: { moduleId: PLATFORM.moduleName('components/netting-color/netting-color-detail') },
    },
  },
  {
    route: 'admin/netting-color/new',
    name: 'netting-color-new',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/netting-color/netting-color-list') },
      side: { moduleId: PLATFORM.moduleName('components/netting-color/netting-color-new') },
    },
  },
  {
    route: 'admin/disinfection-type',
    name: 'disinfection-type-list',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/disinfection-type/disinfection-type-list') },
      side: { moduleId: null },
    },
  },
  {
    route: 'admin/disinfection-type/:Id',
    name: 'disinfection-type-detail',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/disinfection-type/disinfection-type-list') },
      side: { moduleId: PLATFORM.moduleName('components/disinfection-type/disinfection-type-detail') },
    },
  },
  {
    route: 'admin/disinfection-type/new',
    name: 'disinfection-type-new',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/disinfection-type/disinfection-type-list') },
      side: { moduleId: PLATFORM.moduleName('components/disinfection-type/disinfection-type-new') },
    },
  },
  {
    route: 'admin/detergent-type',
    name: 'detergent-type-list',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/detergent-type/detergent-type-list') },
      side: { moduleId: null },
    },
  },
  {
    route: 'admin/service/invoice-standard-products',
    name: 'service-invoice-standard-products',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('views/admin/service/invoice-standard-products') },
      side: { moduleId: null },
    },
  },
  {
    route: 'admin/service/invoice-spagetti-products',
    name: 'service-invoice-spagetti-products',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('views/admin/service/invoice-spagetti-products') },
      side: { moduleId: null },
    },
  },
  {
    route: 'admin/service/waste-matrix',
    name: 'service-waste-matrix',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('views/admin/service/waste-matrix-view') },
      side: { moduleId: null },
    },
  },
  {
    route: 'admin/service/wash-matrix',
    name: 'service-wash-matrix',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('views/admin/service/wash-matrix-view') },
      side: { moduleId: null },
    },
  },
  {
    route: 'admin/service/pricing',
    name: 'service-pricing',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('views/admin/service/pricing-view') },
      side: { moduleId: null },
    },
  },
  {
    route: 'admin/service/rope-type',
    name: 'service-rope-type-list',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('views/admin/service/rope-type/rope-type-view') },
      side: { moduleId: null },
    },
  },
  {
    route: 'admin/service/rope-type/:Id',
    name: 'service-rope-type-edit',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('views/admin/service/rope-type/rope-type-view') },
      side: { moduleId: PLATFORM.moduleName('views/admin/service/rope-type/rope-type-edit') },
    },
  },
  {
    route: 'admin/service/netting-type',
    name: 'service-netting-type-list',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('views/admin/service/netting-type/netting-type-view') },
      side: { moduleId: null },
    },
  },
  {
    route: 'admin/service/netting-type/:Id',
    name: 'service-netting-type-edit',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('views/admin/service/netting-type/netting-type-view') },
      side: { moduleId: PLATFORM.moduleName('views/admin/service/netting-type/netting-type-edit') },
    },
  },
  {
    route: 'admin/service/loop-type',
    name: 'service-loop-type-list',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('views/admin/service/loop-type/loop-type-view') },
      side: { moduleId: null },
    },
  },
  {
    route: 'admin/service/loop-type/:Id',
    name: 'service-loop-type-edit',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('views/admin/service/loop-type/loop-type-view') },
      side: { moduleId: PLATFORM.moduleName('views/admin/service/loop-type/loop-type-edit') },
    },
  },
  {
    route: 'admin/service/packaging-product',
    name: 'service-packaging-product-list',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('views/admin/service/packaging-product/packaging-product-view') },
      side: { moduleId: null },
    },
  },
  {
    route: 'admin/service/packaging-product/:Id',
    name: 'service-packaging-product-edit',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('views/admin/service/packaging-product/packaging-product-view') },
      side: { moduleId: PLATFORM.moduleName('views/admin/service/packaging-product/packaging-product-edit') },
    },
  },
  {
    route: 'admin/detergent-type/:Id',
    name: 'detergent-type-detail',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/detergent-type/detergent-type-list') },
      side: { moduleId: PLATFORM.moduleName('components/detergent-type/detergent-type-detail') },
    },
  },
  {
    route: 'admin/detergent-type/new',
    name: 'detergent-type-new',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/detergent-type/detergent-type-list') },
      side: { moduleId: PLATFORM.moduleName('components/detergent-type/detergent-type-new') },
    },
  },
  {
    route: 'admin/segment',
    name: 'segment-list',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/segment/segment-list') },
      side: { moduleId: null },
    },
  },
  {
    route: 'admin/segment/:Id',
    name: 'segment-detail',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/segment/segment-list') },
      side: { moduleId: PLATFORM.moduleName('components/segment/segment-detail') },
    },
  },
  {
    route: 'admin/segment/new',
    name: 'segment-new',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/segment/segment-list') },
      side: { moduleId: PLATFORM.moduleName('components/segment/segment-new') },
    },
  },
  {
    name: 'sinkertube-list',
    route: 'admin/sinkertube',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/sinkertube/sinkertube-list') },
      side: { moduleId: null },
    },
  },
  {
    route: 'admin/sinkertube/:Id',
    name: 'sinkertube-detail',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/sinkertube/sinkertube-list') },
      side: { moduleId: PLATFORM.moduleName('components/sinkertube/sinkertube-detail') },
    },
  },
  {
    route: 'admin/sinkertube/new',
    name: 'sinkertube-new',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/sinkertube/sinkertube-list') },
      side: { moduleId: PLATFORM.moduleName('components/sinkertube/sinkertube-new') },
    },
  },
  {
    route: 'admin/internal-department',
    name: 'internal-department-list',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/internal-department/internal-department-list') },
      side: { moduleId: null },
    },
  },
  {
    route: 'admin/internal-department/:Id',
    name: 'internal-department-detail',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/internal-department/internal-department-list') },
      side: { moduleId: PLATFORM.moduleName('components/internal-department/internal-department-detail') },
    },
  },
  {
    route: 'admin/internal-department/new',
    name: 'internal-department-new',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/internal-department/internal-department-list') },
      side: { moduleId: PLATFORM.moduleName('components/internal-department/internal-department-new') },
    },
  },
  {
    route: 'admin/general-offer-type',
    name: 'general-offer-type-list',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/general-offer-type/general-offer-type-list') },
      side: { moduleId: null },
    },
  },
  {
    route: 'admin/general-offer-type/:Id',
    name: 'general-offer-type-detail',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/general-offer-type/general-offer-type-list') },
      side: { moduleId: PLATFORM.moduleName('components/general-offer-type/general-offer-type-detail') },
    },
  },
  {
    route: 'admin/general-offer-type/new',
    name: 'general-offer-type-new',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/general-offer-type/general-offer-type-list') },
      side: { moduleId: PLATFORM.moduleName('components/general-offer-type/general-offer-type-new') },
    },
  },

  {
    route: 'admin/design-type-side-rope-combination',
    name: 'design-type-side-rope-combination-list',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: {
        moduleId: PLATFORM.moduleName(
          'components/design-type-side-rope-combination/design-type-side-rope-combination-list'
        ),
      },
      side: { moduleId: null },
    },
  },

  {
    route: 'admin/design-type-drawing-management',
    name: 'design-type-drawing-management',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: {
        moduleId: PLATFORM.moduleName('components/design-type-drawing-management/design-type-drawing-management'),
      },
      side: { moduleId: null },
    },
  },

  {
    route: 'admin/revision',
    name: 'revision',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: {
        moduleId: PLATFORM.moduleName('components/revision/revision'),
      },
      side: { moduleId: null },
    },
  },

  {
    route: 'admin/design-type-side-rope-combination/:Id',
    name: 'design-type-side-rope-combination-detail',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: {
        moduleId: PLATFORM.moduleName(
          'components/design-type-side-rope-combination/design-type-side-rope-combination-list'
        ),
      },
      side: {
        moduleId: PLATFORM.moduleName(
          'components/design-type-side-rope-combination/design-type-side-rope-combination-detail'
        ),
      },
    },
  },
  {
    route: 'admin/design-type-side-rope-combination/:Id/new',
    name: 'design-type-side-rope-combination-new',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: {
        moduleId: PLATFORM.moduleName(
          'components/design-type-side-rope-combination/design-type-side-rope-combination-list'
        ),
      },
      side: {
        moduleId: PLATFORM.moduleName(
          'components/design-type-side-rope-combination/design-type-side-rope-combination-new'
        ),
      },
    },
  },

  {
    route: 'admin/floater-type',
    name: 'floater-type-list',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/floater-type/floater-type-list') },
      side: { moduleId: null },
    },
  },
  {
    route: 'admin/floater-type/:Id',
    name: 'floater-type-detail',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/floater-type/floater-type-list') },
      side: { moduleId: PLATFORM.moduleName('components/floater-type/floater-type-detail') },
    },
  },
  {
    route: 'admin/floater-type/new',
    name: 'floater-type-new',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/floater-type/floater-type-list') },
      side: { moduleId: PLATFORM.moduleName('components/floater-type/floater-type-new') },
    },
  },

  //
  {
    route: 'admin/ring-sinker-net-connection-type',
    name: 'ring-sinker-net-connection-type-list',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: {
        moduleId: PLATFORM.moduleName(
          'components/ring-sinker-net-connection-type/ring-sinker-net-connection-type-list'
        ),
      },
      side: { moduleId: null },
    },
  },
  {
    route: 'admin/ring-sinker-net-connection-type/:Id',
    name: 'ring-sinker-net-connection-type-detail',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: {
        moduleId: PLATFORM.moduleName(
          'components/ring-sinker-net-connection-type/ring-sinker-net-connection-type-list'
        ),
      },
      side: {
        moduleId: PLATFORM.moduleName(
          'components/ring-sinker-net-connection-type/ring-sinker-net-connection-type-detail'
        ),
      },
    },
  },
  {
    route: 'admin/ring-sinker-net-connection-type/new',
    name: 'ring-sinker-net-connection-type-new',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: {
        moduleId: PLATFORM.moduleName(
          'components/ring-sinker-net-connection-type/ring-sinker-net-connection-type-list'
        ),
      },
      side: {
        moduleId: PLATFORM.moduleName('components/ring-sinker-net-connection-type/ring-sinker-net-connection-type-new'),
      },
    },
  },

  //
  {
    route: 'admin/ring-sinker-floater-connection-type',
    name: 'ring-sinker-floater-connection-type-list',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: {
        moduleId: PLATFORM.moduleName(
          'components/ring-sinker-floater-connection-type/ring-sinker-floater-connection-type-list'
        ),
      },
      side: { moduleId: null },
    },
  },
  {
    route: 'admin/ring-sinker-floater-connection-type/:Id',
    name: 'ring-sinker-floater-connection-type-detail',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: {
        moduleId: PLATFORM.moduleName(
          'components/ring-sinker-floater-connection-type/ring-sinker-floater-connection-type-list'
        ),
      },
      side: {
        moduleId: PLATFORM.moduleName(
          'components/ring-sinker-floater-connection-type/ring-sinker-floater-connection-type-detail'
        ),
      },
    },
  },
  {
    route: 'admin/ring-sinker-floater-connection-type/new',
    name: 'ring-sinker-floater-connection-type-new',
    settings: { roles: [roles.administrator] },
    viewPorts: {
      main: {
        moduleId: PLATFORM.moduleName(
          'components/ring-sinker-floater-connection-type/ring-sinker-floater-connection-type-list'
        ),
      },
      side: {
        moduleId: PLATFORM.moduleName(
          'components/ring-sinker-floater-connection-type/ring-sinker-floater-connection-type-new'
        ),
      },
    },
  },

  // Mooring
  {
    route: 'admin/mooring/mooring-stations',
    name: 'mooring-stations-list',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('views/admin/mooring/mooring-stations-list-view') },
      side: { moduleId: null },
    },
  },
  {
    route: 'admin/mooring/mooring-stations/create',
    name: 'create-mooring-station',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('views/admin/mooring/mooring-stations-create-edit-view') },
      side: { moduleId: null },
    },
  },
  {
    route: 'admin/mooring/mooring-stations/:Id',
    name: 'edit-mooring-station',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('views/admin/mooring/mooring-stations-create-edit-view') },
      side: { moduleId: null },
    },
  },
  {
    route: 'admin/mooring/article-register',
    name: 'mooring-article-register',
    settings: { roles: [roles.administrator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('views/admin/mooring/article-register') },
      side: { moduleId: null },
    },
  },
];
