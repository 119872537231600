import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';

const units = {
  kg: 'general.kilogram_short',
  meter: 'general.meter_short',
  liter: 'general.liter_short',
  ton: 'general.ton_short',
};

@autoinject
export class SiUnitValueConverter {
  constructor(private i18n: I18N) {}

  public toView(value: number | string | undefined, unit: 'kg' | 'ton' | 'meter' | 'liter') {
    if (!unit) return 'Unknwon unit';
    if (!value) {
      return '-';
    }

    const formated = new Intl.NumberFormat('nb-NO').formatToParts(Number(value));
    const integers = formated.filter((p) => p.type == 'integer').map((p) => p.value);
    const decimals = formated.filter((p) => p.type == 'decimal' || p.type == 'fraction').map((p) => p.value);

    const ints = integers?.join(' ');
    const lefts = decimals?.join('');

    return ints + lefts + this.i18n.tr(units[unit] ?? `Unknwon unit ${unit}`);
  }
}
