import { bindable } from 'aurelia-framework';
import { BaseButton } from './base-button';

const buttonVariantClasses = {
  primary: 'button--primary',
  secondary: 'button--secondary',
  tertiary: 'button--tertiary',
  ok: 'button--ok',
  warning: 'button--warning',
  danger: 'button--danger',
};

type Variants = keyof typeof buttonVariantClasses;

export class IconButton extends BaseButton {
  @bindable()
  variant: Variants;

  @bindable()
  icon: string;

  constructor() {
    super();
  }

  attached() {
    if (!this.variant) {
      this.variant = 'primary';
    }

    if (!this.icon) {
      throw new Error(`[${IconButton.name}] Icon name must be provided, get names from: https://phosphoricons.com/`);
    }

    if (!buttonVariantClasses[this.variant]) {
      throw new Error(
        `[${IconButton.name}] Unknown button variant: ${this.variant}, expected one of: ${Object.keys(buttonVariantClasses).join(', ')}`
      );
    }

    this.styleClasses = buttonVariantClasses[this.variant];
  }
}
