class StationDetails {
  Name: string;
  OrgNo: string;
  Address1: string;
  Address2: string;
  Zip: string;
  City: string;
  Country: string;
  Phone: string;
  Email: string;
  ContactName: string;
  ContactPhone: string;
  ContactEmail: string;
  //
  CertificationNumber: string;
  QaManager: string;
  //
  SellerCode: string;
  DepartmentCode: string;
  LocationCode: string;
}

export class CreateMooringStation extends StationDetails {
  static ApiUrl = 'api/mooring-stations';
  Id?: number;
}

export class MooringStation extends StationDetails {
  static ApiUrl = 'api/mooring-stations';
  Id: number;
}

export class MooringStationListItem {
  static ApiUrl = 'api/mooring-stations/table';
  Id: number;
  Name: string;
}
