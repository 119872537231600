import { inject, customElement, bindable, DOM } from 'aurelia-framework';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

@customElement('popover')
@inject(DOM.Element)
export class Popover {
  public element: HTMLElement;
  public target: HTMLElement;
  public template: HTMLElement;
  private tippyEl: any;

  constructor(element: HTMLElement) {
    this.element = element;
  }

  @bindable({ defaultValue: null })
  public triggerTarget: Element;

  @bindable public appendTo: string | Element | Function;

  @bindable({ defaultValue: false })
  public arrow: boolean;

  @bindable({ defaultValue: [0, 20] })
  public delay: Array<Number>;

  @bindable({ defaultValue: [275, 250] })
  public duration: Array<Number>;

  @bindable({ defaultValue: 'click' })
  public trigger: string;

  @bindable({ defaultValue: 'top' })
  public placement: string;

  @bindable({ defaultValue: true })
  public interactive: boolean;

  @bindable({ defaultValue: 'default' })
  public theme: string;

  @bindable({ defaultValue: true })
  public hideOnClick: boolean;

  @bindable({ defaultValue: null })
  public onHidden: Function;

  @bindable({ defaultValue: null })
  public onShow: Function;

  @bindable({ defaultValue: 350 })
  public maxWidth: number;

  public attached(): void {
    let content = this.template;

    if (this.hideOnClick) {
      content.addEventListener('click', (_) => {
        if (this.tippyEl) {
          this.tippyEl.hide();
        }
      });
    }

    const options = {
      arrow: this.arrow,
      content: content,
      delay: <any>this.delay,
      duration: <any>this.duration,
      interactive: this.interactive,
      trigger: this.trigger,
      placement: <any>this.placement,
      theme: this.theme,
      triggerTarget: this.triggerTarget,
      maxWidth: this.maxWidth,
    };

    if (this.appendTo) options['appendTo'] = this.appendTo;
    if (this.onHidden) options['onHidden'] = this.onHidden;
    if (this.onShow) options['onShow'] = this.onShow;

    this.tippyEl = tippy(this.element, options);
  }
}
