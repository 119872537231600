import { NetStandardService } from './../../services/net-standard-service';
import { DesignTypeSideRopeCombinationService } from './../../services/design-type-side-rope-combination-service';
import { CustomerNetTypeService } from 'services/customer-net-type-service';
import { NettingService } from 'services/netting-service';
import { DialogCloseResult, DialogService } from 'aurelia-dialog';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { autoinject, bindable, LogManager } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Prompt } from 'elements/prompt';
import { Models } from 'models/core';
import { DesignTypeService } from 'services/design-type-service';
import { DimensionClassService } from 'services/dimension-class-service';
import { ErrorService } from 'services/error-service';
import { NetDimensionService } from 'services/net-dimension-service';
import { NetIntentService } from 'services/net-intent-service';
import { NetService } from 'services/net-service';
import { NetShapeService } from 'services/net-shape-service';
import { NetTypeService } from 'services/net-type-service';
import { NettingTypeService } from 'services/netting-type-service';
import { ToastService } from 'services/toast-service';
import { Utility } from '../../utility';

import { I18N } from 'aurelia-i18n';

import { ValidationController, ValidationRules } from 'aurelia-validation';
import { Logger } from 'aurelia-logging';
import { NetOfferService } from 'services/net-offer-service';
import { AnalysisLookupDialog } from 'components/dialogs/analysis-lookup/analysis-lookup-dialog';
import { ConfirmDialog } from 'components/dialogs/confirm/confirm-dialog';
import { SimpleOfferTabBase } from './simple-offer-tab-base';
import { dimensionClass } from 'models/NetDimension';

const logger: Logger = LogManager.getLogger('simple-offer-tab-dimensions');

@autoinject
export class SimpleOfferTabDimensions extends SimpleOfferTabBase {
  @bindable private netId: number;

  @bindable private net: Models.Net;
  @bindable private netdimensionCalculated;
  @bindable private updateFunction;
  @bindable private cancelFunction;
  @bindable private calcFunction;
  @bindable private dimensionclassType;
  @bindable private locked = false;

  private originalObject: any;
  private nettingtypes: Array<Models.NettingType>;
  private dimensionclasses: Array<Models.DimensionClass>;
  private nextTabIndex: number = null;
  private refreshDimensionsSubscription: Subscription;

  private netTypes: Array<Models.NetType>;
  private allSideSideRopeOptions: Array<any> = null;
  private numberOfSidesOptions: Array<any> = [];
  private numberOfSideRopesOptions: Array<any>;
  private numberOfLiftingRopesOptions: Array<any>;
  private changeTabSubscription: Subscription;

  public formIsValid = false;
  public nettings: Array<Models.Netting>;

  protected is2021Standard = false;
  protected classType = '';

  constructor(
    protected designTypeService: DesignTypeService,
    protected dialogService: DialogService,
    protected dimensionClassService: DimensionClassService,
    protected netStandardService: NetStandardService,
    protected designTypeSideRopeCombinationService: DesignTypeSideRopeCombinationService,
    protected customerNetTypeService: CustomerNetTypeService,
    protected eventAggregator: EventAggregator,
    protected netDimensionService: NetDimensionService,
    protected netIntentService: NetIntentService,
    protected netService: NetService,
    protected netShapeService: NetShapeService,
    protected nettingTypeService: NettingTypeService,
    protected netTypeService: NetTypeService,
    protected router: Router,
    protected toastService: ToastService,
    protected nettingService: NettingService,
    protected i18n: I18N,
    protected validationController: ValidationController,
    protected utility: Utility,
    protected confirm: ConfirmDialog,
    errorService: ErrorService,
    netOfferService: NetOfferService
  ) {
    super(netOfferService, errorService);
  }

  protected async activate(params: { NetId: number; Id: number }) {
    this.netId = params.NetId || params.Id;
    await this.setOfferIsOrder(params.Id);
    void this.getNet(this.netId);

    // Get new tabIndex for tabs component via EA, store value so we can publish this if canDeactivate returns true
    this.changeTabSubscription = this.eventAggregator.subscribe('changeTab', (tabIndex: number) => {
      this.nextTabIndex = tabIndex;
    });

    this.refreshDimensionsSubscription = this.eventAggregator.subscribe('refresh-calculated-dimensions', () => {
      this.getNetDimensionCalculated();
    });
  }

  private changeTab(direction = 'next') {
    if (direction === 'prev') {
      this.eventAggregator.publish('triggerPrevTab', true);
    } else {
      this.eventAggregator.publish('triggerNextTab', true);
    }
  }

  protected async validateAndSave() {
    try {
      await this.validateAsync();
      void this.save();
    } catch (error) {
      this.toastService.showWarning('general.checkValidationErrors');
    }
  }

  private saveAndChangeTab(direction = 'next') {
    // does it validate ?
    this.validateAsync()
      .then(() => {
        // check if different
        if (!this.utility.areEqual(this.net, this.originalObject, true)) {
          // save
          this.save().then((res) => {
            this.changeTab(direction);
          });
        } else {
          this.changeTab(direction);
        }
      })
      .catch((error) => {
        this.toastService.showWarning('general.checkValidationErrors');
      });
  }

  private getNetTypes(): Promise<Array<Models.NetType>> {
    return this.netTypeService.getAllCached().then((netTypes) => {
      this.netTypes = netTypes;
      return netTypes;
    });
  }

  private getNettingTypeName(netting: Models.Netting) {
    if (netting.NettingTypeId) {
      return this.nettingtypes.filter((nettingType) => nettingType.Id === netting.NettingTypeId).map((nettingType) => nettingType.Name);
    } else {
      let nettingTypeName = '';
      if (netting.Material) {
        nettingTypeName += netting.Material.Name + ' ';
      }
      if (netting.ThreadType) {
        nettingTypeName += netting.ThreadType.Name + ' ';
      }
      if (netting.NylonType) {
        nettingTypeName += netting.NylonType.Name + ' ';
      }
      if (netting.MeshSize) {
        nettingTypeName += netting.MeshSize.Name;
      }
      return nettingTypeName;
    }
  }

  // Get net and netdimension
  private async getNet(id) {
    this.netService
      .get(id + '?$expand=NetDimension($expand=DesignType,NetType)')
      .then(async (res) => {
        this.net = res;
        this.locked = res.Locked || this.netIsLinkedToAnalysis(res.NetDimension) || this.offerIsOrder ? true : false;

        if (this.net.NetDimension.DimensionClassId) {
          if (this.net.NetDimension.DimensionClassId === 1) {
            this.dimensionclassType = 0;
          } else if (this.net.NetDimension.DimensionClassId === 10) {
            this.dimensionclassType = 1;
          } else if (this.net.NetDimension.DimensionClassId > 1 && this.net.NetDimension.DimensionClassId < 9) {
            this.dimensionclassType = 2;
          }
        }

        this.allSideSideRopeOptions = await this.designTypeSideRopeCombinationService.getValidCombinations(this.netId);
        this.setupValidSidesAndSideRopes();
        this.getNetDimensionCalculated();
        this.updateOriginalObject();
        this.addValidationRules();
        this.validateFormSilent();
        void this.setNetStandardFlag();
      })
      .catch((err) => this.errorService.handleError(err));

    this.nettingService
      .getAll('?$filter=NetId eq ' + id + '&$expand=MeshSize,NylonType,ThreadType,Material')
      .then((nettings) => {
        this.nettings = nettings;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private addValidationRules() {
    ValidationRules.ensure('NetTypeId')
      .required()
      .withMessage(this.i18n.tr('general.requiredField'))
      .ensure('SideCount')
      .required()
      .withMessage(this.i18n.tr('general.requiredField'))
      .ensure('SideRopes')
      .required()
      .withMessage(this.i18n.tr('general.requiredField'))
      .ensure('LiftingRopes')
      .required()
      .withMessage(this.i18n.tr('general.requiredField'))
      .ensure('CircumferenceProduction')
      .required()
      .when((n: Models.NetDimension) => n.NetShapeId !== 9)
      .withMessage(this.i18n.tr('general.requiredField'))
      .ensure('SquareLength')
      .required()
      .when((n: Models.NetDimension) => n.NetShapeId === 9)
      .withMessage(this.i18n.tr('general.requiredField'))
      .ensure('SquareWidth')
      .required()
      .when((n: Models.NetDimension) => n.NetShapeId === 9)
      .withMessage(this.i18n.tr('general.requiredField'))
      .ensure('DepthBottom')
      .required()
      .withMessage(this.i18n.tr('general.requiredField'))
      .on(this.net.NetDimension);

    // TODO: forskjellige valideringsregler for firkant vs rund not
  }

  private getNetDimensionCalculated() {
    this.netDimensionService
      .calculateValues(this.net.Id, this.net.NetDimension)
      .then((res) => {
        this.netdimensionCalculated = res;
        if (!this.locked) {
          if (this.netdimensionCalculated?.NetStandard?.Version === Models.NetStandardVersion.NS9415_2021) {
            this.net.NetDimension.DimensionClassId = this.netdimensionCalculated.CalculatedDimensionClassId;
          } else {
            if (this.net.NetDimension.DimensionClassId > 11) {
              this.setDimensionClassId(this.netdimensionCalculated.CalculatedDimensionClassId);
            }

            if (
              !this.net.NetDimension.OverrideDimensionClass &&
              this.net.NetDimension.DimensionClassId !== this.netdimensionCalculated.CalculatedDimensionClassId
            ) {
              this.net.NetDimension.DimensionClassId = this.netdimensionCalculated.CalculatedDimensionClassId;

              if (this.netdimensionCalculated.CalculatedDimensionClassId === 1) {
                this.dimensionclassType = 0;
              }

              if (this.netdimensionCalculated.CalculatedDimensionClassId > 1 && this.netdimensionCalculated.CalculatedDimensionClassId < 9) {
                this.dimensionclassType = 2;
              }
            }
          }

          // for coned/spagetti nets, calculate the bottom rope circumference
          if (this.net.NetDimension.NetShapeId === 3 || this.net.NetDimension.NetShapeId === 7) {
            this.net.NetDimension.BottomRopeCircumference = this.netdimensionCalculated.BottomRopeCircumference;
          }

          this.setupValidSidesAndSideRopes();
        }
        this.classType = dimensionClass[this.netdimensionCalculated.CalculatedDimensionClassId];
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private async save(): Promise<void> {
    // we need to remove the expanded objects to avoid problems when saving
    const netType = this.net.NetDimension.NetType;
    const designType = this.net.NetDimension.DesignType;
    delete this.net.NetDimension.NetType;
    delete this.net.NetDimension.DesignType;

    // Sets the height of the small fish jumpnet to the main jump net if there is no value for small fish jumpnet.
    if (this.net.NetDimension.IsCombiNet && this.net.NetDimension.HeightJumpNet && !this.net.NetDimension.HeightJumpNetSmallFish) {
      this.net.NetDimension.HeightJumpNetSmallFish = this.net.NetDimension.HeightJumpNet;
    }

    try {
      await this.netDimensionService.put(this.net.NetDimension, this.net.NetDimension.Id);

      this.toastService.showSuccess('netdimension.updated');
      this.eventAggregator.publish('offer-refresh', 'price');

      // reset state for expanded objects
      this.net.NetDimension.NetType = netType;
      this.net.NetDimension.DesignType = designType;

      this.updateOriginalObject();
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  private setupValidSidesAndSideRopes() {
    this.numberOfSidesOptions = Array.from(new Set(this.allSideSideRopeOptions.map((x) => x.Sides))).map((x) => {
      return { Value: x };
    });

    if (this.net.NetDimension.SideCount && !this.numberOfSidesOptions.find((x) => x.Value == this.net.NetDimension.SideCount)) {
      this.net.NetDimension.SideCount = null;
      this.net.NetDimension.SideRopes = null;
      this.net.NetDimension.LiftingRopes = null;
    }

    if (this.net.NetDimension.SideCount) {
      let minSideRopes = 0;
      const maxSideRopeDistance = this.netdimensionCalculated?.MaxDistanceBetweenVerticalRopes;
      if (maxSideRopeDistance && this.netdimensionCalculated && maxSideRopeDistance !== 0 && this.netdimensionCalculated.CircumferenceCage) {
        minSideRopes = Math.floor(this.netdimensionCalculated.CircumferenceCage / maxSideRopeDistance);
      }

      // logger.debug('setupValidSidesAndSideRopes - maxSideRopeDistance: ' + maxSideRopeDistance + ', minSideRopes: ' + minSideRopes + ', circumferencecage: ' + this.netdimensionCalculated?.CircumferenceCage);

      if (minSideRopes === 0) {
        // not enough info to find number of valid sideropes
        return;
      }

      const newOptions = this.allSideSideRopeOptions
        .filter((x) => x.Sides == this.net.NetDimension.SideCount && x.SideRopes >= minSideRopes)
        .map((x) => {
          return { Value: x.SideRopes };
        });

      // only update the numberOfSideRopesOptions if the array has changed (to avoid to much data binding trouble)
      if (!this.numberOfSideRopesOptions || !this.netDimensionService.compareArrays(newOptions, this.numberOfSideRopesOptions, 'Value')) {
        this.numberOfSideRopesOptions = null;

        setTimeout(() => {
          this.numberOfSideRopesOptions = newOptions;

          if (this.net.NetDimension.SideRopes && !this.numberOfSideRopesOptions.find((x) => x.Value == this.net.NetDimension.SideRopes)) {
            this.net.NetDimension.SideRopes = null;
          }

          if (this.numberOfSideRopesOptions.length === 1) {
            this.net.NetDimension.SideRopes = this.numberOfSideRopesOptions[0].Value;
          }

          this.setLiftingRopesOptions();
        });
      }
    } else {
      // clear side ropes and liftingropes, we dont have enough info
      this.numberOfSideRopesOptions = null;
      this.net.NetDimension.SideRopes = null;
      this.numberOfSideRopesOptions = null;
      this.net.NetDimension.SideRopes = null;
    }
  }

  private setLiftingRopesOptions() {
    if (this.net.NetDimension.SideRopes) {
      let minLiftingRopes = 0;
      const maxLiftingRopeDistance = this.netdimensionCalculated?.MaxDistanceBetweenLiftingRopes;
      if (
        maxLiftingRopeDistance &&
        this.netdimensionCalculated &&
        maxLiftingRopeDistance !== 0 &&
        this.netdimensionCalculated.CircumferenceCage
      ) {
        minLiftingRopes = Math.floor(this.netdimensionCalculated.CircumferenceCage / maxLiftingRopeDistance);
      }

      // logger.debug('setLiftingRopesOptions - maxLiftingRopeDistance: ' + maxLiftingRopeDistance + ', minLiftingRopes ' + minLiftingRopes + ', circumference ' + this.netdimensionCalculated?.CircumferenceCage);

      if (minLiftingRopes === 0) {
        // not enough info to find number of valid sideropes
        return;
      }

      const newOptions = this.allSideSideRopeOptions
        .filter(
          (x) => x.Sides == this.net.NetDimension.SideCount && x.SideRopes <= this.net.NetDimension.SideRopes && x.SideRopes >= minLiftingRopes
        )
        .map((x) => {
          return { Value: x.SideRopes };
        });

      // only update the numberOfSideRopesOptions if the array has changed (to avoid to much data binding trouble)
      if (!this.numberOfLiftingRopesOptions || !this.netDimensionService.compareArrays(newOptions, this.numberOfLiftingRopesOptions, 'Value')) {
        this.numberOfLiftingRopesOptions = null;

        setTimeout(() => {
          // set new options in a setTimeout to let Aurelia update the binding before rebinding
          this.numberOfLiftingRopesOptions = newOptions;

          if (
            this.net.NetDimension.LiftingRopes &&
            !this.numberOfLiftingRopesOptions.find((x) => x.Value == this.net.NetDimension.LiftingRopes)
          ) {
            this.net.NetDimension.LiftingRopes = null;
          }

          if (this.numberOfLiftingRopesOptions.length === 1) {
            this.net.NetDimension.LiftingRopes = this.numberOfLiftingRopesOptions[0].Value;
          }
        });
      }
    } else {
      // if we have not defined sideropes, we cannot set liftingropes
      this.numberOfLiftingRopesOptions = null;
      this.net.NetDimension.LiftingRopes = null;
    }
  }

  async setNetStandardFlag() {
    const standards = await this.netStandardService.getAllCached();
    const standard = standards.find((s) => +s.Id === +this.net.NetDimension.NetStandardId);
    this.is2021Standard = standard?.Version === Models.NetStandardVersion.NS9415_2021;
    if (this.is2021Standard) this.dimensionclassType = 0;
    this.eventAggregator.publish('net-standard-changed', standard);
  }

  async getNetStandards() {
    //NetStandard RES 1821 (Chile) is temporary only visible and used in used net so hide here
    return await this.netStandardService.getAll('?$filter=Id ne ' + Models.NetStandardId.RES_1821);
  }

  setNetStandard(standardId: number) {
    if (!this.net || this.net.NetDimension.NetStandardId == standardId) {
      return;
    }

    this.net.NetDimension.NetStandardId = standardId ? +standardId : null;

    void this.setNetStandardFlag();
    this.getNetDimensionCalculated();
  }

  private setSides(sideCount) {
    if (!this.net || !this.allSideSideRopeOptions || this.net.NetDimension.SideCount == sideCount) {
      return;
    }

    this.net.NetDimension.SideCount = sideCount ? +sideCount : null;
    this.setupValidSidesAndSideRopes();
    this.getNetDimensionCalculated();
    this.validateFormSilent();
  }

  private setSideRopes(sideRopes) {
    if (!this.net || !this.allSideSideRopeOptions || this.net.NetDimension.SideRopes == sideRopes) {
      return;
    }

    this.net.NetDimension.SideRopes = sideRopes ? +sideRopes : null;
    this.getNetDimensionCalculated();
    this.validateFormSilent();

    this.setLiftingRopesOptions();
  }

  private setLiftingRopes(liftingRopes) {
    if (!this.net || !this.allSideSideRopeOptions || this.net.NetDimension.LiftingRopes == liftingRopes) {
      return;
    }

    this.net.NetDimension.LiftingRopes = liftingRopes ? +liftingRopes : null;
    this.getNetDimensionCalculated();
    this.validateFormSilent();
  }

  private setNetTypeId(netTypeId) {
    if (!this.net || this.net.NetDimension.NetTypeId == netTypeId) {
      return;
    }
    this.net.NetDimension.NetTypeId = netTypeId;

    const netType = this.netTypes.find((x) => x.Id == netTypeId);
    this.net.NetDimension.NetType = netType;

    this.getNetDimensionCalculated();
    this.validateFormSilent();
  }

  private updateOriginalObject() {
    if (!this.net) {
      return;
    }
    this.originalObject = JSON.parse(JSON.stringify(this.net));
  }

  private attached() {
    this.nettingTypeService
      .getAllCached()
      .then((res) => {
        this.nettingtypes = res;
      })
      .catch((err) => this.errorService.handleError(err));
    this.dimensionClassService
      .getAllCached()
      .then((res) => {
        this.dimensionclasses = res;
        // Remove dimension classes > 1 && < 9
        this.dimensionclasses = this.dimensionclasses.filter((obj) => obj.Id > 1 && obj.Id < 9);
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private detached() {
    if (this.changeTabSubscription) {
      this.changeTabSubscription.dispose();
    }
    if (this.refreshDimensionsSubscription) {
      this.refreshDimensionsSubscription.dispose();
    }
  }

  private deactivate() {
    if (this.changeTabSubscription) {
      this.changeTabSubscription.dispose();
    }

    if (this.refreshDimensionsSubscription) {
      this.refreshDimensionsSubscription.dispose();
    }
  }

  private setTableNet() {
    this.dimensionclassTypeChanged(2, this.dimensionclassType);
    return true;
  }

  private dimensionclassTypeChanged(newValue, oldValue) {
    if (newValue.toString() === '2') {
      // tabellnot - kun lov hvis kalkulert DimensionClassId er 2-8 (klasse 1-7)
      if (this.netdimensionCalculated) {
        if (this.net && this.net.NetDimension) {
          this.dimensionclassType = 2;
          this.net.NetDimension.Hs = 2.5;
          this.net.NetDimension.Vs = 0.75;
          this.net.NetDimension.DimensionClassId = this.netdimensionCalculated.CalculatedDimensionClassId;
          this.net.NetDimension.OverrideDimensionClass = false;
        }
      }
    }
  }

  private setDimensionClassId(dimensionClassId: number) {
    this.net.NetDimension.DimensionClassId = dimensionClassId;
    this.net.NetDimension.OverrideDimensionClass = true;

    if (this.net.NetDimension.DimensionClassId === 1) {
      this.dimensionclassType = 0;
    } else if (this.net.NetDimension.DimensionClassId === 10) {
      this.dimensionclassType = 1;
    } else if (this.net.NetDimension.DimensionClassId > 1 && this.net.NetDimension.DimensionClassId < 9) {
      this.dimensionclassType = 2;
    }

    return true;
  }

  private cancel() {
    this.router.navigateToRoute('offer-list');
  }

  private validateForm() {
    this.validationController.validate().then((result) => {
      if (result.valid) {
        this.formIsValid = true;
      } else {
        this.formIsValid = false;
      }
    });
  }

  private validateFormSilent() {
    this.validationController.validate().then((result) => {
      if (result.valid) {
        this.formIsValid = true;
      } else {
        this.formIsValid = false;
      }
      this.validationController.reset();
    });
  }

  private async validateAsync(): Promise<any> {
    return new Promise<void>(async (resolve, reject) => {
      this.validationController
        .validate()
        .then((result) => {
          if (result.valid) {
            this.formIsValid = true;
            resolve();
          } else {
            this.formIsValid = false;
            reject();
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  private canDeactivate(params) {
    this.validateForm();
    /*  if (!this.formIsValid) {
        this.toastService.showWarning("general.checkValidationErrors");
        return false;
      }
  */
    if (this.originalObject && !this.utility.areEqual(this.net, this.originalObject, true) && !this.locked) {
      // tslint:disable-next-line:no-console
      return this.dialogService
        .open({
          viewModel: Prompt,
          model: {
            header: 'dialog.pleaseConfirmHeader',
            message: 'dialog.unsavedChangesText',
          },
        })
        .whenClosed((response) => {
          if (response.wasCancelled) {
            return false;
          } else {
            const result = response.output;
            if (result === 'save') {
              // save the nettingtype and let that function handle the rest of the logic
              this.save();
              return false;
            } else {
              this.eventAggregator.publish('changeTab-success', this.nextTabIndex);
              return true;
            }
          }
        });
    } else {
      this.eventAggregator.publish('changeTab-success', this.nextTabIndex);
      return true;
    }
  }

  protected lookup(slot: number | undefined, part: number) {
    this.dialogService
      .open({
        viewModel: AnalysisLookupDialog,
        model: {
          netId: this.net?.Id,
          slot,
        },
        lock: false,
        position: () => {
          //
        },
      })
      .whenClosed(async (response: DialogCloseResult) => {
        if (response.wasCancelled) return false;
        const analysis = response.output;

        try {
          await this.netDimensionService.linkAnalysis(this.net.NetDimensionId, {
            Slot: slot,
            AnalysisId: analysis.AnalysisId,
            Part: part,
            AnalysisVariantId: analysis.AnalysisVariantId,
          });
          await this.getNet(this.net.Id);
        } catch (error) {
          this.errorService.handleError(error);
        }

        document.querySelector('html').style.overflowY = null;
      })
      .catch((err) => {
        document.querySelector('html').style.overflowY = null;
        this.errorService.handleError(err);
      });
  }

  protected async removeAnalysis(slot: number, part: number) {
    try {
      await this.netDimensionService.unLinkAnalysis(this.net.NetDimensionId, {
        Slot: slot,
        Part: part,
      });
      await this.getNet(this.net.Id);
    } catch (error) {
      this.errorService.handleError(error);
    }
  }
  protected async onIsCollectChanged(isCollect: boolean) {
    this.net.IsCollect = isCollect;
    try {
      await this.netService.put(
        {
          IsCollect: isCollect,
        } as Models.Net,
        this.net.Id
      );
      this.toastService.showSuccess('net.updated');
      await this.getNet(this.net.Id);
    } catch (error) {
      this.errorService.handleError(error);
    }
  }
}
