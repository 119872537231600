import { autoinject, bindable } from 'aurelia-framework';

@autoinject
export class BaseButton {
  @bindable()
  disabled: boolean = false;

  @bindable()
  onClick: () => void;

  @bindable()
  classes: string = '';

  protected styleClasses = '';

  constructor() {}

  attached() {}
}
