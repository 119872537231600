import { CreateUpdateMooringComponent, MooringComponent } from './MooringComponent';

class BaseMooringGridLine {
  MoorlogNo: string;
  AnalysisNo: string;
  DimForce?: number;
  IsAccident: boolean;
  MinMblRopesStraps?: number;
  MinMblChainShackleConnectionPlates?: number;
  MinMblAnchoring?: number;

  Length: number;
  SplicedLength: number;
}

export class UpdateMooringGridLineDto extends BaseMooringGridLine {
  Id: number;
  Components: CreateUpdateMooringComponent[];
}

export class MooringGridLine extends BaseMooringGridLine {
  Id: number;
  Components: MooringComponent[];
  HasUnresolvedComments: boolean;
  HasUnresolvedComponentComments: boolean;
  HasComments: boolean;
}
