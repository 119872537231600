import { autoinject, bindable, observable } from 'aurelia-framework';
import { ErrorService } from 'services/error-service';
import { ValidationController, ValidationRules, Validator } from 'aurelia-validation';
import { I18N } from 'aurelia-i18n';
import { ProductMaterialCategory } from 'models';
import { MooringProductType } from 'models/mooring';

@autoinject
export class MooringProductMaterialCategoryCreateEdit {
  @bindable
  @observable
  model: MooringProductType = new ProductMaterialCategory();
  modelChanged(model: MooringProductType) {
    if (!model) return;
    this.form = JSON.parse(JSON.stringify(model));
  }

  @bindable
  onSave: (data: { data: ProductMaterialCategory }) => void;

  @bindable
  onCanceled: () => void;

  @bindable
  visible = false;

  protected form: MooringProductType = {} as ProductMaterialCategory;

  constructor(
    private errorService: ErrorService,
    private validationController: ValidationController,
    private validator: Validator,
    private t: I18N
  ) {}

  protected applyValidationRules() {
    ValidationRules.ensure('Name')
      .required()
      .withMessage(this.t.tr('general.requiredField'))
      .ensure('NameEn')
      .required()
      .withMessage(this.t.tr('general.requiredField'))
      .on(this.form);
  }

  attached() {
    this.applyValidationRules();
  }

  private async validate() {
    this.applyValidationRules();
    await this.validator.validateObject(this.form);
    return (await this.validationController.validate({ object: this.form })).valid;
  }

  async save() {
    try {
      const valid = await this.validate();
      if (!valid) return;
      this.onSave?.({ data: this.form });
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  cancel() {
    this.onCanceled?.();
  }
}
