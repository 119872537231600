import { activationStrategy } from 'aurelia-router';
import { PLATFORM } from 'aurelia-framework';
import { roles } from 'routes';

export const net_routes = [
  {
    route: ['nets'],
    name: 'net-list',
    nav: true,
    title: 'general.net',
    settings: {
      searchable: true,
      roles: [roles.serviceuser, roles.sales, roles.production, roles.productioncoordinator],
    },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/net/net-list') },
      side: { moduleId: null },
    },
    route_base: 'net',
    section: 'products',
  },
  {
    route: ['special-products'],
    name: 'special-product-list',
    nav: true,
    title: 'general.specialproducts',
    settings: {
      searchable: true,
      roles: [roles.serviceuser, roles.sales, roles.production, roles.productioncoordinator],
    },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/net/net-list') },
      side: { moduleId: null },
    },
    route_base: 'special-product',
    section: 'products',
  },
  {
    route: 'special-product-detail/new',
    name: 'special-product-new',
    settings: { roles: [roles.serviceuser, roles.sales], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/special-product/special-product-new') },
      side: { moduleId: null },
    },
    title: 'specialproduct.new',
    route_base: 'special-product',
  },
  {
    route: ['special-product-detail/:Id/edit'],
    name: 'special-product-edit',
    settings: { roles: [roles.serviceuser, roles.sales] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/special-product/special-product-edit') },
      side: { moduleId: null },
    },
    route_base: 'special-product',
  },
  {
    route: 'special-product-detail/:Id',
    name: 'special-product-detail',
    settings: {},
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/special-product/special-product-detail-main') },
      side: { moduleId: null },
    },
    activationStrategy: activationStrategy.invokeLifecycle,
    route_base: 'special-product',
  },
  {
    route: 'net-detail/:Id',
    name: 'net-detail',
    settings: {},
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/net/net-detail-main') },
      side: { moduleId: null },
    },
    activationStrategy: activationStrategy.invokeLifecycle,
    route_base: 'net',
  },
  {
    route: 'net-detail/new',
    name: 'net-new',
    settings: { roles: [roles.serviceuser, roles.sales], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/net/net-new') },
      side: { moduleId: null },
    },
    title: 'net.new',
  },
  {
    route: ['net-detail/:Id/edit'],
    name: 'net-edit',
    settings: { roles: [roles.serviceuser, roles.sales] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/net/net-edit') },
      side: { moduleId: null },
    },
  },
  {
    route: ['net/:Id/copy'],
    name: 'net-copy',
    settings: { searchable: false },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/net/net-copy') },
      side: { moduleId: null },
    },
    title: 'net.netcopy',
  },
];

export const net_order_routes = [
  {
    route: ['orders'],
    name: 'order-list',
    settings: { roles: [roles.sales, roles.production, roles.productioncoordinator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/order/order-list') },
      side: { moduleId: null },
    },
    nav: true,
    title: 'general.order_plural',
    route_base: 'order',
    section: 'net',
  },
  {
    route: ['order/:Id/net/:NetId/'],
    name: 'order-detail',
    settings: { roles: [roles.sales, roles.production, roles.productioncoordinator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/order/order-detail-main') },
      side: { moduleId: null },
    },
    activationStrategy: activationStrategy.invokeLifecycle,
  },
  {
    route: ['order/:Id/net/:NetId/edit'],
    name: 'order-edit',
    settings: { roles: [roles.sales, roles.production, roles.productioncoordinator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/order/order-edit') },
      side: { moduleId: null },
    },
  },
];

export const net_offer_routes = [
  {
    route: ['offers'],
    name: 'offer-list',
    settings: { roles: [roles.sales, roles.productioncoordinator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/offer/offer-list') },
      side: { moduleId: null },
    },
    nav: true,
    title: 'general.offer_plural',
    route_base: 'offer',
    section: 'net',
  },
  {
    route: ['offer/:Id/net/:NetId/'],
    name: 'offer-detail',
    settings: { roles: [roles.sales, roles.productioncoordinator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/offer/offer-detail-main') },
      side: { moduleId: null },
    },
    activationStrategy: activationStrategy.invokeLifecycle,
  },
  {
    route: ['offer/new'],
    name: 'offer-new',
    settings: { roles: [roles.sales, roles.productioncoordinator], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/offer/offer-new') },
      side: { moduleId: null },
    },
    title: 'offer.new',
  },
  {
    route: ['offer/:Id/net/:NetId/edit'],
    name: 'offer-edit',
    settings: { roles: [roles.sales, roles.productioncoordinator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/offer/offer-edit') },
      side: { moduleId: null },
    },
  },
  {
    route: ['offer/:Id/net/:NetId/convert'],
    name: 'offer-create-order',
    settings: { roles: [roles.sales, roles.productioncoordinator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/offer/offer-create-order') },
      side: { moduleId: null },
    },
  },
  {
    route: ['simple-offer/:Id/net/:NetId/'],
    name: 'simple-offer-detail',
    settings: { roles: [roles.sales, roles.productioncoordinator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/simple-offer/simple-offer-detail') },
      side: { moduleId: null },
    },
    activationStrategy: activationStrategy.invokeLifecycle,
  },
  {
    route: ['price-calculation-data'],
    name: 'price-calculation-data',
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/price-calculation-data/price-calculation-data-details') },
      side: { moduleId: null },
    },
  },
];
