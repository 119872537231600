import { containerless, autoinject, bindable } from 'aurelia-framework';
import './tool-bar.scss';

@containerless()
@autoinject()
export class ToolBar {
  @bindable()
  floating: boolean = false;

  @bindable()
  classes: boolean = false;

  _classes: string = '';

  constructor() {}

  attached() {
    if (this.floating) {
      this._classes += 'mn-tool-bar--floating-bottom';
    }

    if (this.classes) {
      this._classes += ` ${this.classes}`;
    }
  }
}
