import './comment-tooltip.scss';
import { autoinject, bindable } from 'aurelia-framework';

export type TooltipComment = {
  Id?: string;
  Comment?: string;
  CreatedBy: string;
  Created: Date;

  ResolveComment?: string;
  ResolvedBy: string;
  Resolved: Date;
};

@autoinject
export class CommentTooltip {
  @bindable({ defaultValue: {} })
  comment?: TooltipComment = {} as TooltipComment;

  @bindable
  commentReadOnly?: boolean;

  @bindable
  onComment: ({ comment }: { comment: string }) => void;

  @bindable
  canResolve = false;

  @bindable
  onResolve: ({ comment }: { comment?: string }) => void;

  @bindable
  requireResolveComment = false;

  @bindable
  onDelete: () => void;

  protected save() {
    this.onComment?.({ comment: this.comment.Comment });
  }

  protected delete() {
    this.onDelete?.();
  }

  protected resolve() {
    this.onResolve?.({ comment: this.comment.ResolveComment });
  }
}
