import { PLATFORM } from 'aurelia-framework';
import { roles } from 'routes';

export const analysis_routes = [
  {
    route: ['analysis/list'],
    name: 'analysis-list',
    settings: { roles: [roles.analysisEngineer], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/analysis/analysis-list') },
      side: { moduleId: null },
    },
    nav: true,
    title: 'analysis.title_plural',
    route_base: 'analysis',
    section: 'net',
  },
  {
    route: ['analysis/details/:Id'],
    name: 'analysis-details',
    settings: { roles: [roles.analysisEngineer], searchable: false },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/analysis/analysis-details') },
      side: { moduleId: null },
    },
    nav: false,
    title: 'analysis.title',
    route_base: 'analysis',
  },
];
