import { Container } from 'aurelia-framework';
import { ErrorService } from 'services';

export function ComponentLoadingFader(_: unknown, __: string, descriptor: PropertyDescriptor) {
  const originalMethod = descriptor.value;
  const errorService = Container.instance.get(ErrorService);
  descriptor.value = async function (...args: unknown[]) {
    const component: HTMLElement = this.element;
    if (!component) {
      throw new Error('Module must inject Element in constructor => constructor(protected element: Element, ...');
    }
    try {
      component?.classList?.remove('component-transition-fade');
      component?.classList?.add('component-transition-fade-active');
      this.loader = true;
      const res = await originalMethod.apply(this, args);
      this.loader = false;

      component?.classList?.add('component-transition-fade');
      component?.classList?.remove('component-transition-fade-active');
      return res;
    } catch (error) {
      errorService.handleError(error);
    } finally {
      setTimeout(() => {
        component?.classList?.remove('component-transition-fade');
        component?.classList?.remove('component-transition-fade-active');
      }, 210);
    }
  };

  return descriptor;
}

export function ViewLoadingFader(_: unknown, __: string, descriptor: PropertyDescriptor) {
  const originalMethod = descriptor.value;
  const errorService = Container.instance.get(ErrorService);
  descriptor.value = async function (...args: unknown[]) {
    let component: HTMLElement = this.element;
    if (!component) {
      throw new Error('Module must inject Element in constructor => constructor(protected element: Element, ...');
    }
    try {
      component = component.querySelector('.app-view-loader');
      component?.classList?.remove('view-transition-fade-hidden', 'view-transition-fade');
      component?.classList?.add('view-transition-fade-active');

      this.loader = true;
      const res = await originalMethod.apply(this, args);
      this.loader = false;
      component?.classList?.add('view-transition-fade');
      component?.classList?.remove('view-transition-fade-active');
      return res;
    } catch (error) {
      errorService.handleError(error);
    } finally {
      setTimeout(() => {
        component?.classList?.add('view-transition-fade-hidden');
        component?.classList?.remove('view-transition-fade');
      }, 200);
    }
  };

  return descriptor;
}
