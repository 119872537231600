import { PLATFORM } from 'aurelia-framework';

export const customer_routes = [
  {
    route: ['customers'],
    name: 'customer-list',
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/customer/customer-list') },
      side: { moduleId: null },
    },
    settings: { searchable: true },
    nav: true,
    title: 'general.customer_plural',
    route_base: 'customer',
    section: 'crm',
  },
  {
    route: ['customer/:Id'],
    name: 'customer-detail',
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/customer/customer-detail') },
      side: { moduleId: null },
    },
  },
  {
    route: ['customer/new'],
    name: 'customer-new',
    settings: { searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/customer/customer-new') },
      side: { moduleId: null },
    },
    title: 'customer.new',
  },
  {
    route: ['customer/:Id/merge'],
    name: 'customer-merge',
    settings: { searchable: false },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/customer/customer-merge') },
      side: { moduleId: null },
    },
    title: 'customer.merge',
  },
  {
    route: ['customer/:Id/edit'],
    name: 'customer-edit',
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/customer/customer-edit') },
      side: { moduleId: null },
    },
  },
];
