import { autoinject } from 'aurelia-framework';
import { MooringArticle } from 'models/mooring';
import { MooringArticleProduct } from 'models/mooring/MooringArticleProduct';
import { AuthHttpClient } from 'services/auth-http-client';
import { BaseApiService } from 'services/base-api-service';

@autoinject
export class MooringArticleProductService extends BaseApiService<MooringArticleProduct> {
  constructor(httpClient: AuthHttpClient) {
    super(httpClient, MooringArticleProduct);
  }

  async items() {
    return await this.httpClient.exec<MooringArticleProduct[]>(MooringArticleProduct.ApiUrl + '/items', {
      method: 'GET',
    });
  }

  async article(articleProductId: number) {
    return await this.httpClient.exec<MooringArticle>(MooringArticleProduct.ApiUrl + `/${articleProductId}/article`, {
      method: 'GET',
    });
  }
}
