import { Exclude, Expose } from 'class-transformer';
import { BaseEntity } from 'models/BaseEntity';

@Exclude()
export class AnalysisCompany extends BaseEntity {
  @Expose()
  Name: string;

  @Expose()
  IsMorenot: boolean;

  @Expose()
  Mooring: boolean;
}
