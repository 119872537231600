import { RouterConfiguration } from 'aurelia-router';
import { tabRoutes } from './article-register-tab-routes';
import { autoinject } from 'aurelia-framework';
import { TabManager } from './tab-manager';
import { MooringArticleDialog } from 'features/mooring/mooring-article-dialog';
import { DialogService } from 'aurelia-dialog';

@autoinject()
export class ArticleRegister {
  constructor(private tabManager: TabManager, private dialogService: DialogService) {}

  get tab() {
    return this.tabManager.currentTabIndex;
  }

  private configureRouter(config: RouterConfiguration) {
    config.map(tabRoutes);
  }

  protected openArticleForm(id?: number) {
    void this.dialogService
      .open({
        viewModel: MooringArticleDialog,
        model: id ? { articleId: id } : undefined,
        position: () => {
          return {
            top: 0,
          };
        },
      })
      .whenClosed(() => {
        document.querySelector('html').style.overflowY = null;
      });
  }
}
