import { autoinject, bindable, observable } from 'aurelia-framework';
import { ErrorService } from 'services/error-service';
import { ValidationController, ValidationRules, Validator } from 'aurelia-validation';
import { I18N } from 'aurelia-i18n';
import { ProductMaterialCategory, ProductMaterialType } from 'models';
import { ProductMaterialCategoryService } from 'services';
import { MooringProductType } from 'models/mooring';

@autoinject
export class MooringProductMaterialTypeCreateEdit {
  @bindable
  @observable
  model: MooringProductType = new ProductMaterialType();
  modelChanged(model: ProductMaterialType) {
    if (!model) return;
    this.form = JSON.parse(JSON.stringify(model));
  }

  @bindable
  onSave: (data: { data: Partial<ProductMaterialType> }) => void;

  @bindable
  onCanceled: () => void;

  @bindable
  @observable
  visible = false;
  visibleChanged(visible: boolean) {
    if (visible) {
      void this.getCategories();
    }
  }

  protected categories: ProductMaterialCategory[] = [];

  protected form: Partial<ProductMaterialType> = {};

  constructor(
    private errorService: ErrorService,
    private validationController: ValidationController,
    private productMaterialCategoryService: ProductMaterialCategoryService,
    private validator: Validator,
    private t: I18N
  ) {}

  protected applyValidationRules() {
    ValidationRules.ensure('Name')
      .required()
      .withMessage(this.t.tr('general.requiredField'))
      .ensure('NameEn')
      .required()
      .withMessage(this.t.tr('general.requiredField'))
      .on(this.form);
  }

  attached() {
    this.applyValidationRules();
  }

  protected async getCategories() {
    try {
      this.categories = await this.productMaterialCategoryService.getAll();
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  private async validate() {
    this.applyValidationRules();
    await this.validator.validateObject(this.form);
    return (await this.validationController.validate({ object: this.form })).valid;
  }

  async save() {
    try {
      const valid = await this.validate();
      if (!valid) return;
      this.onSave?.({ data: this.form });
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  cancel() {
    this.onCanceled?.();
  }
}
