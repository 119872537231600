import { autoinject, bindable } from 'aurelia-framework';
import { ListField, ListFieldTyped } from 'models/ListSettings';
import { CommentForEntity, MooringAnchorLine, MooringComponent } from 'models/mooring';
import { MooringComponentSearchHandler } from 'services/mooring';
import { MooringArticleService } from 'services';
import { toKg, toM, toChecked } from 'lib/ui/ValueDisplayFormatters';
import { DialogService } from 'aurelia-dialog';
import { TableEntryManager } from 'services/ui-helpers';
import { MooringTableBase } from '../table-base';
import { PubSub } from 'lib/event/PubSub';
import { I18N } from 'aurelia-i18n';
import { ConfirmDialog } from 'components/dialogs/confirm/confirm-dialog';

@autoinject
export class AnchorLinesTable extends MooringTableBase<MooringAnchorLine> {
  protected tableColumns: ListField[] = tableColumns;

  @bindable
  protected onSave: (args: { entries: MooringAnchorLine[]; canNavigateOnSave: boolean }) => void;

  constructor(
    protected t: I18N,
    dialogService: DialogService,
    mooringArticleService: MooringArticleService,
    // Used in HTML for article search
    mooringComponentSearchHandler: MooringComponentSearchHandler,
    tableEntryManager: TableEntryManager,
    pubsub: PubSub,
    confirmation: ConfirmDialog
  ) {
    super('anchor-line', dialogService, mooringComponentSearchHandler, tableEntryManager, mooringArticleService, pubsub, confirmation);

    pubsub.sub('list-entity:updated', (data) => {
      if (!data) return;
      if (data.name === 'mooring-article-component') {
        this.onSave({
          entries: this.entries,
          canNavigateOnSave: false,
        });
      }
    });

    pubsub.sub('tabs:change-guard', async () => {
      if (this.changeTracker.areEqual(this.entries)) {
        this.pubsub.publish('tabs:change-guard-ok', null);
        return;
      }

      const res = await this.confirmation.confirmSave();
      if (res == 'cancel') {
        return;
      } else if (res === 'discard-continue') {
        this.pubsub.publish('tabs:change-guard-ok', null);
      } else {
        this.onSave({ entries: this.entries, canNavigateOnSave: true });
      }
    });
  }

  // _tableScrollHandler: () => void;
  // _tableResizeHandler: () => void;
  async attached() {
    await super.attached();

    // const fixedHeader = document.getElementById('table-fixed-header');
    // const tableHeader = document.getElementById('table-main-header');
    // const a = Math.random();
    //
    // // Match the width of the table header to the fixed header
    // this._tableResizeHandler = () => {
    //   const tableCells = tableHeader.querySelectorAll('th');
    //   const fixedCells = fixedHeader.querySelectorAll('th');
    //
    //   fixedCells.forEach((cell, index) => {
    //     cell.style.width = `${tableCells[index].offsetWidth}px`;
    //   });
    // };
    //
    // this._tableScrollHandler = () => {
    //   console.log(a);
    //   const rect = tableHeader.getBoundingClientRect();
    //   // Check if the table header is out of view
    //   if (rect.top <= 0) {
    //     fixedHeader.classList.remove('hidden');
    //     fixedHeader.style.width = `${rect.width}px`;
    //     this._tableResizeHandler();
    //   } else {
    //     fixedHeader.classList.add('hidden');
    //   }
    // };
    //
    // window.addEventListener('scroll', this._tableScrollHandler);
    // window.addEventListener('resize', this._tableResizeHandler);
  }

  protected sortTable() {
    console.log('sortTable');
  }

  protected save() {
    this.onSave({
      canNavigateOnSave: false,
      entries: this.entries,
    });
  }

  protected addEntryComment(component: MooringAnchorLine) {
    this.openCommentDialog({
      mooringId: this.mooringId,
      isReadOnly: false,
      componentId: component.Id,
      componentType: CommentForEntity.AnchorLine,
    });
  }

  protected addComponentComment(component: MooringComponent) {
    this.openCommentDialog({
      mooringId: this.mooringId,
      isReadOnly: false,
      componentId: component.Id,
      componentType: CommentForEntity.AnchorLineComponent,
    });
  }

  protected async canDeactivate() {
    console.log('w');

    return await new Promise((res) => {
      setTimeout(() => {
        res('');
      }, 3000);
    });
  }

  protected anchorLinenumberInputChange(index: number, name: (typeof tableColumns)[number]['field'], _: Event) {
    const line = this.entries[index];

    if (name === 'LengthOfRope' || name === 'LengthOfBottomRope') {
      line.TotalLength = (+line.LengthOfBottomRope || 0) + (+line.LengthOfRope || 0);
    }
  }

  protected unbind() {
    this.pubsub.unsub();
    super.unbind();
  }
}

const tableColumns: ListFieldTyped<MooringAnchorLine>[] = [
  {
    field: 'MoorlogNo',
    title: 'mooring.anchorLineNo',
    selected: true,
    visible: true,
    filterType: 'TEXT',
    inputType: 'text',
  },
  {
    field: 'AnalysisNo',
    title: 'mooring.analysisNo',
    selected: true,
    visible: true,
    filterType: 'TEXT',
    inputType: 'text',
  },
  {
    field: 'DimForce',
    title: 'mooring.dimForce',
    selected: true,
    visible: true,
    filterType: 'TEXT',
    transform: toKg,
    inputType: 'float',
  },
  {
    field: 'IsAccident',
    title: 'mooring.isAccident',
    selected: true,
    visible: true,
    filterType: 'BOOL',
    transform: toChecked,
    inputType: 'bool',
  },
  {
    field: 'MinMblRopesStraps',
    title: 'mooring.minMblRope',
    selected: true,
    visible: true,
    filterType: 'TEXT',
    transform: toKg,
    inputType: 'float',
  },
  {
    field: 'MinMblChainShackleConnectionPlates',
    title: 'mooring.minMblChain',
    selected: true,
    visible: true,
    filterType: 'TEXT',
    transform: toKg,
    inputType: 'float',
  },
  {
    field: 'MinMblAnchoring',
    title: 'mooring.minMblAnchoring',
    selected: true,
    visible: true,
    filterType: 'TEXT',
    transform: toKg,
    inputType: 'float',
  },
  {
    field: 'BottomAnchoringUplift',
    title: 'mooring.bottomAnchoringUplift',
    selected: true,
    visible: true,
    filterType: 'TEXT',
    transform: toKg,
    inputType: 'float',
  },
  {
    field: 'DepthAtAnchoringPoint',
    title: 'mooring.depthOfAnchoringPoint',
    selected: true,
    visible: true,
    filterType: 'TEXT',
    transform: toM,
    inputType: 'float',
  },
  {
    field: 'Slope',
    title: 'mooring.incline',
    selected: true,
    visible: true,
    filterType: 'TEXT',
    inputType: 'float',
  },
  {
    field: 'HoldingPowerAnchoring',
    title: 'mooring.holdingPowerAnchoring',
    selected: true,
    visible: true,
    filterType: 'TEXT',
    transform: toKg,
    inputType: 'float',
  },
  {
    field: 'LengthOfBottomRope',
    title: 'mooring.lengthOfBottomChain',
    selected: true,
    visible: true,
    filterType: 'TEXT',
    transform: toM,
    inputType: 'float',
  },
  {
    field: 'LengthOfRope',
    title: 'mooring.lengthOfRope',
    selected: true,
    visible: true,
    filterType: 'TEXT',
    transform: toM,
    inputType: 'float',
  },
  {
    field: 'TotalLength',
    title: 'mooring.totalLengthByAnchoring',
    selected: true,
    visible: true,
    filterType: 'TEXT',
    transform: toM,
    inputType: 'float',
  },
  {
    field: 'StartPositionLat',
    title: 'mooring.anchorLineStartN',
    selected: true,
    visible: true,
    filterType: 'TEXT',
    inputType: 'gps',
  },
  {
    field: 'StartPositionLong',
    title: 'mooring.anchorLineStartE',
    selected: true,
    visible: true,
    filterType: 'TEXT',
    inputType: 'gps',
  },
  {
    field: 'StopPositionLat',
    title: 'mooring.anchorLineStopN',
    selected: true,
    visible: true,
    filterType: 'TEXT',
    inputType: 'gps',
  },
  {
    field: 'StopPositionLong',
    title: 'mooring.anchorLineStopE',
    selected: true,
    visible: true,
    filterType: 'TEXT',
    inputType: 'gps',
  },
  {
    field: 'actions',
    title: '',
    selected: true,
    visible: true,
    filterType: 'none',
  },
];
