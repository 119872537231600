import { autoinject } from 'aurelia-framework';
import { NettingTypeService, CheckDuplicateNettingTypeService } from 'models';
import { AuthHttpClient } from 'services/auth-http-client';
import { BaseApiService } from 'services/base-api-service';

@autoinject
export class NettingTypeServiceService extends BaseApiService<NettingTypeService> {
  constructor(httpClient: AuthHttpClient) {
    super(httpClient, NettingTypeService);
  }

  public async checkDuplicate(data: CheckDuplicateNettingTypeService) {
    return await this.httpClient.exec<{ IsDuplicate: boolean }>(`${NettingTypeService.ApiUrl}/checkDuplicate`, {
      method: 'POST',
      body: JSON.stringify(data),
    });
  }
}
