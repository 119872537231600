import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { TableHeader } from 'components/table/table-content';
import { PaginationHandler } from 'elements/pagination-handler';
import { MooringCertificateListItem, MooringFrameCertificateListItem } from 'models/mooring';
import { MooringCertificateService } from 'services';
import { ErrorService } from 'services/error-service';
import { Router } from 'aurelia-router';
import { DataFilter } from 'lib/tables/DataFilter';
import { PubSub } from 'lib/event/PubSub';
import { getCallbackData } from 'elements/filterer';
import { Filters, filterTypes } from 'elements/Filter';
import { TabManager } from 'views/admin/mooring/tab-manager';
import { DialogService } from 'aurelia-dialog';
import { SalesOrderDialog } from 'views/mooring/mooring-salesorder/salesorder-dialog';
import { SiteService } from 'services/site-service';
import { CustomerService } from 'services/customer-service';
import { SystemFileStorageService } from 'services/system-file-storage-service';

type TableFilter = FrameFilters & DataFilter;

type FrameFilters = {
  customerIds?: number[];
  localityIds?: number[];
  orderDateFrom?: Date;
  orderDateTo?: Date;
};

@autoinject()
export class TabMooringFrameCertificate {
  protected certificates: MooringCertificateListItem[] = [];
  protected dataFilter: TableFilter = new DataFilter();

  protected tableHeaders: TableHeader<MooringFrameCertificateListItem & { actions: ''; certificate: '' }>[] = [
    { key: 'Id', label: 'general.id', sortable: true },
    { key: 'OrderId', label: 'general.orderId', sortable: true },
    { key: 'CertificateNumber', label: 'general.certificateNumber', sortable: true },
    { key: 'CustomerName', label: 'general.customer', sortable: true },
    { key: 'LocalityName', label: 'general.locality', sortable: true },
    { key: 'actions', label: '', sortable: false },
  ];

  constructor(
    private errorService: ErrorService,
    private certificateService: MooringCertificateService,
    private router: Router,
    private pagination: PaginationHandler,
    private pubsub: PubSub,
    private t: I18N,
    private tabManager: TabManager,
    private dialogService: DialogService,
    private systemFilesService: SystemFileStorageService,
    //
    private localityService: SiteService,
    private customersService: CustomerService
  ) {
    tabManager.tabActivated();
  }

  protected setupPagination() {
    const routeParams = this.router.currentInstruction?.queryParams;
    let currentPage = 1;
    if (routeParams && routeParams.currentPage) {
      currentPage = +routeParams.currentPage;
    }

    this.pubsub.sub('filter-section:search-text-changed', (data) => {
      if (data.context !== this.context) return;
      this.dataFilter.searchText = data.searchText;
      void this.getCertificates();
    });

    this.pubsub.sub('filter-section:reset-filters', (data) => {
      if (data.context !== this.context) return;
      this.filterClearAll();
    });

    this.pubsub.sub('filter-section:list-settings-changed', (data) => {
      if (this.dataFilter.top === data.settings.pageSize) return;

      this.dataFilter.top = data.settings.pageSize;
      this.pagination.pageSize = data.settings.pageSize;
      this.pagination.currentPage = 1;
      void this.getCertificates();
    });

    this.pubsub.sub('export-list', (data) => {
      if (data === 'mooring-certificates') {
        void this.getCertificates({ export: true });
      }
    });

    this.pubsub.sub('list-entity:created', () => {
      void this.getCertificates();
    });

    this.pubsub.sub('list-entity:updated', () => {
      void this.getCertificates();
    });

    this.pagination.init({
      currentPage: currentPage,
      totalItems: 0,
      onPageChanged: (_, pagination) => {
        this.dataFilter.skip = pagination.skip;
        this.dataFilter.top = pagination.top;
        void this.getCertificates();
      },
    });
  }

  async attached() {
    this.setupPagination();
    await this.getCertificates();
  }

  protected detached() {
    this.pubsub.unsub();
  }

  protected setOrderByColumn(column: string) {
    this.dataFilter.setOrderByAndFlipOrderDirectionIfSameKey(column);
    void this.getCertificates();
  }

  protected downloadCertificate(attachmentId: number) {
    try {
      this.systemFilesService.download(attachmentId, true);
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected context = 'mooring_frame_certificates';
  protected filterClearAll: () => void;
  protected filterClearSingle: (name: string) => void;
  protected filterToggleVisible: () => void;
  protected filterGetFiltersQuery: () => Promise<FrameFilters>;
  protected setFilterValueByName: (name: string, data: any) => void;

  protected defaultFilters = {
    customerIds: true,
    localityIds: true,
    orderDate: true,
  };

  protected getFilterKey() {
    return 'MOORING_CERTIFICATES_TABLE';
  }

  protected onFilterChanged() {
    void this.getCertificates();
  }

  getFilterValues() {
    return getCallbackData(this.filterGetFiltersQuery);
  }

  protected async setupFilters() {
    const localities = (await this.localityService.getAllCached())
      .filter((x) => !x.IsDeleted)
      .map((x) => ({ Id: x.Id, Name: x.Name }));

    const cusotmers = (await this.customersService.getAllCached())
      .filter((x) => !x.IsDeleted)
      .map((x) => ({ Id: x.Id, Name: x.Name }));

    return await new Promise((res: (v: Filters) => void) => {
      setTimeout(() => {
        const filters: Filters = {
          customerIds: {
            name: 'customerIds',
            label: this.t.tr('general.customer'),
            type: filterTypes.CHECKBOX,
            options: cusotmers,
            query: 'customerIds',
          },
          localityIds: {
            name: 'localityIds',
            label: this.t.tr('general.locality'),
            type: filterTypes.CHECKBOX,
            options: localities,
            query: 'localityIds',
          },
          orderDate: {
            name: 'orderDate',
            label: this.t.tr('general.orderDate'),
            type: filterTypes.RANGE_DATE,
          },
        };
        res(filters);
      });
    });
  }

  protected editSalesOrder(_: number, id: number) {
    void this.dialogService
      .open({
        viewModel: SalesOrderDialog,
        model: {
          salesOrderId: id,
        },
        position: () => {
          return {
            top: 0,
          };
        },
      })
      .whenClosed(() => {
        document.querySelector('html').style.overflowY = null;
        void this.getCertificates();
      });
  }

  protected async getCertificates(options?: { export: boolean }) {
    try {
      const filters = await this.filterGetFiltersQuery?.();
      this.dataFilter.export = options?.export || false;
      this.dataFilter.customerIds = filters?.customerIds || null;
      this.dataFilter.localityIds = filters?.localityIds || null;
      this.dataFilter.orderDateFrom = filters?.orderDateFrom || null;
      this.dataFilter.orderDateTo = filters?.orderDateTo || null;
      // this.dataFilter.locale = this.i18n.getLocale() as 'en' | 'nb' | 'es';

      const response = await this.certificateService.frameTable(this.dataFilter);
      if (!response) return;
      this.pagination.totalItems = +response.headers.get('x-total-count') || 0;
      this.certificates = response.data;
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  // Aurelia lifecycle
  protected canDeactivate() {
    return this.tabManager.leaveTab();
  }
}
