import { Industry } from './Industry';

export module UserModels {
  export class User {
    public static ApiUrl = 'api/users';
    Id: string;
    Name: string;
    Email: string;
    Password: string;
    Language: string;
    ServiceStationId?: number;
    ProducerId?: number;
    TwoFactorEnabled?: boolean;
    Industry?: Industry;
    MooringStationId?: number;
    MooringStationName: string;
  }

  export class UserSimple {
    public static ApiUrl = 'api/users';
    Id: string;
    Name: string;
  }
}

export type Role =
  | 'Leader'
  | 'ProductionCoordinator'
  | 'AnalysisEngineer'
  | 'Production'
  | 'Administrator'
  | 'Sales'
  | 'ServiceUser'
  | 'MooringCalculation';
