import { isEqual, isEqualWith } from 'lodash';

const comparator = (a: object, b: object) => {
  if (Array.isArray(a) && Array.isArray(b)) {
    return isEqual(a, b);
  }
  return undefined;
};

export class EqualityChecker {
  protected clone: unknown;

  public track(obj: unknown) {
    this.clone = structuredClone(obj);
  }

  public areEqual(obj: unknown) {
    const current = structuredClone(obj);
    return isEqualWith(current, this.clone, comparator);
  }
}
