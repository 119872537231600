import { autoinject, bindable, observable } from 'aurelia-framework';
import { ErrorService } from 'services/error-service';
import { ValidationController, ValidationRules, Validator } from 'aurelia-validation';
import { I18N } from 'aurelia-i18n';
import { AquacomComponentType, MooringArticleCategory, MooringArticleType } from 'models/mooring';
import { MooringArticleCategoryService, MooringArticleTypeService } from 'services';

@autoinject
export class MooringArticleTypeCreateEdit {
  @bindable
  @observable
  model: MooringArticleType = new MooringArticleType();
  modelChanged(model: MooringArticleType) {
    if (!model) return;
    this.form = JSON.parse(JSON.stringify(model));
  }

  @bindable
  onSave: (data: { data: MooringArticleType }) => void;

  @bindable
  onCanceled: () => void;

  @bindable
  @observable
  visible = false;
  visibleChanged(visible: boolean) {
    if (visible) {
      void this.getCategories();
    }
  }

  protected categories: MooringArticleCategory[] = [];
  protected aquacomTypes: (AquacomComponentType & { PathName: string })[] = [];

  protected form: MooringArticleType = {
    Id: undefined,
    Name: undefined,
    NameEn: undefined,
    NameEs: undefined,
    CategoryId: undefined,
  };

  constructor(
    private errorService: ErrorService,
    private validationController: ValidationController,
    private mooringCategoryService: MooringArticleCategoryService,
    private mooringArticleTypeService: MooringArticleTypeService,
    private validator: Validator,
    private t: I18N
  ) {}

  protected applyValidationRules() {
    ValidationRules.ensure('Name')
      .required()
      .withMessage(this.t.tr('general.requiredField'))
      .ensure('NameEn')
      .required()
      .withMessage(this.t.tr('general.requiredField'))
      .ensure('CategoryId')
      .required()
      .withMessage(this.t.tr('general.requiredField'))
      .on(this.form);
  }

  attached() {
    this.applyValidationRules();
    void this.getAquacomCategories();
  }

  protected async getAquacomCategories() {
    try {
      const types = await this.mooringArticleTypeService.getAquacomTypes();
      this.aquacomTypes = types.map((t) => ({
        ...t,
        PathName: `${t.CategoryName} - ${t.Name}`,
      }));
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  async getCategories() {
    try {
      this.categories = await this.mooringCategoryService.getAll();
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  private async validate() {
    this.applyValidationRules();
    await this.validator.validateObject(this.form);
    return (await this.validationController.validate({ object: this.form })).valid;
  }

  async save() {
    try {
      const valid = await this.validate();
      if (!valid) return;
      this.onSave?.({ data: this.form });
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  cancel() {
    this.onCanceled?.();
  }
}
