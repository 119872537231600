import './overview.scss';
import { autoinject, bindable } from 'aurelia-framework';

@autoinject()
export class Overview {
  @bindable
  offerId: number;
  /**
   *
   */
  constructor() {}
}
