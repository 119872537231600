import { SendToServiceStationDialog } from './send-to-service-station-dialog';
import { AuthorizeStep } from './../../authorizeStep';
import { ServiceNotes } from './service-notes';
import { ServiceStationStorageService } from './../../services/service-station-storage-service';
import { ServiceSummary } from './service-summary';
import { HistoryDialog } from './../history-dialog/history-dialog';
import { Prompt } from 'elements/prompt';
import { DialogService } from 'aurelia-dialog';
import { ReportService } from 'services/report-service';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { PLATFORM, bindable, LogManager } from 'aurelia-framework';
import { autoinject } from 'aurelia-framework';
import { Router, RouterConfiguration } from 'aurelia-router';
import { Models } from 'models/core';
import { DeleteDialogService } from 'services/delete-dialog-service';
import { ErrorService } from 'services/error-service';
import { NetService } from 'services/net-service';
import { ServiceService } from 'services/service-service';
import { ServiceStatusService } from 'services/service-status-service';
import { ToastService } from 'services/toast-service';
import { ServiceFormDialog } from '../service-form-dialog/service-form-dialog';
import * as moment from 'moment';
import tippy from 'tippy.js';
import { StorageHistoryService } from 'services/storage-history-service';
import { Logger } from 'aurelia-logging';
import { IndustryService } from 'services/industry-service';

const logger: Logger = LogManager.getLogger('service-detail-main');

@autoinject
export class ServiceDetailMain {
  private service: Models.Service;
  private net: Models.Net;
  private serviceStatuses: Array<Models.ServiceStatus>;
  private generateServiceCardProgress: boolean = false;
  private generateServiceSummaryReportProgress: boolean = false;
  private generateServiceNotesReportProgress: boolean = false;
  private isEditingInternalComment: boolean = false;
  private isSpecialProduct: boolean;
  private isFisheryServiceStation: boolean;
  private isLoaded = false;

  private tabsArray: Array<string>;

  private serviceCardButtonText: string;
  private serviceSummaryReportButtonText: string = 'service.generateServiceSummaryReport';
  private serviceWashAndMeasurementsTabName: string;
  private subscription: Subscription;
  private subscriptionServiceUpdated: Subscription;
  private extendValidity: boolean = false;
  private serviceStationStorages: Array<Models.ServiceStationStorage>;
  private serviceStationStorage: Models.ServiceStationStorage;
  private serviceStationStorageId: number;

  private userIsAdmin: boolean = false;
  private internalComment: string;
  private internalCommentHasValue: boolean = false;

  private isEditingPlannedDeliveryDate: boolean = false;
  private plannedDeliveryDate: any = null;

  @bindable private serviceId: number;
  @bindable private netId: number;
  @bindable private isDialog: boolean = false;

  @bindable private pageService;
  @bindable private pageOriginalObject;

  private activeIndex: number;
  private orderDetailsRef: any;
  private washRef: any;
  private testRef: any;
  private repairRef: any;
  private materialConsumptionRef: any;
  private impregnationRef: any;
  private spaghettiRef: any;
  private preparationRef: any;
  private signingRef: any;
  private extendValidityRef: any;
  private hoursRef: any;

  constructor(
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private netService: NetService,
    private router: Router,
    private toastService: ToastService,
    private serviceService: ServiceService,
    private serviceStatusService: ServiceStatusService,
    private serviceStationStorageService: ServiceStationStorageService,
    private deleteDialogService: DeleteDialogService,
    private reportService: ReportService,
    private dialogService: DialogService,
    private storageHistoryService: StorageHistoryService,
    private industryService: IndustryService
  ) {}

  public canDeactivate() {
    if (!this.isDialog) {
      return true;
    }

    const refArray = [this.orderDetailsRef];

    if (!this.extendValidity && (this.service.OrderedWashing || this.service.OrderedMeasuring)) {
      refArray.push(this.washRef);
    }

    if (!this.extendValidity && this.service.OrderedTesting) {
      refArray.push(this.testRef);
    }

    if (!this.extendValidity && this.service.OrderedRepair) {
      refArray.push(this.repairRef);
    }

    if (!this.extendValidity) {
      refArray.push(this.materialConsumptionRef);
    }

    if (!this.extendValidity && this.service.OrderedAntifouling) {
      refArray.push(this.impregnationRef);
    }

    if (!this.extendValidity && this.service.OrderedSpaghetti) {
      refArray.push(this.spaghettiRef);
    }

    if (!this.extendValidity) {
      refArray.push(this.preparationRef);
      refArray.push(this.signingRef);
    }

    if (this.extendValidity) {
      refArray.push(this.extendValidityRef);
    }

    refArray.push(this.hoursRef);

    if (!refArray[this.activeIndex]) {
      return true;
    }

    return refArray[this.activeIndex].viewModel.canDeactivate();
  }

  private getTabIndex(tabName: string) {
    logger.debug('getTabIndex');
    logger.debug(tabName);

    if (!this.tabsArray) {
      this.setupTabsArray();
    }
    let index = this.tabsArray.indexOf(tabName);

    logger.debug('index');
    logger.debug(index.toString());

    return this.tabsArray.indexOf(tabName);
  }

  private setupTabsArray() {
    const tabsArray = ['orderDetails'];

    if (!this.extendValidity && (this.service.OrderedWashing || this.service.OrderedMeasuring)) {
      tabsArray.push('wash');
    }

    if (this.service.OrderedTesting) {
      tabsArray.push('test');
    }

    if (this.service.OrderedRepair) {
      tabsArray.push('repair');
    }

    if (this.service.OrderedRepair || !this.extendValidity) {
      tabsArray.push('materialConsumption');
    }

    if (!this.extendValidity && this.service.OrderedAntifouling) {
      tabsArray.push('impregnation');
    }

    if (!this.extendValidity && this.service.OrderedSpaghetti) {
      tabsArray.push('spaghetti');
    }

    if (!this.extendValidity) {
      tabsArray.push('preparation');
      tabsArray.push('signing');
    }

    if (this.extendValidity) {
      tabsArray.push('extendValidity');
    }

    tabsArray.push('hours');

    this.tabsArray = tabsArray;
  }

  private configureRouter(config: RouterConfiguration, router) {
    config.map([
      {
        route: [''],
        name: 'service-detail-orderdetails',
        moduleId: PLATFORM.moduleName('./service-detail-orderdetails'),
        tabindex: 0,
      },
      {
        route: ['wash'],
        name: 'service-detail-wash',
        moduleId: PLATFORM.moduleName('./service-detail-wash'),
        tabindex: 1,
      },
      {
        route: ['test'],
        name: 'service-detail-test',
        moduleId: PLATFORM.moduleName('./service-detail-test'),
        tabindex: 2,
      },
      {
        route: ['repair'],
        name: 'service-detail-repair',
        moduleId: PLATFORM.moduleName('./service-detail-repair'),
        tabindex: 3,
      },
      {
        route: ['material-consumption'],
        name: 'service-detail-material-consumption',
        moduleId: PLATFORM.moduleName('./service-detail-material-consumption'),
        tabindex: 4,
      },
      {
        route: ['impregnation'],
        name: 'service-detail-impregnation',
        moduleId: PLATFORM.moduleName('./service-detail-impregnation'),
        tabindex: 5,
      },
      {
        route: ['spaghetti'],
        name: 'service-detail-spaghetti',
        moduleId: PLATFORM.moduleName('./service-detail-spaghetti'),
        tabindex: 6,
      },
      {
        route: ['preparation'],
        name: 'service-detail-preparation',
        moduleId: PLATFORM.moduleName('./service-detail-preparation'),
        tabindex: 7,
      },
      {
        route: ['signing'],
        name: 'service-detail-signing',
        moduleId: PLATFORM.moduleName('./service-detail-signing'),
        tabindex: 8,
      },
      {
        route: ['extend-validity'],
        name: 'service-detail-extend-validity',
        moduleId: PLATFORM.moduleName('./service-detail-extend-validity'),
        tabindex: 9,
      },
      {
        route: ['hours'],
        name: 'service-detail-hours',
        moduleId: PLATFORM.moduleName('./service-detail-hours'),
        tabindex: 10,
      },
    ]);
  }

  private attached() {
    if (this.isDialog) {
      void this.activate({ Id: this.serviceId, NetId: this.netId, IsDialog: this.isDialog });

      this.eventAggregator.subscribe('changeTabIndex', (index) => {
        this.activeIndex = index;
      });

      this.eventAggregator.subscribe('changeTabIndexByName', (name) => {
        this.activeIndex = this.getTabIndex(name);
      });
    }
  }

  private async activate(params) {
    const getSerices = this.getService(params.Id);
    const getNet = this.getNet(params.NetId);
    const getServiceStatuses = this.getServiceStatuses();
    const getServiceStationStorage = this.getServiceStationStorage(+params.NetId);

    await Promise.all([getSerices, getNet, getServiceStatuses, getServiceStationStorage]);
    this.isLoaded = true;

    if (this.isSpecialProduct && this.isFisheryServiceStation) {
      this.tabsArray.splice(this.tabsArray.indexOf('preparation'), 1);
    }

    if (this.service.InternalComment) {
      this.internalCommentHasValue = true;
    }

    this.subscriptionServiceUpdated = this.eventAggregator.subscribe('serviceUpdated', (value) => {
      this.getService(params.Id);
      this.getNet(params.NetId);
    });

    this.subscription = this.eventAggregator.subscribe('serviceLocked', async (value) => {
      await this.getServiceStationStorage(params.NetId);
      await this.getService(params.Id);
    });

    if (!AuthorizeStep.auth.roles) {
      setTimeout(() => {
        if (AuthorizeStep.auth.roles && AuthorizeStep.auth.roles.indexOf('Administrator') !== -1) {
          this.userIsAdmin = true;
        }
      }, 1000);
    } else {
      if (AuthorizeStep.auth.roles && AuthorizeStep.auth.roles.indexOf('Administrator') !== -1) {
        this.userIsAdmin = true;
      }
    }
  }

  private deactivate() {
    if (this.subscription) {
      this.subscription.dispose();
    }
    if (this.subscriptionServiceUpdated) {
      this.subscriptionServiceUpdated.dispose();
    }
  }

  private detached() {
    if (this.subscription) {
      this.subscription.dispose();
    }
    if (this.subscriptionServiceUpdated) {
      this.subscriptionServiceUpdated.dispose();
    }
  }

  private async getNet(id) {
    try {
      const net = await this.netService.getNetInfo(id);
      this.net = net;
      this.setServiceCardButtonText();
      this.serviceWashAndMeasurementsTabName = this.net.IsSpecialProduct ? 'service.washTab' : 'service.washAndMeasurementsTab';
      this.isSpecialProduct = net.IsSpecialProduct;

      setTimeout(() => {
        tippy('.tippy', {
          content: (reference) => {
            const title = reference.getAttribute('title');
            reference.removeAttribute('title');
            return title;
          },
        });
      }, 500);
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  private setServiceCardButtonText() {
    if (this.service && this.net) {
      if (this.net.IsSpecialProduct) {
        this.serviceCardButtonText = this.service.ApprovedService
          ? 'service.generateServiceCardSpecialProduct'
          : 'service.generateServiceCardSpecialProductDraft';
      } else {
        this.serviceCardButtonText =
          this.service.ApprovedService || this.service.ApprovedExtendedPeriod
            ? 'service.generateServiceCard'
            : 'service.generateServiceCardDraft';
      }
    }
  }

  private async getIndutry(id) {
    try {
      await this.industryService.get(id).then((res) => {
        if (res.Name == 'Fishery') {
          this.isFisheryServiceStation = true;
        } else {
          this.isFisheryServiceStation = false;
        }
      });
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  private async getService(id) {
    try {
      logger.debug('get service');
      const service = await this.serviceService.get(id + '?$expand=Contact,ServicePriority,ServiceStatus,ServiceStationStorage,ServiceStation');
      this.service = service;
      await this.getIndutry(service.ServiceStation.IndustryId);

      this.setServiceCardButtonText();

      if (service.ServiceTypeId == 2) {
        this.extendValidity = true;
      }

      let serviceStationIds = [
        service.ServiceStationId,
        service.WashingServiceStationId,
        service.MeasuringServiceStationId,
        service.TestingServiceStationId,
        service.RepairServiceStationId,
        service.AntifoulingServiceStationId,
        service.SpaghettiServiceStationId,
        service.DeliveryServiceStationId,
      ].filter((v, i, a) => a.indexOf(v) === i); //distinct

      if (service.ServiceStationId) {
        this.serviceStationStorageService
          .getAllCached()
          .then((storagesRes) => {
            const storagesFiltered = storagesRes
              .filter((x) => (!x.IsDeleted || x.Id === service.ServiceStationStorageId) && serviceStationIds.includes(x.ServiceStationId))
              .sort((a, b) => (a.ParentId ? 1 : -1));

            const parentStorages = storagesFiltered.filter((it) => !it.ParentId);
            const childStorages = storagesFiltered.filter((it) => it.ParentId);

            const storages = parentStorages.map((storage) => {
              const children = childStorages.filter((it) => it.ParentId === storage.Id).sort((a, b) => (a.Name > b.Name ? 1 : -1));
              return {
                ...storage,
                children,
              };
            });

            this.serviceStationStorages = storages.sort((a, b) => (a.Name > b.Name ? 1 : -1));
          })
          .catch((err) => this.errorService.handleError(err));
      }

      this.setupTabsArray();
      this.eventAggregator.publish('serviceMainHeaderReady');
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  private async getServiceStationStorage(netId: number) {
    try {
      const storage = await this.storageHistoryService.getNetStorage(netId);

      if (!storage) {
        this.serviceStationStorage = undefined;
        this.serviceStationStorageId = undefined;
        return;
      }

      const serviceStation = await this.serviceStationStorageService.get(storage.Id + `?$expand=ServiceStation`); //.get(storage.ServiceStationId + ``);
      storage.ServiceStation = serviceStation.ServiceStation;
      this.serviceStationStorage = storage;
      this.serviceStationStorageId = storage?.Id;
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  private async getServiceStatuses() {
    try {
      const serviceStatus = await this.serviceStatusService.getAllCached();
      this.serviceStatuses = serviceStatus.filter((x) => !x.IsDeleted).sort((a, b) => (a.SortIndex > b.SortIndex ? 1 : -1));
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  private changeServiceStatus(status: Models.ServiceStatus) {
    // status delivered and discarded should be set through a signing process unless testing is not ordred,
    // in that case no tests are performed so normal signing cannot be done
    if ((this.service.OrderedTesting && status.Id === 8) || status.Id === 9) {
      return;
    }

    // update storage in a special patch
    this.serviceService
      .updateServiceStatus(status.Id, this.service.Id)
      .then((res) => {
        this.eventAggregator.publish('dropdownClose', 1);
        this.toastService.showSuccess('service.serviceStatusChanged');

        // pulish update so other entities can update their state - this will also refresh the service in this component
        this.eventAggregator.publish('serviceUpdated', 1);

        this.getNet(this.net.Id);
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private generateServiceCard() {
    if (this.generateServiceCardProgress) {
      return;
    }

    this.generateServiceCardProgress = true;

    if (this.service.ApprovedExtendedPeriod || this.service.ServiceTypeId === 2) {
      this.reportService
        .getServiceExtendedPeriod(this.service.Id)
        .then((res) => {
          this.toastService.showSuccess('document.downloaded');
          this.generateServiceCardProgress = false;
        })
        .catch((err) => {
          this.errorService.handleError(err);
          this.generateServiceCardProgress = false;
        });
    } else {
      this.reportService
        .getServiceCard(this.service.Id)
        .then((res) => {
          this.toastService.showSuccess('document.downloaded');
          this.generateServiceCardProgress = false;
        })
        .catch((err) => {
          this.errorService.handleError(err);
          this.generateServiceCardProgress = false;
        });
    }
  }

  private showSendToServiceStationDialog() {
    this.dialogService
      .open({
        viewModel: SendToServiceStationDialog,
        model: {
          service: this.service,
          net: this.net,
        },
      })
      .whenClosed((res) => {
        if (!res.wasCancelled) {
          this.getNet(this.service.NetId);
        }
      });
  }

  private regenerateServiceCard() {
    this.dialogService
      .open({
        viewModel: Prompt,
        model: {
          header: 'service.regenerateServiceCardHeader',
          message: 'service.regenerateServiceCardText',
          actions: {
            continue: { enabled: true, t: 'service.RegenerateServiceCard' },
            cancel: { enabled: true, t: 'dialog.cancel' },
          },
        },
      })
      .whenClosed((res) => {
        if (!res.wasCancelled) {
          this.serviceService
            .regenerateServiceCard(this.service.Id)
            .then((res) => {
              this.toastService.showSuccess('service.serviceCardRegenerated');

              // download service card when new document is generated
              this.generateServiceCard();
            })
            .catch((err) => this.errorService.handleError(err));
        }
      });
  }

  private generateServiceSummaryReport() {
    if (this.generateServiceSummaryReportProgress) {
      return;
    }

    this.generateServiceSummaryReportProgress = true;

    this.reportService
      .getServiceSummaryReport(this.service.Id)
      .then((res) => {
        this.toastService.showSuccess('document.downloaded');
        this.generateServiceSummaryReportProgress = false;
      })
      .catch((err) => {
        this.errorService.handleError(err);
        this.generateServiceSummaryReportProgress = false;
      });
  }

  private generateServiceSummaryReportHtml() {
    if (this.generateServiceSummaryReportProgress) {
      return;
    }

    this.generateServiceSummaryReportProgress = true;

    this.reportService
      .getServiceSummaryReportHtml(this.service.Id)
      .then((res) => {
        this.dialogService.open({
          viewModel: ServiceSummary,
          model: { reportContent: res, serviceId: this.service.Id },
          lock: false,
          position: (mc, mo) => {},
        });

        this.generateServiceSummaryReportProgress = false;
      })
      .catch((err) => {
        this.errorService.handleError(err);
        this.generateServiceSummaryReportProgress = false;
      });
  }

  private generateServiceNotesReport() {
    if (this.generateServiceNotesReportProgress) {
      return;
    }

    this.generateServiceNotesReportProgress = true;

    this.reportService
      .getServiceNotesReport(this.service.Id)
      .then((res) => {
        this.toastService.showSuccess('document.downloaded');
        this.generateServiceNotesReportProgress = false;
      })
      .catch((err) => {
        this.errorService.handleError(err);
        this.generateServiceNotesReportProgress = false;
      });
  }

  private generateServiceNotesReportHtml() {
    if (this.generateServiceNotesReportProgress) {
      return;
    }

    this.generateServiceNotesReportProgress = true;

    this.reportService
      .getServiceNotesReportHtml(this.service.Id)
      .then((res) => {
        this.dialogService.open({
          viewModel: ServiceNotes,
          model: { reportContent: res, serviceId: this.service.Id },
          lock: false,
          position: (mc, mo) => {},
        });

        this.generateServiceSummaryReportProgress = false;
      })
      .catch((err) => {
        this.errorService.handleError(err);
        this.generateServiceSummaryReportProgress = false;
      });
  }

  private unlockService() {
    return this.dialogService
      .open({
        viewModel: Prompt,
        model: {
          header: 'dialog.serviceUnlockHeader',
          message: 'dialog.serviceUnlockText',
          actions: {
            delete: { enabled: false },
            save: { enabled: false },
            cancel: { enabled: true, t: 'dialog.cancel' },
            dontsave: { enabled: false },
            continue: { enabled: true, t: 'dialog.serviceUnlock' },
          },
        },
      })
      .whenClosed((response) => {
        if (response.wasCancelled) {
          return false;
        } else {
          const result = response.output;
          if (result === 'continue') {
            this.serviceService
              .unlockService(this.service.Id)
              .then((res) => {
                this.getService(this.service.Id);
                this.toastService.showSuccess('service.unlocked');
                this.eventAggregator.publish('service-unlocked', this.service.Id);
              })
              .catch((err) => this.errorService.handleError(err));
          }
        }
      });
  }

  private changeServiceStationStorage(storage: Models.ServiceStationStorage) {
    let storageId = storage ? storage.Id : null;

    this.netService
      .updateStorageView(this.net.Id, storageId)
      .then((res) => {
        this.eventAggregator.publish('dropdownClose', 1);
        this.toastService.showSuccess('service.serviceStationStorageChanged');
        this.service.ServiceStationStorage = storage;
        this.serviceStationStorage = storage;
        this.serviceStationStorageId = storage?.Id;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private editPlannedDeliveryDate() {
    this.plannedDeliveryDate = this.service.PlannedDeliveryDate;
    this.isEditingPlannedDeliveryDate = true;
  }

  private plannedDeliveryDateChange(event) {
    logger.debug('event', event);

    let doUpdate = false;

    if (event.detail && !this.service.PlannedDeliveryDate) {
      // set new value
      doUpdate = true;
    } else if (!event.detail && this.service.PlannedDeliveryDate) {
      // clear value
      doUpdate = true;
    } else if (event.detail && !moment.utc(event.detail).isSame(moment.utc(this.service.PlannedDeliveryDate))) {
      // update
      doUpdate = true;
    }

    logger.debug('doUpdate ' + doUpdate, moment.utc(event.detail), moment.utc(this.service.PlannedDeliveryDate));

    if (doUpdate) {
      this.serviceService
        .patchValue(this.service.Id, 'PlannedDeliveryDate', event.detail)
        .then((res) => {
          this.service.PlannedDeliveryDate = res.PlannedDeliveryDate;
          this.toastService.showSuccess('service.updated');
        })
        .catch((err) => this.errorService.handleError(err));

      this.isEditingPlannedDeliveryDate = false;
    }
  }

  private editInternalComment() {
    this.internalComment = this.service.InternalComment;
    this.isEditingInternalComment = true;
  }

  private cancelEditInternalComment() {
    this.isEditingInternalComment = false;
  }

  private saveInternalComment() {
    this.service.InternalComment = this.internalComment;

    if (this.service.InternalComment === '' || this.service.InternalComment === null) {
      this.internalCommentHasValue = false;
    } else {
      this.internalCommentHasValue = true;
    }

    this.serviceService
      .patchValue(this.service.Id, 'InternalComment', this.service.InternalComment)
      .then((res) => {
        this.toastService.showSuccess('service.internalCommentUpdated');
        this.isEditingInternalComment = false;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private showHistory() {
    let viewModel = {
      EntityType: 'Service',
      EntityId: this.service.Id,
    };

    this.dialogService.open({ viewModel: HistoryDialog, model: viewModel, lock: false, position: (mc, mo) => {} });
  }

  private openNetDetails() {
    let route = !this.net.IsSpecialProduct ? 'net-detail/' + this.net.Id : 'special-product-detail/' + this.net.Id;

    if (!this.isDialog) {
      this.router.navigate(route);

      return;
    }

    const res = this.canDeactivate();
    if (typeof res === 'boolean') {
      if (res === true) {
        this.eventAggregator.publish('closeServiceDetailsDialog');
        this.router.navigate(route);
      }
    } else {
      res.then((result) => {
        if (result === true) {
          this.eventAggregator.publish('closeServiceDetailsDialog');
          this.router.navigate(route);
        }
      });
    }
  }

  private openCustomerDetails() {
    if (!this.isDialog) {
      this.router.navigate('customer/' + this.net.CustomerId);
    }

    const res = this.canDeactivate();
    if (typeof res === 'boolean') {
      if (res === true) {
        this.eventAggregator.publish('closeServiceDetailsDialog');
        this.router.navigate('customer/' + this.net.CustomerId);
      }
    } else {
      res.then((result) => {
        if (result === true) {
          this.eventAggregator.publish('closeServiceDetailsDialog');
          this.router.navigate('customer/' + this.net.CustomerId);
        }
      });
    }
  }

  private editService() {
    if (this.isDialog) {
      const openEditDialog = () => {
        this.dialogService
          .open({
            viewModel: ServiceFormDialog,
            model: {
              Id: this.service.Id,
              NetId: this.net.Id,
            },
            lock: false,
            position: (mc, mo) => {},
          })
          .whenClosed((dialogRes) => {
            if (dialogRes.output && dialogRes.output === 'deleted') {
              this.eventAggregator.publish('closeServiceDetailsDialog');
            } else {
              this.getService(this.serviceId);
            }
          });
      };

      const res: any = this.canDeactivate();
      if (typeof res === 'boolean') {
        if (res === true) {
          openEditDialog();
        }
      } else {
        res.then((result) => {
          if (result === true) {
            openEditDialog();
          }
        });
      }
    } else {
      this.router.navigateToRoute('service-edit', { Id: this.service.Id, NetId: this.service.NetId });
    }
  }
}
