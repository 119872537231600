import { BaseEntity } from './BaseEntity';

export class EntityNoteDto extends BaseEntity {
  Title: string;
  Content: string;
  CreatedBy: string;
  CreatedById: string;
  UpdateBy: string;
  UpdateById: string;
}

export class CreateEntityNoteDto {
  Title: string = '';
  Content: string;
}

export class UpdateEntityNoteDto extends CreateEntityNoteDto {
  Id: number;
}
