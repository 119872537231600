import { autoinject, bindable } from 'aurelia-framework';
import { connectTo } from 'aurelia-store';
import { ConfirmDialog } from 'components/dialogs/confirm/confirm-dialog';
import { PubSub } from 'lib/event/PubSub';
import { ApplicationState } from 'lib/state';
import { AsyncErrorHandler } from 'lib/ui';
import { CreateEntityNoteDto, EntityNoteDto, UpdateEntityNoteDto } from 'models';
import { ToastService } from 'services';
import { MooringOfferService } from 'services/mooring';

@autoinject
@connectTo()
export class Note {
  state: ApplicationState;

  @bindable
  offerId: number;

  @bindable
  note?: EntityNoteDto | CreateEntityNoteDto = null;
  noteChanged(note?: EntityNoteDto | CreateEntityNoteDto) {
    if (!note) {
      this.isEditing = false;
      return;
    }

    if (!this.isExistingNote(note)) {
      this.isEditing = true;
    }

    this.formNote = note;
  }

  @bindable
  onCancel: VoidFunction;

  formNote: UpdateEntityNoteDto | CreateEntityNoteDto;

  isEditing = false;

  isOwner = false;

  constructor(
    private mooringOfferService: MooringOfferService,
    private pubSub: PubSub,
    private toaster: ToastService,
    private confirmation: ConfirmDialog
  ) {}

  protected attached() {
    if (this.isExistingNote(this.note)) {
      this.isOwner = this.note.CreatedById == this.state.user.Id;
    }
  }

  protected isExistingNote(note: unknown): note is EntityNoteDto {
    if ((note as EntityNoteDto)?.Id) return true;
    return false;
  }

  protected setEditNote() {
    this.isEditing = true;
    this.formNote = this.note;
  }

  @AsyncErrorHandler
  protected async saveNote() {
    if (!this.note) return;
    if (this.isExistingNote(this.note)) {
      const dto = new UpdateEntityNoteDto();
      dto.Id = this.note.Id;
      dto.Content = this.formNote.Content;
      await this.updateNote(dto);
    } else {
      const dto = new CreateEntityNoteDto();
      dto.Content = this.formNote.Content;
      await this.createNote(dto);
    }
  }

  @AsyncErrorHandler
  private async createNote(note: CreateEntityNoteDto) {
    await this.mooringOfferService.createNote(this.offerId, note);
    this.pubSub.publish('entity:created', {
      name: 'entity-note',
    });
    this.toaster.showCreated();
    this.isEditing = false;
  }

  @AsyncErrorHandler
  private async updateNote(note: UpdateEntityNoteDto) {
    await this.mooringOfferService.updateNote(this.offerId, note.Id, note);
    this.pubSub.publish('entity:updated', {
      name: 'entity-note',
    });
    this.toaster.showUpdated();
    this.isEditing = false;
  }

  @AsyncErrorHandler
  protected async deleteNote() {
    if (!this.isExistingNote(this.note)) return;

    const confirmed = await this.confirmation.confirmDelete();
    if (!confirmed) return;

    await this.mooringOfferService.deleteNote(this.offerId, this.note.Id);
    this.pubSub.publish('entity:deleted', {
      name: 'entity-note',
    });
    this.toaster.showDeleted();
  }

  protected cancelEdit() {
    this.isEditing = false;
    this.onCancel?.();
  }
}
