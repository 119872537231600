import { PLATFORM } from 'aurelia-framework';
import { RouterConfiguration } from 'aurelia-router';
import { base_routes } from './base';
import { net_offer_routes, net_routes, net_order_routes } from './net';
import { service_routes } from './service';
import { customer_routes } from './customer';
import { admin_routes } from './admin';
import { report_routes } from './reports';
import { mooring_routes } from './mooring';
import { analysis_routes } from './analysis';

export const roles = {
  // Administration
  administrator: 'Administrator',
  // Sales
  sales: 'Sales',
  analysisEngineer: 'AnalysisEngineer',
  // Service
  leader: 'Leader',
  serviceuser: 'ServiceUser',
  production: 'Production',
  productioncoordinator: 'ProductionCoordinator',
  serviceInvoicing: 'ServiceInvoicing',
  // Mooring
  mooringAdministrator: 'MooringAdministrator',
  mooringcalculation: 'MooringCalculation',
  mooringUser: 'MooringUser',
} as const;

export default function (config: RouterConfiguration) {
  config.options.pushState = false;
  config.title = 'general.apptitle';
  config.map([
    ...base_routes,

    ...net_routes,
    ...net_offer_routes,
    ...net_order_routes,

    ...service_routes,
    ...customer_routes,
    ...admin_routes,
    ...report_routes,
    ...mooring_routes,
    ...analysis_routes,

    {
      route: 'management/general-offers',
      name: 'general-offers',
      settings: { roles: [roles.leader], searchable: true },
      viewPorts: {
        main: { moduleId: PLATFORM.moduleName('components/general-offers/general-offers') },
        side: { moduleId: null },
      },
      title: 'generalOffer.title',
    },
    {
      route: 'management/general-offers/new',
      name: 'general-offers-new',
      settings: { roles: [roles.leader], searchable: true },
      viewPorts: {
        main: { moduleId: PLATFORM.moduleName('components/general-offers/general-offer-new') },
        side: { moduleId: null },
      },
      title: 'generalOffer.new.title',
    },
    {
      route: 'management/general-offers/:Id/edit',
      name: 'general-offers-edit',
      settings: { roles: [roles.leader], searchable: true },
      viewPorts: {
        main: { moduleId: PLATFORM.moduleName('components/general-offers/general-offer-edit') },
        side: { moduleId: null },
      },
      title: 'generalOffer.edit.title',
    },
    {
      route: 'management/week-report',
      name: 'week-report-list',
      settings: { roles: [roles.leader], searchable: true },
      viewPorts: {
        main: { moduleId: PLATFORM.moduleName('components/week-report/week-report-list') },
        side: { moduleId: null },
      },
      title: 'weekReport.title',
    },
    {
      route: 'management/week-report/:Id/edit',
      name: 'week-report-edit',
      settings: { roles: [roles.leader], searchable: true },
      viewPorts: {
        main: { moduleId: PLATFORM.moduleName('components/week-report/week-report-edit') },
        side: { moduleId: null },
      },
      title: 'weekReport.editTitle',
    },
    {
      route: ['management'],
      name: 'management',
      settings: { roles: [roles.leader], searchable: true },
      viewPorts: {
        main: { moduleId: PLATFORM.moduleName('components/management/management') },
        side: { moduleId: null },
      },
      nav: true,
      title: 'general.management',
      route_base: 'management',
      roles: [roles.leader],
    },
  ]);

  config.mapUnknownRoutes({ redirect: '' });

  return config;
}
