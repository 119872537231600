import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Models } from 'models/core';
import { DisinfectionTypeService } from 'services/disinfection-type-service';
import { ErrorService } from 'services/error-service';
import { ToastService } from 'services/toast-service';
import { DeleteDialogService } from './../../services/delete-dialog-service';

@autoinject
export class DisinfectionTypeDetail {
  private disinfectiontype: Models.DisinfectionType;
  protected checkDuplicateData: Models.CheckDuplicateDisinfectionType = new Models.CheckDuplicateDisinfectionType();

  private constructor(
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private router: Router,
    private toastService: ToastService,
    private disinfectionTypeService: DisinfectionTypeService,
    private deleteDialogService: DeleteDialogService
  ) {}

  protected activate(params: { Id: string }) {
    this.disinfectionTypeService
      .get(params.Id)
      .then((res) => {
        this.disinfectiontype = res;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  protected async updateDisinfectionType() {
    try {
      if (await this.checkDuplicate()) return;

      await this.disinfectionTypeService.put(this.disinfectiontype, this.disinfectiontype.Id);
      this.eventAggregator.publish('disinfectionTypeListReset', 1);
      this.toastService.showSuccess('disinfectiontype.updated');
      this.router.navigateToRoute('disinfection-type-list');
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected async checkDuplicate() {
    this.checkDuplicateData.Id = this.disinfectiontype.Id;
    this.checkDuplicateData.ProductId = this.disinfectiontype.ProductId;
    const { IsDuplicate } = await this.disinfectionTypeService.checkDuplicate(this.checkDuplicateData);
    this.eventAggregator.publish('disinfectionTypeIsDuplicate', IsDuplicate);
    return IsDuplicate;
  }

  protected deleteDisinfectionType() {
    void this.deleteDialogService.confirmBeforeDelete(() => {
      this.disinfectionTypeService
        .delete(this.disinfectiontype.Id)
        .then(() => {
          this.eventAggregator.publish('disinfectionTypeListReset', 1);
          this.toastService.showSuccess('disinfectiontype.deleted');
          this.router.navigateToRoute('disinfection-type-list');
        })
        .catch((err) => this.errorService.handleError(err));
    });
  }

  protected gotoParentView() {
    this.eventAggregator.publish('disinfectionTypeListReset', 1);
    this.router.navigateToRoute('disinfection-type-list');
  }
}
