import { autoinject, bindable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { ListField } from 'models/ListSettings';
import { CommentForEntity, MooringBridle, MooringComponent } from 'models/mooring';
import { MooringArticleService } from 'services';
import { MooringComponentSearchHandler } from 'services/mooring';
import { toKg, toChecked } from 'lib/ui/ValueDisplayFormatters';
import { MooringTableBase } from '../table-base';
import { TableEntryManager } from 'services/ui-helpers';
import { DialogService } from 'aurelia-dialog';
import { PubSub } from 'lib/event/PubSub';
import { ConfirmDialog } from 'components/dialogs/confirm/confirm-dialog';

@autoinject
export class BridlesTable extends MooringTableBase<MooringBridle> {
  protected tableColumns: ListField[] = tableColumns;

  @bindable
  protected onSave: (args: { entries: MooringBridle[]; canNavigateOnSave: boolean }) => void;

  constructor(
    protected t: I18N,
    dialogService: DialogService,
    mooringArticleService: MooringArticleService,
    // Used in HTML for article search
    mooringComponentSearchHandler: MooringComponentSearchHandler,
    tableEntryManager: TableEntryManager,
    pubsub: PubSub,
    confirmation: ConfirmDialog
  ) {
    super('bridle', dialogService, mooringComponentSearchHandler, tableEntryManager, mooringArticleService, pubsub, confirmation);

    pubsub.sub('list-entity:updated', (data) => {
      if (!data) return;
      if (data.name === 'mooring-article-component') {
        this.onSave({
          entries: this.entries,
          canNavigateOnSave: false,
        });
      }
    });

    pubsub.sub('tabs:change-guard', async () => {
      if (this.changeTracker.areEqual(this.entries)) {
        this.pubsub.publish('tabs:change-guard-ok', null);
        return;
      }

      const res = await this.confirmation.confirmSave();

      if (res == 'cancel') {
        return;
      } else if (res === 'discard-continue') {
        this.pubsub.publish('tabs:change-guard-ok', null);
      } else {
        this.onSave({ entries: this.entries, canNavigateOnSave: true });
      }
    });
  }

  async attached() {
    await super.attached();
  }

  protected sortTable() {
    console.log('sortTable');
  }

  protected save() {
    this.onSave({
      entries: this.entries,
      canNavigateOnSave: false,
    });
  }

  protected addEntryComment(component: MooringBridle) {
    this.openCommentDialog({
      mooringId: this.mooringId,
      isReadOnly: false,
      componentId: component.Id,
      componentType: CommentForEntity.Bridle,
    });
  }

  protected addComponentComment(component: MooringComponent) {
    this.openCommentDialog({
      mooringId: this.mooringId,
      isReadOnly: false,
      componentId: component.Id,
      componentType: CommentForEntity.BridleComponent,
    });
  }

  protected unbind() {
    this.pubsub.unsub();
    super.unbind();
  }
}

const tableColumns: ListField[] = [
  {
    field: 'MoorlogNo',
    title: 'mooring.bridleNo',
    selected: true,
    visible: true,
    filterType: 'TEXT',
    transform: (value: string) => value,
    inputType: 'text',
  },
  {
    field: 'AnalysisNo',
    title: 'mooring.analysisNo',
    selected: true,
    visible: true,
    filterType: 'TEXT',
    transform: (value: string) => value,
    inputType: 'text',
  },
  {
    field: 'DimForce',
    title: 'mooring.dimForce',
    selected: true,
    visible: true,
    filterType: 'TEXT',
    transform: toKg,
    inputType: 'float',
  },
  {
    field: 'IsAccident',
    title: 'mooring.isAccident',
    selected: true,
    visible: true,
    filterType: 'BOOL',
    transform: toChecked,
    inputType: 'bool',
  },
  {
    field: 'MinMblRopesStraps',
    title: 'mooring.minMblRope',
    selected: true,
    visible: true,
    filterType: 'TEXT',
    transform: toKg,
    inputType: 'float',
  },
  {
    field: 'MinMblChainShackleConnectionPlates',
    title: 'mooring.minMblChain',
    selected: true,
    visible: true,
    filterType: 'TEXT',
    transform: toKg,
    inputType: 'float',
  },
  {
    field: 'MinMblAnchoring',
    title: 'mooring.minMblAnchoring',
    selected: true,
    visible: true,
    filterType: 'TEXT',
    transform: toKg,
    inputType: 'float',
  },
  {
    field: 'actions',
    title: '',
    selected: true,
    visible: true,
    filterType: 'none',
  },
];
