import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Models } from 'models/core';
import { DisinfectionTypeService } from 'services/disinfection-type-service';
import { ErrorService } from 'services/error-service';
import { ToastService } from 'services/toast-service';

@autoinject
export class DisinfectionTypeNew {
  private disinfectiontype: Models.DisinfectionType = new Models.DisinfectionType();
  protected checkDuplicateData: Models.CheckDuplicateDisinfectionType = new Models.CheckDuplicateDisinfectionType();

  private constructor(
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private router: Router,
    private toastService: ToastService,
    private disinfectionTypeService: DisinfectionTypeService
  ) {}

  protected async createDisinfectionType() {
    if (await this.checkDuplicate()) return;

    this.disinfectionTypeService
      .post(this.disinfectiontype)
      .then((res) => {
        this.eventAggregator.publish('disinfectionTypeListReset', 1);
        this.toastService.showSuccess('disinfectiontype.created');
        this.router.navigateToRoute('disinfection-type-detail', { Id: res.Id });
      })
      .catch((err) => this.errorService.handleError(err));
  }

  protected async checkDuplicate() {
    this.checkDuplicateData.ProductId = this.disinfectiontype.ProductId;
    const { IsDuplicate } = await this.disinfectionTypeService.checkDuplicate(this.checkDuplicateData);
    this.eventAggregator.publish('disinfectionTypeIsDuplicate', IsDuplicate);
    return IsDuplicate;
  }
}
