import { bindable } from 'aurelia-framework';
import { MooringArticleProduct } from 'models/mooring';
import './certificate-metadata-row.scss';

export type CertificateMetadataHeader = {
  articleNo: string;
  articleName: string;
  categoryName: string;
  typeName: string;
  dimension: string;
  supplierName: string;
};

export class CertificateMetadataRow {
  @bindable
  metadata: MooringArticleProduct;
}
