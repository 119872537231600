import './mooring-create-template-dialog.scss';

import { DialogController } from 'aurelia-dialog';
import { autoinject } from 'aurelia-framework';
import { ValidationController, Validator } from 'aurelia-validation';
import { StandardValidationBuilder } from 'lib/validation';
import { MooringComponentSearchHandler } from 'services/mooring';
import { CreateMooringTemplateDto, MooringArticle, MooringTemplateDto } from 'models/mooring';
import { AsyncErrorHandler } from 'lib/ui';
import { MooringArticleService, ToastService } from 'services';
import { TableEntryManager } from 'services/ui-helpers';
import { MooringComponentTemplateService } from 'services/mooring/mooring-component-temlate-service';

export type ComponentTemplateType = 'anchor-line' | 'bridle' | 'grid-line' | 'buoy';

export type TemplateComponent = {
  // The id if the entry in template article listing
  // e.g. Id of MooringAnchorLineTemplates
  ComponentTemplateId?: number;
  SortIndex: number;
  ArticleId?: number;
  ProductName?: string;
  HasLoadConstraint: boolean;
  //
  MBL?: number;
  CategoryName?: string;
  TypeName?: string;
  ProductTypeName?: string;
};

export type CreateTemplateDialogArgs = {
  action: 'create';
  type: ComponentTemplateType;
  components: TemplateComponent[];
};

export type UpdateTemplateDialogArgs = {
  action: 'update';
  templateId: number;
  type: ComponentTemplateType;
};

type TemplateForm = {
  TemplateId?: number;
  TemplateName: string;
};

@autoinject
export class MooringCreateUpdateTemplateDialog {
  protected model: CreateMooringTemplateDto = undefined;

  protected type: ComponentTemplateType;

  protected components: TemplateComponent[] = [];

  protected articles: MooringArticle[] = [];

  protected templateForm: TemplateForm = {
    TemplateName: '',
  };

  constructor(
    protected validator: Validator,
    private dialogSerivce: DialogController,
    private ruleBuilder: StandardValidationBuilder,
    private toastService: ToastService,
    private validationController: ValidationController,
    private tableEntryManager: TableEntryManager,
    private mooringArticleService: MooringArticleService,
    private mooringComponentTemplateService: MooringComponentTemplateService,
    private toaster: ToastService,
    // Used in view
    protected mooringComponentSearchHandler: MooringComponentSearchHandler
  ) {}

  protected applyValidationRules() {
    this.ruleBuilder.with(this.templateForm).required('TemplateName').done();
  }

  @AsyncErrorHandler
  protected async getTemplate(id: number) {
    let task: Promise<{ data: MooringTemplateDto }>;

    switch (this.type) {
      case 'anchor-line': {
        task = this.mooringComponentTemplateService.getAnchorLineTemplate(id);
        break;
      }
      case 'grid-line': {
        task = this.mooringComponentTemplateService.getGridLineTemplate(id);
        break;
      }
      case 'buoy': {
        task = this.mooringComponentTemplateService.getBuoyTemplate(id);
        break;
      }
      case 'bridle': {
        task = this.mooringComponentTemplateService.getBridleTemplate(id);
        break;
      }
    }

    const { data: template } = await task;
    this.templateForm.TemplateName = template.Name;
    this.components = template.Components.map((x) => ({
      MBL: x.MBL,
      SortIndex: x.SortIndex,
      TypeName: x.TypeName,
      ArticleId: x.ArticleId,
      ProductName: x.ProductName,
      CategoryName: x.CategoryName,
      HasLoadConstraint: x.HasLoadConstraint,
      ProductTypeName: x.ProductTypeName,
      ComponentTemplateId: x.Id,
    })).sort((a, b) => a.SortIndex - b.SortIndex);
  }

  @AsyncErrorHandler
  protected async getArticles() {
    this.articles = await this.mooringArticleService.getAll('$expand=ArticleProducts($expand=Product)');
  }

  protected async init(args: CreateTemplateDialogArgs | UpdateTemplateDialogArgs) {
    this.type = args.type;

    await this.getArticles();

    if (args.action === 'create') {
      this.components = args.components?.map((o) => ({
        ArticleId: o.ArticleId,
        SortIndex: o.SortIndex,
        ProductName: o.ProductName,
        HasLoadConstraint: o.HasLoadConstraint,
        ProductTypeName: o.ProductTypeName,
        TypeName: o.TypeName,
        CategoryName: o.CategoryName,
        MBL: o.MBL,
      }));

      if (!this.components.length) {
        this.components = [
          {
            SortIndex: 0,
            HasLoadConstraint: true,
          },
        ];
      }

      this.type = args.type;
    } else if (args.action === 'update') {
      this.templateForm.TemplateId = args.templateId;
      await this.getTemplate(args.templateId);
    }

    this.applyValidationRules();
  }

  protected activate(args?: CreateTemplateDialogArgs | UpdateTemplateDialogArgs) {
    if (!args) {
      this.toastService.showError('Template args is missing');
    }
    void this.init(args);
  }

  protected addComponent(components: TemplateComponent[], index?: number) {
    const component: TemplateComponent = { ArticleId: undefined, HasLoadConstraint: true, SortIndex: 0 };
    this.components = this.tableEntryManager.addEntry(components, component, index);
  }

  protected removeComponent(index: number) {
    this.components = this.tableEntryManager.removeEntry(this.components, index);
  }

  protected moveComponentUp(index: number) {
    this.components = this.tableEntryManager.moveEntryUp(this.components, index);
  }

  protected moveComponentDown(index: number) {
    this.components = this.tableEntryManager.moveEntryDown(this.components, index);
  }

  @AsyncErrorHandler
  async onSave() {
    const { valid } = await this.validationController.validate();
    if (!valid) return;

    const templateId = this.templateForm.TemplateId;
    if (templateId) {
      const dto = {
        Id: templateId,
        Name: this.templateForm.TemplateName,
        Components: this.components.map((x) => ({
          Id: x.ComponentTemplateId,
          SortIndex: x.SortIndex,
          ArticleId: x.ArticleId,
          HasLoadConstraint: x.HasLoadConstraint,
        })),
      };

      let task: Promise<unknown>;
      switch (this.type) {
        case 'anchor-line': {
          task = this.mooringComponentTemplateService.updateAnchorLineTemplate(templateId, dto);
          break;
        }
        case 'grid-line': {
          task = this.mooringComponentTemplateService.updateGridLineTemplate(templateId, dto);
          break;
        }
        case 'buoy': {
          task = this.mooringComponentTemplateService.updateBuoyTemplate(templateId, dto);
          break;
        }
        case 'bridle': {
          task = this.mooringComponentTemplateService.updateBridleTemplate(templateId, dto);
          break;
        }
      }
      await task;
      this.toaster.showUpdated();
    } else {
      const dto = {
        Name: this.templateForm.TemplateName,
        Components: this.components.map((x) => ({
          SortIndex: x.SortIndex,
          ArticleId: x.ArticleId,
          HasLoadConstraint: x.HasLoadConstraint,
        })),
      };

      let task: Promise<unknown>;
      switch (this.type) {
        case 'anchor-line': {
          task = this.mooringComponentTemplateService.createAnchorLineTemplate(dto);
          break;
        }
        case 'grid-line': {
          task = this.mooringComponentTemplateService.createGridLineTemplate(dto);
          break;
        }
        case 'buoy': {
          task = this.mooringComponentTemplateService.createBuoyTemplate(dto);
          break;
        }
        case 'bridle': {
          task = this.mooringComponentTemplateService.createBridleTemplate(dto);
          break;
        }
      }

      await task;
      this.toaster.showCreated();
    }
    this.onCancel();
  }

  onCancel() {
    void this.dialogSerivce.cancel();
  }
}
