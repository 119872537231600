/* eslint-disable @typescript-eslint/no-explicit-any */
import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, bindable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import * as moment from 'moment';
import { Filters, Filter as Filter, filterTypes } from './Filter';
import { PubSub } from 'lib/event/PubSub';
import { FilterManager } from 'lib/tables/FilterManager';

@autoinject
export class TableFilter {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @bindable
  protected manager: FilterManager;

  protected filters: Filters & { skip?: number };
  private filterTypes = filterTypes;

  @bindable protected fields: any[] | undefined;

  protected filterInputInFocus: Record<keyof Filters, any> = {};
  protected filterDatepickerInFocus: any;

  constructor(
    private eventAggregator: EventAggregator,
    private i18n: I18N,
    private pubsub: PubSub
  ) {
    pubsub.sub('filter-section:reset-filters', () => {
      this.clearAllFilters();
    });

    pubsub.sub('filter-manager:init', (filter) => {
      //
    });
  }

  protected attached() {
    void this.setupFilters();
  }

  protected async notifyFilterChange() {
    const filters = await this.filterGetFiltersQuery();
    const selectedFilters: { [key: string]: string } = {};
    for (const [filterName, filter] of Object.entries(filters)) {
      if (filterName.startsWith('_')) continue;

      if (Array.isArray(filter)) {
        selectedFilters[filterName] = filter.join(',');
      } else if (typeof filter === 'string') {
        selectedFilters[filterName] = filter;
      } else {
        console.error('Unhandled filter type: ' + typeof filter);
      }
    }
    this.manager.selectedFilters = selectedFilters;

    this.pubsub.publish('filter:changed', {
      context: this.manager.context,
    });
  }

  private filterClearSingleByName(filterName: string) {
    const filter = this.filters[filterName];
    if (!filter) return;
    this.filterClearSingle(filter, true);
  }

  private setFilterValue(filterName: string, value: { Id: number }) {
    setTimeout(() => {
      const filter = this.filters[filterName];
      if (!filter) return;
      if (filter.type === 'checkbox') {
        this.filterChange(filter, value, { target: { checked: true } }, true);
      }
      if (filter.type === 'radio') {
        this.filterChange(filter, value, undefined, true);
      }
    });
    return true;
  }

  // Helper-function for filters
  private filterValuesToArray(filter: Filter) {
    if (!filter?.values || !Object.keys(filter.values).length) {
      return null;
    }

    const values = Object.entries(filter?.values);
    const data = values?.map((it) => {
      const item = {
        key: it[0],
        value: it[1],
        object: null,
      };

      if (typeof filter.options === 'string') return; // TODO! Find out if this breaks anything?

      const objectRef = filter.options.find((it) => it?.Id == item.key);
      if (objectRef) {
        item.object = objectRef;
      }

      return item;
    });

    return data;
  }

  private i18nName(row: { Name: string; NameEn: string; NameEs: string }) {
    if (!row) {
      return null;
    }

    const lang = this.i18n.getLocale();
    switch (lang) {
      case 'nb-NO':
        return row['Name'];
      case 'en':
        return row['NameEn'] ? row['NameEn'] : row['Name'];
      case 'es':
        return row['NameEs'] ? row['NameEs'] : row['NameEn'];
      default:
        return row['Name'];
    }
  }

  // Sets the selected-label that shows in the dropdown
  // Converts array to compact string etc.
  private setFilterValueLabel(filter: any) {
    // Checkbox
    if (filter.type === this.filterTypes.CHECKBOX) {
      const values: any = this.filterValuesToArray(filter)
        ?.filter((it) => it.value == true)
        ?.sort((a, b) => {
          if (a?.object?.SortIndex && b?.object?.SortIndex) {
            return a.object.SortIndex > b.object.SortIndex ? 1 : -1;
          } else {
            return 0;
          }
        });

      const fullString = values?.map((it: any) => this.i18nName(it.object)).join(', ');

      if (!values || values.length == 0) {
        filter._valueLabel = this.i18n.tr('servicefilters.noneSelected');
        filter._valueIsNone = true;
      } else if (values.length == 1) {
        filter._valueLabel = fullString.substring(0, 16);
        filter._valueIsNone = false;
      } else if (values.length > 1 && fullString.length > 16) {
        filter._valueLabel = `${fullString.substring(0, 16)} ...`;
        filter._valueIsNone = false;
      } else {
        filter._valueLabel = fullString.substring(0, 16);
        filter._valueIsNone = false;
      }
    }

    // Range
    if (filter.type === this.filterTypes.RANGE) {
      if (filter.values.from && filter.values.to) {
        filter._valueLabel = `${filter.values.from} - ${filter.values.to}`;
        filter._valueIsNone = false;
      } else if (filter.values.from) {
        filter._valueLabel = `${this.i18n.tr('servicefilters.rangeFrom').toLowerCase()} ${filter.values.from}`;
        filter._valueIsNone = false;
      } else if (filter.values.to) {
        filter._valueLabel = `${this.i18n.tr('servicefilters.rangeTo').toLowerCase()} ${filter.values.to}`;
        filter._valueIsNone = false;
      } else {
        filter._valueLabel = this.i18n.tr('servicefilters.noneSelected');
        filter._valueIsNone = true;
      }
    }

    // Range Date
    if (filter.type === this.filterTypes.RANGE_DATE) {
      if (filter.values.from && filter.values.to) {
        filter._valueLabel = `${moment.utc(filter.values.from).format('DD.MM.YYYY')} - ${moment.utc(filter.values.to).format('DD.MM.YYYY')}`;
        filter._valueIsNone = false;
      } else if (filter.values.from) {
        filter._valueLabel = `${this.i18n.tr('servicefilters.rangeFrom').toLowerCase()} ${moment.utc(filter.values.from).format('DD.MM.YYYY')}`;
        filter._valueIsNone = false;
      } else if (filter.values.to) {
        filter._valueLabel = `${this.i18n.tr('servicefilters.rangeTo').toLowerCase()} ${moment.utc(filter.values.to).format('DD.MM.YYYY')}`;
        filter._valueIsNone = false;
      } else {
        filter._valueLabel = this.i18n.tr('servicefilters.noneSelected');
        filter._valueIsNone = true;
      }
    }

    // Radio simple
    if (filter.type === this.filterTypes.RADIO_SIMPLE) {
      const selected = filter.options.find(({ value }) => value === filter.values);
      filter._valueLabel = selected.label;
    }

    // Radio
    if (filter.type === this.filterTypes.RADIO) {
      setTimeout(() => {
        const selected = filter.options.find(({ Id }) => Id === filter.values);
        filter._valueLabel = this.i18nName(selected) ?? this.i18n.tr('servicefilters.noneSelected');
        filter._valueIsNone = this.i18nName(selected) ? false : true;
      });
    }
  }

  // Sets the _selectedOptions on applicable filters
  // This array is just a helper for the frontend-logic and repeaters
  private setFilterSelectedOptions(filter: Filter) {
    if (filter.type === this.filterTypes.CHECKBOX && Array.isArray(filter.options)) {
      const selected = filter.options.filter((option) => {
        return filter.values[option.Id];
      });

      filter._selectedOptions = selected;
    }
  }

  private setFiltersDisabled() {
    for (const [key, filter] of Object.entries(this.filters) as any) {
      if (typeof filter === 'object') {
        if (filter?.disabled !== undefined) {
          if (typeof filter.disabled === 'function') {
            this.filters[key]._disabled = filter.disabled(this.filters);
          } else {
            this.filters[key]._disabled = filter.disabled;
          }

          // TODO: event refresh optios
          // this.eventAggregator.publish('filterOptionsRefresh', key);
        }
      }
    }
  }

  private async filterGetFiltersQuery(fields = null): Promise<object> {
    if (!this.filters) {
      await this.setupFilters();
    }

    return new Promise((resolve) => {
      const query: object = {};
      const valuesToArray = (filter: Filter) => {
        if (filter.type === this.filterTypes.CHECKBOX) {
          return (
            Object.entries(filter?.values)
              .filter(([, value]) => !!value)
              .map(([key]) => key) ?? []
          );
        } else {
          return filter?.values;
        }
      };

      const filters: Array<[string, any]> = Object.entries(this.filters);

      for (const [, filter] of filters) {
        if (!filter || typeof filter !== 'object') {
          continue;
        }

        // Has custom query
        if (filter.query) {
          // Servicefilter uses custom query-key
          if (typeof filter.query === 'string') {
            const values: any = valuesToArray(filter);

            // TODO: Check what this is.... < does same work twice
            if (values?.length > 0) {
              query[filter.query] = values;
            }
          }

          // Servicefilter uses custom query-function
          else if (typeof filter.query === 'function') {
            // Multiple values i returned
            const values = filter.query(filter.values);
            if (Array.isArray(values)) {
              if (values.length > 0) {
                values.forEach(({ key, value }) => {
                  query[key] = value;
                });
              }
            }

            // Single value is returned
            else {
              if (values.value && values.value !== '') {
                query[values.key] = values.value;
              }
            }
          }
        }
        // Use default query-handling
        else {
          // Range
          if (filter.type === this.filterTypes.RANGE) {
            query[`${filter.name}From`] = filter.values.from;
            query[`${filter.name}To`] = filter.values.to;
          }

          // Range Date
          if (filter.type === this.filterTypes.RANGE_DATE) {
            query[`${filter.name}From`] = filter.values.from ? moment.utc(filter.values.from).format('YYYY-MM-DD') : null;
            query[`${filter.name}To`] = filter.values.to ? moment.utc(filter.values.to).format('YYYY-MM-DD') : null;
          }

          // Radio simple
          if (filter.type === this.filterTypes.RADIO_SIMPLE) {
            if (filter.values !== null) {
              query[filter.name] = filter.values;
            }
          }

          // Radio
          if (filter.type === this.filterTypes.RADIO) {
            query[filter.name] = filter.values;
          }
        }
      }

      // Misc
      if (fields) {
        query['_select'] = fields
          ?.filter((x) => x.selected && !x.function)
          .map((x) => x.field)
          .join(',');
      } else {
        query['_select'] = this.fields
          ?.filter((x) => x.selected && !x.function)
          .map((x) => x.field)
          .join(',');
      }

      return resolve(query);
    });
  }

  // Radio-buttons needs a middle-function because of some
  // binding-issues, and because the click-event has to
  // get a true in return
  private filterRadioChangeProxy(filter: Filter, option: any, event: any) {
    setTimeout(() => {
      void this.filterChange(filter, option, event);
    });
    return true;
  }

  // Datepickers need a middle-function to better handle changes
  protected filterDateChangeProxy(filter: Filter, option: any, event: any) {
    if (!event.firedBy) {
      return this.filterChange(filter, option, event);
    }

    const date = event.firedBy._d;
    if (
      date &&
      filter?.values[option] &&
      moment.default(date).format('YYYY-MM-DD') == moment.default(filter?.values[option]).format('YYYY-MM-DD')
    ) {
      return;
    }

    // hack because datepicker is slow
    setTimeout(() => {
      void this.filterChange(filter, option, event);
    }, 500);
  }

  private filterChange(filter: Filter, option: any, event: any, noEmitUpdate?: boolean) {
    // this.pubsub.publish('filter:changed', null);
    // Filter has custom change-function
    if (filter.change) {
      filter.change(option, event, filter.values);
    }

    // Use default change-functions
    else {
      if (filter.type === this.filterTypes.CHECKBOX) {
        filter.values[option.Id] = event.target.checked;
        filter._clearable = Object.values(filter.values).includes(true);
      }

      if (filter.type === this.filterTypes.RANGE) {
        filter._clearable = (filter.values as any).from || (filter.values as any).to;
      }

      if (filter.type === this.filterTypes.RANGE_DATE) {
        filter.values[`${filter.name}${option}`] = event.detail;
        filter._clearable = (filter.values as any).from || (filter.values as any).to;
      }

      if (filter.type === this.filterTypes.RADIO_SIMPLE) {
        this.filters[filter.name].values = option.value;
        filter._clearable = filter.values !== null;
      }

      if (filter.type === this.filterTypes.RADIO) {
        this.filters[filter.name].values = option.Id;
        filter._clearable = filter.values !== null;
      }
    }

    this.setFilterSelectedOptions(filter);
    this.setFilterValueLabel(filter);
    this.setFiltersDisabled();
    if (!noEmitUpdate) {
      this.notifyFilterChange();
    }
    this.filtersSaveLocal();
  }

  protected filterClearSingle(filter: Filter, noEmit?: boolean) {
    this.filterClear(filter, true);
    if (!noEmit) {
      this.notifyFilterChange();
    }
  }

  protected filterClearByName(filterName: string) {
    this.filterClear(this.filters[filterName], true);
    this.notifyFilterChange();
  }

  /**
   * Will clear a filter to a null-state
   * This will _not_ reset a filter to default value
   * @param filter
   * @param useDefaults Will ignore a filter's custom clear-function if set to `true`
   */
  private async filterClear(filter, useDefaults = false, batch = false) {
    if (!filter || typeof filter !== 'object') {
      return;
    }

    // Filter has custom clear-function
    if (filter.clear && !useDefaults) {
      filter.clear(filter.values, this.filterClearByName.bind(this));
    }

    // Use default clear-functions
    else {
      // Checkbox
      if (filter.type === this.filterTypes.CHECKBOX) {
        for (const [key] of Object.entries(filter.values)) {
          filter.values[key] = false;
        }
      }

      // Radio
      if (filter.type === this.filterTypes.RADIO) {
        filter.values = null;
      }

      // Radio simple
      if (filter.type === this.filterTypes.RADIO_SIMPLE) {
        filter.values = null;
      }

      // Range
      if (filter.type === this.filterTypes.RANGE) {
        filter.values = { from: null, to: null };
      }

      // Range Date
      if (filter.type === this.filterTypes.RANGE_DATE) {
        filter.values = { from: null, to: null };
      }
    }

    filter._clearable = false;

    this.setFilterSelectedOptions(filter);
    this.setFilterValueLabel(filter);

    if (!batch) {
      this.setFiltersDisabled();
      this.filtersSaveLocal();
    }

    if (!useDefaults && !batch) {
      this.notifyFilterChange();
    }
  }

  private async clearAllFilters() {
    for (const [key] of Object.entries(this.filters)) {
      await this.filterClear(this.filters[key], false, true);
    }
    this.setFiltersDisabled();
    this.filters.searchText = null;
    this.filters.skip = 0;
    this.filtersSaveLocal();

    this.notifyFilterChange();
  }

  private async filterReset(filter: Filter) {
    if (filter.defaultValues) {
      if (typeof filter.defaultValues === 'function') {
        filter.values = await filter.defaultValues();
      } else {
        filter.values = filter.defaultValues;
      }
    } else {
      void this.filterClear(filter);
    }
  }

  protected setFilterInputFocus(filter: { name: string }, state: boolean) {
    setTimeout(() => {
      this.filterInputInFocus[filter.name] = state;
    });
  }

  private filterSearchKeydown(filter: { _search: string }, event: KeyboardEvent) {
    if (event.key == 'Escape' || event.key == 'Esc' || event.keyCode == 27) {
      filter._search = null;
    }
    return true;
  }

  private filterToggleVisible(filter, event) {
    filter._visible = event.target.checked;
    this.filterClear(filter); // Will also trigger filtersSaveLocal
  }

  private filtersSaveLocal() {
    const data: any = {};

    for (const [key, value] of Object.entries(this.filters)) {
      const filter = this.filters[key];

      if (!filter || typeof filter === 'undefined') {
        continue;
      } else if (typeof filter === 'string' || typeof filter === 'number') {
        data[key] = filter;
      } else {
        data[key] = {
          values: filter.values,
          visible: filter._visible ?? false,
        };
      }
    }

    localStorage.setItem(this.manager.storageKey, JSON.stringify(data));
  }

  public async setupFilters() {
    const filters = this.manager.filters as Record<any, any>;

    // Function for refreshing options-list
    // Used with filter-disabling
    // TODO: Refresh
    // this.eventAggregator.subscribe('filterOptionsRefresh', async (filterKey) => {
    //   this.filters[filterKey].options = await filters[filterKey].optionsFunc();
    //   this.setFilterValueLabel(this.filters[filterKey]);
    // });

    // Functions and stuff to do when loading filters for the first time
    const filtersListLocal = JSON.parse(localStorage.getItem(this.manager.storageKey));
    const diffKeys = Object.keys(filtersListLocal || {}) != Object.keys(filters || {});

    // Default filters visible
    if (!filtersListLocal || (filtersListLocal && diffKeys)) {
      for (const [key, value] of Object.entries(this.manager.defaultFilters)) {
        filters[key]._visible = value;
      }
    }

    for (const [key, filter] of Object.entries(filters)) {
      // Set default values and helpers for filters
      if (!filter.values) {
        if (filters[key].type === this.filterTypes.CHECKBOX) {
          filters[key].values = {};
          filters[key]._selectedOptions = [];
        }

        if (filters[key].type === this.filterTypes.RADIO) {
          filters[key].values = null;
        }

        if (filters[key].type === this.filterTypes.RANGE) {
          filters[key].values = { from: null, to: null };
        }

        if (filters[key].type === this.filterTypes.RANGE_DATE) {
          filters[key].values = { from: null, to: null };
          filters[key]._childInFocus = { from: false, to: false };
        }

        if (filters[key].type === this.filterTypes.RADIO_SIMPLE) {
          filters[key].values = null;

          // Helper for RADIO_SIMPLE-filter, so we don't have to write
          // options for show and hide all the times
          if (typeof filters[key].options === 'string') {
            filters[key].options = [
              { label: this.i18n.tr('general.show'), value: null },
              { label: this.i18n.tr('general.hide'), value: false },
              { label: filters[key].options, value: true },
            ];
          }
        }
      }

      // Set values to default values, if any
      if (filters[key].defaultValues) {
        if (typeof filters[key].defaultValues === 'function') {
          filters[key].values = await filters[key].defaultValues();
        } else {
          filters[key].values = filters[key].defaultValues;
        }
      }

      // Handling localstorage - sets values and visible-state
      if (filtersListLocal) {
        if (filtersListLocal[key]) {
          filters[key]._visible = filtersListLocal[key].visible === undefined ? true : filtersListLocal[key].visible;
          filters[key].values = filtersListLocal[key].values;
        }
      }

      // Show/hide clear-button
      if (filters[key].type === this.filterTypes.CHECKBOX) {
        filters[key]._clearable = Object.values(filters[key].values).includes(true);

        if (filters[key].defaultValues && typeof filters[key] === 'object') {
          const isDefault = Object.entries(filters[key].values).every(
            ([valueKey, valueValue]) => !valueValue || filters[key].defaultValues[valueKey] == valueValue
          );
          if (isDefault) {
            filters[key]._clearable = false;
          }
        }
      }

      if (filters[key].type === this.filterTypes.RANGE) {
        filters[key]._clearable = filters[key].values['from'] || filters[key].values['to'];
      }

      if (filters[key].type === this.filterTypes.RANGE_DATE) {
        filters[key]._clearable = filters[key].values['from'] || filters[key].values['to'];
      }

      if (filters[key].type === this.filterTypes.RADIO_SIMPLE) {
        filters[key]._clearable = filters[key].values !== null;
      }

      if (filters[key].type === this.filterTypes.RADIO) {
        filters[key]._clearable = filters[key].values !== null;
      }

      // Set selected options (works for checkboxes)
      this.setFilterSelectedOptions(filters[key]);

      // Sets value-label
      this.setFilterValueLabel(filters[key]);
    }

    if (filtersListLocal) {
      if (filtersListLocal.orderBy) {
        filters.orderBy = filtersListLocal.orderBy;
      }
      if (filtersListLocal.orderByDirection) {
        filters.orderByDirection = filtersListLocal.orderByDirection;
      }
      if (filtersListLocal.skip) {
        filters.skip = filtersListLocal.skip;
      }
      if (filtersListLocal.top) {
        filters.top = filtersListLocal.top;
      }
      if (filtersListLocal.searchText) {
        filters.searchText = filtersListLocal.searchText;
      }
    }

    this.filters = filters;

    this.notifyFilterChange();
  }

  protected filterMouseEnter(filter: any) {
    this.setFilterInputFocus(filter, true);

    if (filter.type === this.filterTypes.RANGE_DATE && filter._dropdownRef) {
      const left = window.scrollX + filter._dropdownRef.getBoundingClientRect().left;
      const width = 477; // Width of datepicker-filter
      const isOverflowing = left + width > window.innerWidth ? true : false;
      if (isOverflowing) {
        filter._dropdownRef.classList.add('servicefilter-dropdown--datefilter-overflow-fix');
      } else {
        filter._dropdownRef.classList.remove('servicefilter-dropdown--datefilter-overflow-fix');
      }
    }
  }
}
