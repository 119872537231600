import { autoinject, bindable } from 'aurelia-framework';
import { BaseButton } from './base-button';
import { I18N } from 'aurelia-i18n';

const buttonVariantClasses = {
  primary: 'button--primary',
  secondary: 'button--secondary',
  tertiary: 'button--tertiary',
  ok: 'button--ok',
  warning: 'button--warning',
  danger: 'button--danger',
};

type Variants = keyof typeof buttonVariantClasses;

@autoinject()
export class IconButtonTooltip extends BaseButton {
  @bindable()
  variant: Variants;

  @bindable()
  icon: string;

  @bindable()
  tooltip: string | undefined;

  _tooltip: string | undefined;

  constructor(private t: I18N) {
    super();
  }

  attached() {
    if (!this.variant) {
      this.variant = 'primary';
    }

    if (!this.icon) {
      throw new Error(`[${IconButtonTooltip.name}] Icon name must be provided, get names from: https://phosphoricons.com/`);
    }

    if (!buttonVariantClasses[this.variant]) {
      throw new Error(
        `[${IconButtonTooltip.name}] Unknown button variant: ${this.variant}, expected one of: ${Object.keys(buttonVariantClasses).join(', ')}`
      );
    }

    if (this.tooltip) {
      this._tooltip = this.t.tr(this.tooltip);
    }

    this.styleClasses = buttonVariantClasses[this.variant];
  }
}
