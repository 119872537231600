import { NetlogHttpClient } from 'lib/http';
import { FileAttachment } from 'models';
import { AttachmentHandler, FileId } from './attachment-handler';
import { MooringAttachmentType } from 'models/mooring';

export type MooringDocumentsHandlerArgs = {
  mooringId?: number;
  attachmentIds?: FileId[];
  attachmentType: MooringAttachmentType;
};

export class MooringDocumentsHandler implements AttachmentHandler {
  private _mooringId?: number;
  private _attachmentIds?: FileId[];
  private _attachmentType: MooringAttachmentType;

  constructor(
    private client: NetlogHttpClient,
    args: MooringDocumentsHandlerArgs
  ) {
    this._mooringId = args.mooringId;
    this._attachmentType = args.attachmentType;
    this._attachmentIds = args.attachmentIds;
  }

  set mooringId(id: number) {
    this.mooringId = id;
  }

  set attachmentIds(ids: FileId[]) {
    this._attachmentIds = ids;
  }

  get baseUrl() {
    return `api/moorings/${this._mooringId}/attachments`;
  }

  upload(file: File) {
    const form = this.client.fileFormdata(file);
    form.append('type', this._attachmentType.toString());
    return this.client.uploadFile(form, this.baseUrl);
  }

  delete(storageFileId: FileId) {
    return this.client.delete({
      url: `${this.baseUrl}/analysis/${storageFileId}`,
    });
  }

  download(storageFileId: FileId) {
    return this.client.downloadBlob(`api/system-storage/${storageFileId}`);
  }

  async metadata() {
    if (!this._attachmentIds?.length) return [];
    const ids = this._attachmentIds.filter(Boolean);
    const jobs = ids.map((id) =>
      this.client.get<FileAttachment>(
        {
          url: `api/system-storage/${id}/metadata`,
          model: FileAttachment,
        },
        { cache: true }
      )
    );

    const response = await Promise.all(jobs);
    return response.map((r) => r.data);
  }
}
