import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { MooringProductType } from 'models/mooring';
import { ProductMaterialCategoryService } from 'services';
import { ErrorService } from 'services/error-service';
import { ToastService } from 'services/toast-service';

@autoinject()
export class MooringProductMaterialCategoryContainer {
  protected headers: {
    title: string;
  }[] = [];

  constructor(
    private errorService: ErrorService,
    private productMaterialCategoryService: ProductMaterialCategoryService,
    private toast: ToastService,
    private t: I18N
  ) {}

  protected attached() {
    this.createHeaders();
    void this.getCategories();
  }

  protected categories: MooringProductType[] = [];
  protected async getCategories() {
    this.categories = await this.productMaterialCategoryService.getAll();
  }

  protected async onDelete(id: number) {
    try {
      if (id) {
        await this.productMaterialCategoryService.delete(id);
        this.toast.showSuccess(this.t.tr('general.deleted'));
        await this.getCategories();
      }
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected async onSave(type: MooringProductType) {
    try {
      if (type.Id) {
        await this.productMaterialCategoryService.put(type, type.Id);
      } else {
        await this.productMaterialCategoryService.post(type);
      }
      this.toast.showSuccess(this.t.tr('general.saved'));
      await this.getCategories();
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected isCreating = false;
  protected editingType: number | undefined = undefined;
  protected setEditingType(id: number) {
    this.isCreating = false;
    this.editingType = id;
  }

  protected setIsCreatingType(isCreating: boolean) {
    this.editingType = undefined;
    this.isCreating = isCreating;
  }

  private createHeaders() {
    this.headers = [
      { title: this.t.tr('general.name') },
      { title: this.t.tr('general.name-en') },
      { title: this.t.tr('general.name-es') },
      { title: this.t.tr('general.actions') },
    ];
  }
}
