import { autoinject, LogManager } from 'aurelia-framework';
import { Logger } from 'aurelia-logging';
import { NetlogHttpClient } from 'lib/http';
import { Role, UserModels } from 'models/UserModels';
import { AuthHttpClient } from 'services/auth-http-client';
import { BaseApiService } from 'services/base-api-service';

const logger: Logger = LogManager.getLogger('user-service');

@autoinject
export class UserService extends BaseApiService<UserModels.User> {
  private currentUser: UserModels.User = null;

  constructor(
    httpClient: AuthHttpClient,
    private netlogClient: NetlogHttpClient
  ) {
    super(httpClient, UserModels.User);
  }

  public getUserRoles(userId: string): Promise<Array<any>> {
    return this.getAll(`/${userId}/roles`);
  }

  public getRolesForUser(userId: string) {
    const url = this.apiUrl + `/${userId}/roles`;

    return this.httpClient.exec<Role[]>(url, {
      method: 'GET',
    });
  }

  public getCurrentUser(): Promise<UserModels.User> {
    if (this.currentUser) {
      return Promise.resolve(this.currentUser);
    } else {
      return this.get('currentuser').then((user) => {
        this.currentUser = user;
        return this.currentUser;
      });
    }
  }

  public clearCurrentUser() {
    this.currentUser = null;
  }

  public clearUserSettings() {
    const keys = Object.keys(localStorage);

    const keysToKeep = ['netlog_language', 'authToken'];

    keys.forEach((key) => {
      if (keysToKeep.indexOf(key) === -1) {
        logger.debug(`Remove ${key} from localStorage`);
        localStorage.removeItem(key);
      }
    });
  }

  public setLanguageCurrentUser(language: string): Promise<any> {
    return this.post(null, '/set-language/' + language);
  }

  public put(entity: UserModels.User, id?: number | string, url: string = null): Promise<UserModels.User> {
    if (url) {
      url = url.replace(/[?&]$/, '');
    }

    const options: any = {
      body: JSON.stringify(entity),
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    } as RequestInit;

    return this.httpClient.fetch(this.apiUrl + (url ? url : id ? '/' + id : ''), options).then((response: Response) => {
      const status = response.status;
      if (status <= 400) {
        return response.text().then((responseText) => {
          if (responseText) {
            return JSON.parse(responseText) as UserModels.User;
          } else {
            return null;
          }
        });
      }

      return response.text().then((responseText) => {
        throw this.createError(status, responseText);
      });
    });
  }

  public async getMooringSellers() {
    return await this.netlogClient.get<UserModels.UserSimple[]>({
      url: '/api/users/mooring/sellers',
      model: UserModels.UserSimple,
    });
  }
}
