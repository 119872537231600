import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { FluentRuleCustomizer, ValidationRules } from 'aurelia-validation';

@autoinject
export class StandardValidationBuilder {
  constructor(private I18N: I18N) {}

  /**
   * Start building a validation for a model.
   *
   *
   * @param model The model to validate.
   * @param id An optional id to use for the validation.
   */
  with<T>(model: T, id?: string) {
    if (id) {
      model['__validationId'] = id;
    }
    return new ValidationBuilder(this.I18N, model);
  }
}

type WhenPredicate<T> = (model: T) => boolean;

class ValidationBuilder<T> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _validations: FluentRuleCustomizer<any, any>;

  constructor(
    private I18N: I18N,
    private model: T
  ) {
    this._validations = ValidationRules.ensure('').satisfies(() => true);
  }

  /**
   * The id is optional, but is used by the validation-controller-errors to
   * distinguish between different models with the same property name: e.g. items in an array.
   */
  setId(f: keyof typeof this.model, id: string) {
    if (!this.model) return this;
    this.model[f as string]['__validationId'] = id;
    return this;
  }

  get rules() {
    return this._validations.rules;
  }

  required(f: keyof typeof this.model, when?: WhenPredicate<T>) {
    let validation = this._validations.ensure(f as string).required();

    if (when) {
      validation = validation.when(when);
    }

    validation = validation.withMessage(this.I18N.tr('general.requiredField'));
    this._validations = validation;
    return this;
  }

  min(f: keyof typeof this.model, min: number, when?: WhenPredicate<T>) {
    let validation = this._validations.ensure(f as string).min(min);

    if (when) {
      validation = validation.when(when);
    }

    validation = validation.withMessage(this.I18N.tr('validation.mustBeGreaterOrEqualToValue', { value: min }));
    this._validations = validation;
    return this;
  }

  max(f: keyof typeof this.model, max: number, when?: WhenPredicate<T>) {
    let validation = this._validations.ensure(f as string).max(max);

    if (when) {
      validation = validation.when(when);
    }

    validation = validation.withMessage(this.I18N.tr('validation.mustBeLessOrEqualToValue', { value: max }));
    this._validations = validation;
    return this;
  }

  maxLength(f: keyof typeof this.model, maxLength: number, when?: WhenPredicate<T>) {
    let validation = this._validations.ensure(f as string).maxLength(maxLength);

    if (when) {
      validation = validation.when(when);
    }

    validation = validation.withMessage(this.I18N.tr('validation.maxLengthOfX', { value: maxLength }));
    this._validations = validation;
    return this;
  }

  minLength(f: keyof typeof this.model, minLength: number, when?: WhenPredicate<T>) {
    let validation = this._validations.ensure(f as string).maxLength(minLength);

    if (when) {
      validation = validation.when(when);
    }

    validation = validation.withMessage(this.I18N.tr('validation.minLengthOfX', { value: minLength }));
    this._validations = validation;
    return this;
  }

  custom(f: keyof typeof this.model, message: string, predicate: (value: T, object: T) => boolean, when?: WhenPredicate<T>) {
    let validation = this._validations.ensure(f as string).satisfies(predicate);

    if (when) {
      validation = validation.when(when);
    }

    validation = validation.withMessage(this.I18N.tr(message));
    this._validations = validation;
    return this;
  }

  done() {
    return this._validations.on(this.model);
  }

  on(model: T) {
    return this._validations.on(model);
  }
}
