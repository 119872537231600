import { autoinject, noView, observable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { PubSub } from 'lib/event/PubSub';
import { ListSettingsService } from 'services/list-settings-service';

interface PaginationInit {
  /**
   * Current page we are on
   */
  currentPage?: number;
  /**
   * How many items to paginate?
   */
  totalItems?: number;
  /**
   * Callback when the page number changes
   */
  onPageChanged?: (page: number, pagination: Paged) => void;
}

export type Paged = {
  top: number;
  skip: number;
};

@autoinject
@noView
export class PaginationHandler {
  /**
   * Current page size.
   * This is retrieved by config, else default is 25
   */
  pageSize = 25;

  noEmit = false;

  @observable
  /**
   * The current page we are on, can be modified by outside.
   * On updated, we set currentPage query param on the current route
   */
  currentPage = 1;
  currentPageChanged(newPage: number, oldPage: number) {
    if (!this.router?.currentInstruction) return;
    // Prevents routing on inital page load.
    if (newPage === 1 && oldPage === undefined) return;
    const params = this.router.currentInstruction.queryParams;
    params.currentPage = newPage.toString();
    const search = `?${new URLSearchParams(params).toString()}`;
    this.router.navigate(this.router.currentInstruction.fragment + search);

    this.pubsub.publish('pagination:page-change', { ...this.pagination });
    setTimeout(() => {
      this.onPageChanged?.(newPage, this.pagination);
    }, 100);
  }

  /**
   * Total items to paginate
   */
  totalItems = 0;

  /**
   * Callback handler provided on init for when
   * a page changes
   */
  private onPageChanged?: (page: number, pagination: Paged) => void;

  constructor(
    private listSettingService: ListSettingsService,
    private router: Router,
    private pubsub: PubSub
  ) {
    void this.setup();
  }

  public init(initData: PaginationInit) {
    const { currentPage, totalItems, onPageChanged } = initData;
    if (currentPage) this.currentPage = currentPage;
    if (totalItems) this.totalItems = totalItems;
    this.onPageChanged = onPageChanged;
  }

  private async setup() {
    this.pageSize = await this.listSettingService.getDefaultPageSize();
    this.pubsub.unsub();
    this.pubsub.sub('filter:changed', () => {
      this.currentPage = 1;
    });
  }

  /**
   * The offset for the current page.
   * (current page - 1) * pageSize
   */
  get offset() {
    return (this.currentPage - 1) * this.pageSize;
  }

  /**
   * Gets the paginaion properties to be used in queries to API.
   * top and skip values for pagination.
   */
  get pagination() {
    return { top: this.pageSize, skip: this.offset };
  }

  /**
   * Aurelia lifecycle hook
   */
  protected detached() {
    this.pubsub.unsub();
  }
}
