import { autoinject } from 'aurelia-dependency-injection';
import { DialogService } from 'aurelia-dialog';
import { Confirm } from 'elements/confirm';
import { Actions } from '../../../elements/confirm';
import { I18N } from 'aurelia-i18n';

@autoinject()
export class ConfirmDialog {
  constructor(
    private dialog: DialogService,
    private t: I18N
  ) {}

  public async confirm(header: string, message: string, onConfirm: (confirmed: boolean) => void, actions: Actions) {
    const enabledActions = {
      ok: { enabled: false, t: 'dialog.ok' },
      yes: { enabled: false, t: 'dialog.yes' },
      save: { enabled: false, t: 'dialog.save' },
      cancel: { enabled: false, t: 'dialog.cancel' },
      continueSave: { enabled: false, t: 'dialog.continueSave' },
      continueDiscard: { enabled: false, t: 'dialog.continueDiscard' },
    };

    for (const [key, value] of Object.entries(actions)) {
      enabledActions[key]['enabled'] = value.enabled;
      if (value.t) enabledActions[key]['t'] = value.t;
    }
    const dialog = this.dialog.open({
      viewModel: Confirm,
      model: {
        header,
        message,
        actions: enabledActions,
      },
    });

    const result = await dialog.whenClosed();
    const ok = ['ok', 'yes', 'save', 'save-continue'].find((r) => r == result.output);
    if (onConfirm) {
      onConfirm(!!ok);
    }

    return !!ok;
  }

  public confirmDelete(header?: string, message?: string, onConfirm?: (confirmed: boolean) => void) {
    if (!header) header = this.t.tr('dialog.areYouSure');
    if (!message) message = this.t.tr('dialog.confirmDeleteEntry');

    return this.confirm(header, message, onConfirm, {
      ok: { enabled: true, t: 'dialog.delete' },
      cancel: { enabled: true, t: 'dialog.cancel' },
    });
  }

  public confirmYesNo(header: string, message: string, onConfirm?: (confirmed: boolean) => void) {
    return this.confirm(header, message, onConfirm, {
      yes: { enabled: true },
      cancel: { enabled: true },
    });
  }

  public async confirmSave(header?: string, message?: string) {
    const dialog = this.dialog.open({
      viewModel: Confirm,
      model: {
        header: header || 'general.doYouWantToSaveChanges',
        message: message || 'general.pendingUnsavedChanges',
        actions: {
          cancel: { enabled: true, t: 'dialog.cancel' },
          continueSave: { enabled: true, t: 'dialog.continueSave' },
          continueDiscard: { enabled: true, t: 'dialog.continueDiscard' },
        },
      },
    });

    const result = await dialog.whenClosed();
    return result.output as 'save-continue' | 'discard-continue' | 'cancel';
  }
}
