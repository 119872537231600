import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { CertificateProducer, MooringSupplier } from 'models/mooring';
import { ErrorService } from 'services/error-service';
import { SupplierService } from 'services/supplier-service';
import { ToastService } from 'services/toast-service';

@autoinject()
export class MooringSupplierContainer {
  protected headers: {
    title: string;
  }[] = [];

  constructor(
    private errorService: ErrorService,
    private supplierService: SupplierService,
    private toast: ToastService,
    private t: I18N
  ) {}

  protected attached() {
    this.createHeaders();
    void this.getSuppliers();
  }

  protected suppliers: MooringSupplier[] = [];
  protected async getSuppliers() {
    this.suppliers = await this.supplierService.getAll();
  }

  protected async onDelete(id: number) {
    try {
      if (id) {
        await this.supplierService.delete(id);
        this.toast.showSuccess(this.t.tr('general.deleted'));
        await this.getSuppliers();
      }
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected async onSave(supplier: MooringSupplier) {
    try {
      if (supplier.Id) {
        await this.supplierService.put(supplier, supplier.Id);
      } else {
        await this.supplierService.post(supplier);
      }
      this.toast.showSuccess(this.t.tr('general.saved'));
      await this.getSuppliers();
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected isCreating = false;
  protected editingSupplier: number | undefined = undefined;
  protected setEditingSupplier(id: number) {
    this.isCreating = false;
    this.editingSupplier = id;
  }

  protected setIsCreatingSupplier(isCreating: boolean) {
    this.editingSupplier = undefined;
    this.isCreating = isCreating;
  }

  protected getSupplierName(certificateProducer: CertificateProducer) {
    if (certificateProducer === CertificateProducer.MORENOT) return 'Mørenot';
    if (certificateProducer === CertificateProducer.SUPPLIER) return this.t.tr('general.supplier');
  }

  private createHeaders() {
    this.headers = [
      { title: this.t.tr('general.name') },
      { title: this.t.tr('general.certificateProducedBy') },
      { title: this.t.tr('general.actions') },
    ];
  }
}
