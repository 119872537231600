import { BaseEntityWithMultilanguageName } from '../BaseEntity';

export enum SalesOrderTypeEnum {
  Standard = 0,
  FrameFlexilink = 1,
  FrameSolidlink = 2,
}

export class MooringSalesOrderType extends BaseEntityWithMultilanguageName {
  static ApiUrl = 'api/mooring-sales-order-types';

  Type: SalesOrderTypeEnum;
}
