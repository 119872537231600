import { activationStrategy } from 'aurelia-router';
import { PLATFORM } from 'aurelia-framework';
import { roles } from 'routes';

export const service_routes = [
  {
    route: ['services'],
    name: 'service-list',
    settings: { roles: [roles.serviceuser], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/service/service-list') },
      side: { moduleId: null },
    },
    nav: true,
    title: 'general.serviceoverview',
    route_base: 'service',
    section: 'service',
  },
  {
    route: ['queue'],
    name: 'queue',
    settings: { roles: [roles.serviceuser], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/service/service-queue') },
      side: { moduleId: null },
    },
    nav: true,
    title: 'general.servicequeue',
    route_base: 'queue',
    section: 'service',
  },
  {
    route: 'delivery-report',
    name: 'service-delivery-report',
    settings: { roles: [roles.serviceuser], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/reports/service-delivery-report') },
      side: { moduleId: null },
    },
    title: 'reports.servicedeliveryreportheader',
    nav: true,
    route_base: 'delivery-report',
    section: 'service',
  },
  {
    route: 'invoicing-report',
    name: 'service-invoicing-report',
    settings: { roles: [roles.serviceuser], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/reports/service-invoicing-report') },
      side: { moduleId: null },
    },
    title: 'reports.serviceinvoicingreportheader',
    nav: true,
    route_base: 'invoicing-report',
    section: 'service',
  },
  {
    route: 'invoicing-list-service',
    name: 'invoicing-list-service',
    settings: { roles: [roles.serviceInvoicing], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/service/service-invoicing-list') },
      side: { moduleId: null },
    },
    title: 'general.orderBasis',
    nav: true,
    route_base: 'invoicing-list-service',
    section: 'service',
  },
  {
    route: ['service/:Id/net/:NetId'],
    name: 'service-detail',
    settings: { roles: [roles.sales, roles.serviceuser, roles.productioncoordinator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/service/service-detail-main') },
      side: { moduleId: null },
    },
    activationStrategy: activationStrategy.invokeLifecycle,
  },
  {
    route: ['service/:Id/net/:NetId/counter'],
    name: 'service-counter',
    settings: { roles: [roles.sales, roles.serviceuser, roles.productioncoordinator] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/service/service-counter/service-counter') },
      side: { moduleId: null },
    },
    activationStrategy: activationStrategy.invokeLifecycle,
  },
  {
    route: ['service/new'],
    name: 'service-new',
    settings: { roles: [roles.serviceuser], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/service/service-new') },
      side: { moduleId: null },
    },
    title: 'service.new',
  },
  {
    route: ['service/:Id/net/:NetId/edit'],
    name: 'service-edit',
    settings: { roles: [roles.serviceuser] },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/service/service-edit') },
      side: { moduleId: null },
    },
  },
  {
    route: 'storages',
    name: 'storage-list',
    settings: { roles: [roles.serviceuser], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/storage/storage-list') },
      side: { moduleId: null },
    },
    title: 'storages.storageListHeader',
    nav: true,
    route_base: 'storages',
    section: 'storage',
  },
  {
    route: 'storage-view',
    name: 'storage-view',
    settings: { roles: [roles.serviceuser], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/storage/storage-view') },
      side: { moduleId: null },
    },
    title: 'storages.storageViewHeader',
    nav: true,
    route_base: 'storage-view',
    section: 'storage',
  },
  {
    route: 'storage-invoices',
    name: 'storage-invoice-list',
    settings: { roles: [roles.serviceuser], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/storage/storage-invoice-list') },
      side: { moduleId: null },
    },
    title: 'storages.storageInvoiceListHeader',
    nav: true,
    route_base: 'storage-invoices',
    section: 'storage',
  },
];
