import './review.scss';
import { MooringOfferService, MooringService } from 'services/mooring';
import { autoinject, bindable } from 'aurelia-framework';
import { AsyncErrorHandler } from 'lib/ui';
import { MooringReviewDto, CommentForEntity } from 'models/mooring';
import { PubSub } from 'lib/event/PubSub';
import { ConfirmDialog } from 'components/dialogs/confirm/confirm-dialog';
import { ToastService } from 'services';

type ComponentComment = {
  Entity: CommentForEntity;
  EntityId: number;

  Comment: string;
  Resolved: boolean;
};

@autoinject()
export class Review {
  @bindable()
  protected mooringId: number;

  @bindable()
  protected reviewId?: number;

  reviewIdChanged(n?: number) {
    void this.getReiew(n);
  }

  protected review?: MooringReviewDto;

  protected anchorLines: ComponentComment[] = [];
  protected anchorLinesResolved: number = 0;

  protected gridLines: ComponentComment[] = [];
  protected gridLinesResolved: number = 0;

  protected bridles: ComponentComment[] = [];
  protected bridlesResolved: number = 0;

  protected buoys: ComponentComment[] = [];
  protected buoysResolved: number = 0;

  protected allCommentsAreResolved = false;

  constructor(
    private offerService: MooringOfferService,
    private mooringService: MooringService,
    private pubsub: PubSub,
    private confirm: ConfirmDialog,
    private toaster: ToastService
  ) {}

  private notifyUpdate() {
    this.pubsub.publish('entity:updated', null);
    this.getReiew(this.reviewId);
  }

  @AsyncErrorHandler
  protected async updateReview() {
    if (!this.reviewId) return;
    await this.offerService.updateReview(this.reviewId, { Comment: this.review?.Comment?.trim() });
    this.notifyUpdate();
    this.toaster.showUpdated();
  }

  @AsyncErrorHandler
  protected async approveReview() {
    if (!this.reviewId) return;
    const confirmed = await this.confirm.confirmYesNo('Godkjenn kontroll', 'Er du sikker på at du vil godkjenne kontrollen?');
    if (!confirmed) return;
    await this.offerService.completeReview(this.reviewId, { Comment: this.review?.Comment?.trim(), Approve: true });
    this.notifyUpdate();
    this.toaster.showUpdated();
  }

  @AsyncErrorHandler
  protected async rejectReview() {
    if (!this.reviewId) return;
    const confirmed = await this.confirm.confirmYesNo('Send til revisjon', 'Er du sikker på at du vil sende tilbudet tilbake til revisjon?');
    if (!confirmed) return;
    await this.offerService.completeReview(this.reviewId, { Comment: this.review?.Comment?.trim(), Approve: false });
    this.notifyUpdate();
    this.toaster.showUpdated();
  }

  @AsyncErrorHandler
  protected async abortReview() {
    if (!this.reviewId) return;
    const confirmed = await this.confirm.confirmYesNo(
      'Avbryt kontroll',
      'Er du sikker på at du vil avbryte kontrollen og gå tilbake til under arbeid?'
    );
    if (!confirmed) return;

    await this.offerService.abortReview(this.reviewId, { Comment: this.review?.Comment?.trim() });
    this.notifyUpdate();
    this.toaster.showUpdated();
  }

  @AsyncErrorHandler
  async getReiew(id?: number) {
    if (!id) return;
    const { data: review } = await this.offerService.getReview(id);
    const { data: comments } = await this.mooringService.getMooringComponentComments({ mooringId: this.mooringId });
    this.review = review;

    this.anchorLinesResolved = 0;
    this.buoysResolved = 0;
    this.bridlesResolved = 0;
    this.gridLinesResolved = 0;
    const anchorLines: ComponentComment[] = [];
    const gridLines: ComponentComment[] = [];
    const bridles: ComponentComment[] = [];
    const buoys: ComponentComment[] = [];

    for (const comment of comments) {
      const c = {
        Entity: comment.Entity,
        Comment: comment.Comment,
        EntityId: comment.EntityId,
        Resolved: Boolean(comment.ResolvedById),
      } satisfies ComponentComment;

      if (comment.Entity === CommentForEntity.AnchorLine || comment.Entity === CommentForEntity.AnchorLineComponent) {
        anchorLines.push(c);
        if (c.Resolved) this.anchorLinesResolved++;
      } else if (comment.Entity === CommentForEntity.GridLine || comment.Entity === CommentForEntity.GridLineComponent) {
        gridLines.push(c);
        if (c.Resolved) this.gridLinesResolved++;
      } else if (comment.Entity === CommentForEntity.Bridle || comment.Entity === CommentForEntity.BridleComponent) {
        bridles.push(c);
        if (c.Resolved) this.bridlesResolved++;
      } else if (comment.Entity === CommentForEntity.Buoy || comment.Entity === CommentForEntity.BuoyComponent) {
        buoys.push(c);
        if (c.Resolved) this.buoysResolved++;
      }
    }

    this.anchorLines = anchorLines;
    this.gridLines = gridLines;
    this.buoys = buoys;
    this.bridles = bridles;

    const totalComments = anchorLines.length + gridLines.length + buoys.length + bridles.length;
    const allResolvedComments = this.anchorLinesResolved + this.gridLinesResolved + this.buoysResolved + this.bridlesResolved;

    this.allCommentsAreResolved = totalComments - allResolvedComments == 0;
    console.log(this.allCommentsAreResolved);
  }

  protected async getReiews() {}
}
