import { autoinject } from 'aurelia-framework';
import { CheckDuplicatePackagingProduct, PackagingProduct } from 'models/PackagingProduct';
import { AuthHttpClient } from 'services/auth-http-client';
import { BaseApiService } from 'services/base-api-service';

@autoinject
export class PackagingProductService extends BaseApiService<PackagingProduct> {
  constructor(httpClient: AuthHttpClient) {
    super(httpClient, PackagingProduct);
  }

  public async checkDuplicate(data: CheckDuplicatePackagingProduct) {
    return await this.httpClient.exec<{ IsDuplicate: boolean }>(`${PackagingProduct.ApiUrl}/checkDuplicate`, {
      method: 'POST',
      body: JSON.stringify(data),
    });
  }
}
