import { autoinject, bindable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { TableHeader } from 'components/table/table-content';
import { FilterManager } from 'lib/tables/FilterManager';
import { MooringOfferListItemDto } from 'models/mooring/MooringOffers';

@autoinject
export class OffersTable {
  @bindable filterManager: FilterManager;
  @bindable offers: MooringOfferListItemDto[] = [];

  protected tableHeaders: TableHeader<MooringOfferListItemDto & { actions: '' }>[] = [
    { key: 'Id', label: 'general.id', sortable: true },
    { key: 'Created', label: 'general.created', sortable: true },
    { key: 'Customer', label: 'general.customer', sortable: true },
    { key: 'Contact', label: 'general.contact', sortable: true },
    { key: 'Locality', label: 'mooring.site', sortable: true },
    { key: 'LocalityNumber', label: 'mooring.siteNumber', sortable: true },
    { key: 'Title', label: 'general.title', sortable: true },
    { key: 'CrmReference', label: 'mooring.crmReference', sortable: true },
    { key: 'Status', label: 'general.status', sortable: true },
    { key: 'actions', label: '', sortable: false },
  ];

  constructor(private router: Router) {}

  protected setOrderByColumn(column: string) {
    this.filterManager.setOrderByColumn(column);
  }

  protected openOffer(offerId: number) {
    this.router.navigate(`mooring/offers/${offerId}`);
  }
}
