import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { ValidationController, Validator } from 'aurelia-validation';
import { AsyncErrorHandler } from 'lib/ui';
import { StandardValidationBuilder } from 'lib/validation';
import { MooringStation } from 'models/mooring';
import { MooringStationService } from 'services';
import { ToastService } from 'services/toast-service';

@autoinject()
export class MooringsStationsListView {
  protected ready = false;

  protected mooringStation: MooringStation = new MooringStation();
  protected id: number | undefined;

  constructor(
    private mooringStationsService: MooringStationService,
    private router: Router,
    private toast: ToastService,
    private validationController: ValidationController,
    private validationBuilder: StandardValidationBuilder,
    protected validator: Validator
  ) {}

  protected activate({ Id }: { Id: number | undefined }) {
    this.id = Id;
    void this.getMooringStation();
  }

  deactivate() {}

  protected applyValidationRules() {
    this.validationBuilder
      .with(this.mooringStation)
      .required('CertificationNumber')
      .required('QaManager')
      .required('LocationCode')
      .required('SellerCode')
      .maxLength('DepartmentCode', 10)
      .required('DepartmentCode')
      .done();
  }

  @AsyncErrorHandler
  protected async save() {
    if (!this.mooringStation) return;

    const { valid } = await this.validationController.validate();
    if (!valid) return;

    if (this.mooringStation.Id) {
      await this.mooringStationsService.update(this.mooringStation, this.mooringStation.Id);
      this.toast.showUpdated();
    } else {
      const station = await this.mooringStationsService.create(this.mooringStation);
      this.toast.showCreated();
      this.router.navigateToRoute('edit-mooring-station', { Id: station.Id });
    }
  }

  @AsyncErrorHandler
  private async getMooringStation() {
    if (this.id) {
      this.mooringStation = await this.mooringStationsService.get(this.id);
    }

    this.ready = true;
    this.applyValidationRules();
  }
}
