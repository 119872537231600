import { autoinject } from 'aurelia-framework';
import { OfferTabProps } from '../tabs';

@autoinject
export class Tab {
  data: OfferTabProps;

  activate(data: OfferTabProps) {
    console.log(data);
    this.data = data;
  }
}
