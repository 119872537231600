export const CommentForEntity = {
  AnchorLine: 1,
  AnchorLineComponent: 2,

  GridLine: 3,
  GridLineComponent: 4,

  Buoy: 5,
  BuoyComponent: 6,

  Bridle: 7,
  BridleComponent: 8,
} as const;

export type CommentForEntity = (typeof CommentForEntity)[keyof typeof CommentForEntity];

export class CreateComponentCommentDto {
  EntityId: number;
  Entity: CommentForEntity;
  Comment: string;
  MustBeResolved: boolean;
}

export class UpdateComponentCommentDto {
  Comment: string;
  MustBeResolved: boolean;
}

export class ResolveComponentCommentDto {
  Comment: string;
}

export class MooringComponentCommentDto {
  CommentId: number;
  EntityId: number;
  Entity: CommentForEntity;

  MustBeResolved: boolean;

  CreatedById: string;
  CreatedBy: string;

  ResolvedById: string;
  ResolvedBy: string;

  Created: Date;
  Updated: Date;

  Comment?: string;
  ResolvedComment?: string;
}
