import { autoinject } from 'aurelia-framework';
import { NetlogHttpClient } from 'lib/http';
import { QueryFilter } from 'lib/tables/DataFilter';
import { CreatedEntity } from 'models/BaseEntity';
import { CreateMooringTemplateDto, MooringTemplateDto, MooringTemplateListItemDto, UpdateMooringTemplateDto } from 'models/mooring';

@autoinject
export class MooringComponentTemplateService {
  constructor(private client: NetlogHttpClient) {}

  // Anchor line
  async getAnchorLineTemplate(id: number) {
    return await this.client.get<MooringTemplateDto>({
      url: `api/moorings/templates/anchor-lines/${id}`,
      model: MooringTemplateDto,
    });
  }

  async getAnchorLineTemplates(query: QueryFilter) {
    return await this.client.get<MooringTemplateListItemDto[]>({
      url: `api/moorings/templates/anchor-lines${query.asQueryString}`,
      model: MooringTemplateListItemDto,
    });
  }

  async createAnchorLineTemplate(dto: CreateMooringTemplateDto) {
    return await this.client.post<CreatedEntity>({
      url: `api/moorings/templates/anchor-lines`,
      data: dto,
      model: CreatedEntity,
    });
  }

  async updateAnchorLineTemplate(id: number, data: UpdateMooringTemplateDto) {
    return await this.client.patch({
      data,
      url: `api/moorings/templates/anchor-lines/${id}`,
    });
  }

  async deleteAnchorLineTemplate(id: number) {
    return await this.client.delete({
      url: `api/moorings/templates/anchor-lines/${id}`,
    });
  }

  // Grid line
  async getGridLineTemplate(id: number) {
    return await this.client.get<MooringTemplateDto>({
      url: `api/moorings/templates/grid-lines/${id}`,
      model: MooringTemplateDto,
    });
  }

  async getGridLineTemplates(query: QueryFilter) {
    return await this.client.get<MooringTemplateListItemDto[]>({
      url: `api/moorings/templates/grid-lines${query.asQueryString}`,
      model: MooringTemplateListItemDto,
    });
  }

  async createGridLineTemplate(dto: CreateMooringTemplateDto) {
    return await this.client.post<CreatedEntity>({
      url: `api/moorings/templates/grid-lines`,
      data: dto,
      model: CreatedEntity,
    });
  }

  async updateGridLineTemplate(id: number, data: UpdateMooringTemplateDto) {
    return await this.client.patch({
      data,
      url: `api/moorings/templates/grid-lines/${id}`,
    });
  }

  async deleteGridLineTemplate(id: number) {
    return await this.client.delete({
      url: `api/moorings/templates/grid-lines/${id}`,
    });
  }

  // Buoys
  async getBuoyTemplate(id: number) {
    return await this.client.get<MooringTemplateDto>({
      url: `api/moorings/templates/buoys/${id}`,
      model: MooringTemplateDto,
    });
  }

  async getBuoyTemplates(query: QueryFilter) {
    return await this.client.get<MooringTemplateListItemDto[]>({
      url: `api/moorings/templates/buoys${query.asQueryString}`,
      model: MooringTemplateListItemDto,
    });
  }

  async createBuoyTemplate(dto: CreateMooringTemplateDto) {
    return await this.client.post<CreatedEntity>({
      url: `api/moorings/templates/buoys`,
      data: dto,
      model: CreatedEntity,
    });
  }

  async updateBuoyTemplate(id: number, data: UpdateMooringTemplateDto) {
    return await this.client.patch({
      data,
      url: `api/moorings/templates/buoys/${id}`,
    });
  }

  async deleteBuoyTemplate(id: number) {
    return await this.client.delete({
      url: `api/moorings/templates/buoys/${id}`,
    });
  }

  // Bridles
  async getBridleTemplate(id: number) {
    return await this.client.get<MooringTemplateDto>({
      url: `api/moorings/templates/bridles/${id}`,
      model: MooringTemplateDto,
    });
  }

  async getBridleTemplates(query: QueryFilter) {
    return await this.client.get<MooringTemplateListItemDto[]>({
      url: `api/moorings/templates/bridles${query.asQueryString}`,
      model: MooringTemplateListItemDto,
    });
  }

  async createBridleTemplate(dto: CreateMooringTemplateDto) {
    return await this.client.post<CreatedEntity>({
      url: `api/moorings/templates/bridles`,
      data: dto,
      model: CreatedEntity,
    });
  }

  async updateBridleTemplate(id: number, data: UpdateMooringTemplateDto) {
    return await this.client.patch({
      data,
      url: `api/moorings/templates/bridles/${id}`,
    });
  }

  async deleteBridleTemplate(id: number) {
    return await this.client.delete({
      url: `api/moorings/templates/bridles/${id}`,
    });
  }
}
