import { autoinject } from 'aurelia-framework';
import { NetlogHttpClient } from 'lib/http';
import { DataFilter, QueryFilter } from 'lib/tables/DataFilter';
import { CreateEntityNoteDto, EntityNoteDto, UpdateEntityNoteDto } from 'models';
import { CreatedEntity } from 'models/BaseEntity';
import {
  CreateMooringOfferDto,
  MooringOfferDto,
  MooringOfferListItemDto,
  UpdateMooringOfferDto,
  CompleteReviewDto,
  UpdateReviewDto,
  MooringReviewDto,
  AbortReviewDto,
} from 'models/mooring';

@autoinject
export class MooringOfferService {
  constructor(private client: NetlogHttpClient) {}

  async getOffer(id: number) {
    return await this.client.get<MooringOfferDto>({
      url: `api/mooring-offers/${id}`,
      model: MooringOfferDto,
    });
  }

  async getOffers(query: QueryFilter) {
    return await this.client.get<MooringOfferListItemDto[]>({
      url: `api/mooring-offers${query.asQueryString}`,
      model: MooringOfferListItemDto,
    });
  }

  async createOffer(data: CreateMooringOfferDto) {
    return await this.client.post({
      data,
      url: 'api/mooring-offers',
      model: CreatedEntity,
    });
  }

  async updateOffer(id: number, data: UpdateMooringOfferDto) {
    return await this.client.patch({
      data,
      url: `api/mooring-offers/${id}`,
    });
  }

  async exportOfferNavision(offerId: number) {
    return await this.client.downloadBlob(`api/mooring-offers/${offerId}/export`);
  }

  // Review
  async sendForReview(offerId: number) {
    return await this.client.post({
      url: `api/mooring-offer-reviews/offer/${offerId}`,
      data: {},
    });
  }

  async getOfferReviews(offerId: number) {
    return await this.client.get<MooringReviewDto>({
      url: `api/mooring-offers/${offerId}/reviews`,
      model: MooringReviewDto,
    });
  }

  async getReview(reviewId: number) {
    return await this.client.get<MooringReviewDto>({
      url: `api/mooring-offer-reviews/${reviewId}`,
      model: MooringReviewDto,
    });
  }

  async completeReview(reviewId: number, dto: CompleteReviewDto) {
    return await this.client.post({
      url: `api/mooring-offer-reviews/${reviewId}/complete`,
      data: dto,
    });
  }

  async abortReview(reviewId: number, dto: AbortReviewDto) {
    return await this.client.post({
      url: `api/mooring-offer-reviews/${reviewId}/abort`,
      data: dto,
    });
  }

  async updateReview(reviewId: number, dto: UpdateReviewDto) {
    return await this.client.patch({
      url: `api/mooring-offer-reviews/${reviewId}`,
      data: dto,
    });
  }

  // async getComponentComments(reviewId: number, entityId: number, entity: CommentForEntity) {
  //   const params = new URLSearchParams();
  //   params.set('entity', entity.toString());
  //   params.set('entityId', entityId.toString());
  //
  //   return await this.client.get<MooringReviewCommentDto[]>({
  //     url: `api/mooring-offer-reviews/${reviewId}/comments?${params.toString()}`,
  //     model: MooringReviewCommentDto,
  //   });
  // }
  //
  // async createComponentComment(reviewId: number, dto: CreateComponentCommentDto) {
  //   return await this.client.post({
  //     url: `api/mooring-offer-reviews/${reviewId}/comments`,
  //     data: dto,
  //   });
  // }
  //
  // async deleteComponentComment(reviewId: number, commentId: number) {
  //   return await this.client.delete({
  //     url: `api/mooring-offer-reviews/${reviewId}/comments/${commentId}`,
  //   });
  // }
  //
  // async updateComponentComment(reviewId: number, commentId: number, dto: UpdateComponentCommentDto) {
  //   return await this.client.patch({
  //     url: `api/mooring-offer-reviews/${reviewId}/comments/${commentId}`,
  //     data: dto,
  //   });
  // }
  //
  // async resolveComponentComment(reviewId: number, commentId: number, dto: ResolveComponentCommentDto) {
  //   return await this.client.post({
  //     url: `api/mooring-offer-reviews/${reviewId}/comments/${commentId}/resolve`,
  //     data: dto,
  //   });
  // }

  async createNote(offerId: number, dto: CreateEntityNoteDto) {
    return await this.client.post({
      url: `api/mooring-offers/${offerId}/notes`,
      data: dto,
    });
  }

  async updateNote(offerId: number, noteId: number, dto: UpdateEntityNoteDto) {
    return await this.client.patch({
      url: `api/mooring-offers/${offerId}/notes/${noteId}`,
      data: dto,
    });
  }

  async deleteNote(offerId: number, noteId: number) {
    return await this.client.delete({
      url: `api/mooring-offers/${offerId}/notes/${noteId}`,
    });
  }

  async getNotes(offerId: number) {
    return await this.client.get<EntityNoteDto[]>({
      url: `api/mooring-offers/${offerId}/notes`,
      model: EntityNoteDto,
    });
  }
}
