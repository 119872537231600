const MooringReviewStatus = {
  Waiting: 1,
  Rejected: 2,
  Aborted: 3,
  Approved: 4,
};

type MooringReviewStatus = (typeof MooringReviewStatus)[keyof typeof MooringReviewStatus];

class BaseMooringReviewDto {
  ReviewId: number;

  CreatedBy: string;
  CreatedById: string;

  Created: Date;
  Updated: Date;

  ReviewedBy?: string;
  ReviewedById?: string;

  Status: MooringReviewStatus;
}

export class MooringReviewListItemDto extends BaseMooringReviewDto {
  Comments: number;
}

export class MooringReviewDto extends BaseMooringReviewDto {
  Comment?: string;
  CanCompleteReview: boolean;
}

export class UpdateReviewDto {
  Comment: string;
}

export class AbortReviewDto {
  Comment: string;
}

export class CompleteReviewDto {
  Comment: string;
  Approve: boolean;
}
