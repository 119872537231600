import { PLATFORM } from 'aurelia-framework';
import { roles } from 'routes';

export const report_routes = [
  {
    route: 'reports/service-storage-report',
    name: 'service-storage-report',
    settings: { roles: [roles.serviceuser], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/reports/service-storage-report') },
      side: { moduleId: null },
    },
    title: 'reports.servicestationstoragereportheader',
  },
  {
    route: 'reports/service-repairs-report',
    name: 'service-repairs-report',
    settings: { roles: [roles.serviceuser], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/reports/service-repairs-report') },
      side: { moduleId: null },
    },
    title: 'reports.servicerepairsreportheader',
  },
  {
    route: 'reports/service-antifouling-report',
    name: 'service-antifouling-report',
    settings: { roles: [roles.serviceuser], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/reports/service-antifouling-report') },
      side: { moduleId: null },
    },
    title: 'reports.serviceantifoulingreportheader',
  },
  {
    route: 'reports/service-statistics',
    name: 'service-statistics',
    settings: { roles: [roles.serviceuser], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/reports/service-statistics') },
      side: { moduleId: null },
    },
    title: 'reports.servicestatistics.title',
  },
  {
    route: 'reports/service-kpis',
    name: 'service-kpis',
    settings: { roles: [roles.serviceuser], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/reports/service-kpis') },
      side: { moduleId: null },
    },
    title: 'reports.servicekpis.title',
  },
  {
    route: 'reports/service-backlog',
    name: 'service-backlog',
    settings: { roles: [roles.serviceuser], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/reports/service-backlog') },
      side: { moduleId: null },
    },
    title: 'reports.servicebacklog.title',
  },
  {
    route: 'reports/service-month-report',
    name: 'service-month-report',
    settings: { roles: [roles.serviceuser], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/reports/service-month-report') },
      side: { moduleId: null },
    },
    title: 'reports.servicemonthreport.title',
  },
  {
    route: 'reports/service-week-report',
    name: 'service-week-report',
    settings: { roles: [roles.serviceuser], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/reports/service-week-report/service-week-report') },
      side: { moduleId: null },
    },
    title: 'reports.serviceweekreport.title',
  },
  {
    route: 'reports/newnet-month-hourly-consumption-and-impregnation',
    name: 'newnet-month-hourly-consumption-and-impregnation',
    settings: { roles: [roles.serviceuser], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/reports/newnet-month-report') },
      side: { moduleId: null },
    },
    title: 'reports.newnetmonthreport.title',
  },
  {
    route: 'reports/newnet-antifouling-report',
    name: 'newnet-antifouling-report',
    settings: { roles: [roles.serviceuser], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/reports/newnet-antifouling-report') },
      side: { moduleId: null },
    },
    title: 'reports.newnetantifouling.title',
  },
  {
    route: ['reports'],
    name: 'reports',
    nav: true,
    settings: { roles: [roles.serviceuser], searchable: true },
    viewPorts: {
      main: { moduleId: PLATFORM.moduleName('components/reports/reports') },
      side: { moduleId: null },
    },
    title: 'general.reports',
    route_base: 'reports',
    section: 'admin',
  },
];
