import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, bindable, observable } from 'aurelia-framework';
import { RopeTypeService } from 'models';
import { ErrorService } from 'services/error-service';
import { ProductService } from 'services/product-service';
import { RopeDimensionService } from 'services/rope-dimension-service';
import { RopeHardnessService } from 'services/rope-hardness-service';

@autoinject
export class RopeTypeForm {
  @bindable
  @observable
  private ropetype: RopeTypeService;
  ropetypeChanged(ropeType: RopeTypeService) {
    if (ropeType?.Id) {
      void this.setProductName(ropeType.ProductId);
    }
  }

  protected articleNo = '';
  protected isDuplicate = false;

  constructor(
    protected ropeDimensionService: RopeDimensionService,
    protected ropeHardnessService: RopeHardnessService,
    private productService: ProductService,
    private errorService: ErrorService,
    private eventAggregator: EventAggregator
  ) {}

  private attached() {
    this.eventAggregator.subscribe('ropeTypeServiceIsDuplicate', (isDuplicate) => {
      this.isDuplicate = isDuplicate;
    });
  }

  protected setProductId(event: { detail: { value: string } }) {
    const productId = Number(event.detail.value);
    this.ropetype.ProductId = productId;
    void this.setProductName(productId);
  }

  private async setProductName(productId: number) {
    try {
      if (productId) {
        const product = await this.productService.get(productId);
        this.ropetype.Name = product.Name;
        this.articleNo = product.ArticleNo;
      }
    } catch (error) {
      this.errorService.handleError(error);
    }
  }
}
