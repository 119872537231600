import { DialogController, DialogService } from 'aurelia-dialog';
import { autoinject } from 'aurelia-framework';
import './mooting-create-offer-dialog.scss';
import { ValidationController, Validator } from 'aurelia-validation';
import { Router } from 'aurelia-router';
import { StandardValidationBuilder } from 'lib/validation';
import { MooringOfferService, MooringService } from 'services/mooring';
import { CreateMooringOfferDto, CreateMooringSiteSetupDto } from 'models/mooring';
import { AsyncErrorHandler } from 'lib/ui';
import { CustomerService } from 'services/customer-service';
import { SiteService } from 'services/site-service';
import { AnalysisCompanyService, ToastService } from 'services';
import { I18N } from 'aurelia-i18n';
import { PubSub } from 'lib/event/PubSub';
import { ContactService } from 'services/contact-service';
import { CustomerDialog } from 'features/contact/customer-dialog';
import { UserService } from 'services/user-service';
import { UserModels } from 'models/UserModels';

const dummyy = new CreateMooringOfferDto();
dummyy.Title = 'Forankringsanalyse anlegg twoday';
dummyy.SiteSetup = new CreateMooringSiteSetupDto();
dummyy.CustomerId = 455;
dummyy.ContactId = 316;
dummyy.SiteId = 1431;
dummyy.StatusId = 1;
dummyy.AnalysisReference = 'Reference';
dummyy.Hs = 1.2;
dummyy.Vc = 0.5;
dummyy.SiteNumber = 'SiteNumber 01';
dummyy.AnalysisReference = 'AnalysisReference 02';

dummyy.SiteSetup.NoCagesLength = 3;
dummyy.SiteSetup.NoCagesWidth = 3;
dummyy.SiteSetup.CageSize = 100;
dummyy.SiteSetup.GridLineLength = 3;
dummyy.SiteSetup.MooringDepth = 3;
dummyy.SiteSetup.NetDepth = 3;
dummyy.SiteSetup.NoAnchorLines = 3;
dummyy.SiteSetup.NoBridles = 3;

@autoinject
export class MooringCreateOfferDialog {
  protected model: CreateMooringOfferDto = undefined;

  protected offerId?: number;

  constructor(
    private dialogSerivce: DialogService,
    private dialogControl: DialogController,
    private ruleBuilder: StandardValidationBuilder,
    private toastService: ToastService,
    protected customerService: CustomerService,
    protected siteService: SiteService,
    protected validationController: ValidationController,
    protected validator: Validator,
    protected mooringOfferService: MooringOfferService,
    protected mooringService: MooringService,
    private contactService: ContactService,
    private router: Router,
    private t: I18N,
    private pubSub: PubSub,
    private analysisCompanyService: AnalysisCompanyService,
    private userService: UserService
  ) {
    this.model = new CreateMooringOfferDto();
    this.model.SiteSetup = new CreateMooringSiteSetupDto();
    this.createValidationRules();
  }

  protected applyTestData() {
    this.model = dummyy;
    this.createValidationRules();
  }

  @AsyncErrorHandler
  protected async getOffer(id: number) {
    const { data: offer } = await this.mooringOfferService.getOffer(id);

    this.model.Title = offer.Title;

    this.model.Hs = offer.Hs;
    this.model.Vc = offer.Vc;
    this.model.CustomerId = offer.CustomerId;
    this.model.ContactId = offer.ContactId;
    this.model.SiteNumber = offer.SiteNumber;
    this.model.SiteId = offer.SiteId;
    this.model.SalesPersonId = offer.SalesPersonId;

    this.model.CrmReference = offer.CrmReference;
    this.model.AnalysisReference = offer.AnalysisReference;

    this.model.AnalysisCompanyId = offer.AnalysisCompanyId;

    this.model.SiteSetup.CageSize = offer.SiteSetup.CageSize;

    this.model.SiteSetup.NoBridles = offer.SiteSetup.NoBridles;
    this.model.SiteSetup.ReliabilityClass = offer.SiteSetup.ReliabilityClass;

    this.model.SiteSetup.NoAnchorLines = offer.SiteSetup.NoAnchorLines;
    this.model.SiteSetup.GridLineLength = offer.SiteSetup.GridLineLength;

    this.model.SiteSetup.PenCircumference = offer.SiteSetup.PenCircumference;

    this.model.SiteSetup.MiddleGapWidth = offer.SiteSetup.MiddleGapWidth;
    this.model.SiteSetup.HasGapMiddle = offer.SiteSetup.HasGapMiddle;

    this.model.SiteSetup.NoCagesWidth = offer.SiteSetup.NoCagesWidth;
    this.model.SiteSetup.NoCagesLength = offer.SiteSetup.NoCagesLength;
    this.model.SiteSetup.NetDepth = offer.SiteSetup.NetDepth;
    this.model.SiteSetup.MooringDepth = offer.SiteSetup.MooringDepth;

    this.model.SiteSetup.NEPositionLat = offer.SiteSetup.NEPositionLat;
    this.model.SiteSetup.NEPositionLong = offer.SiteSetup.NEPositionLong;
    this.model.SiteSetup.SEPositionLat = offer.SiteSetup.SEPositionLat;
    this.model.SiteSetup.SEPositionLong = offer.SiteSetup.SEPositionLong;
    this.model.SiteSetup.NWPositionLat = offer.SiteSetup.NWPositionLat;
    this.model.SiteSetup.NWPositionLong = offer.SiteSetup.NWPositionLong;
    this.model.SiteSetup.SWPositionLat = offer.SiteSetup.SWPositionLat;
    this.model.SiteSetup.SWPositionLong = offer.SiteSetup.SWPositionLong;

    this.model.SiteSetup.CenterPositionLong = offer.SiteSetup.CenterPositionLong;
    this.model.SiteSetup.CenterPositionLat = offer.SiteSetup.CenterPositionLat;
  }

  protected activate(args?: { id?: number }) {
    if (!args || !args.id) return;
    this.offerId = args.id;
    void this.getOffer(args.id);
  }

  @AsyncErrorHandler
  async getAnalysisCompanies() {
    this.analysisCompanyService.getCompanies();
  }

  @AsyncErrorHandler
  async onSave() {
    const { valid } = await this.validationController.validate();
    if (!valid) return;

    if (this.offerId) {
      await this.mooringOfferService.updateOffer(this.offerId, this.model);
      this.toastService.showSuccess(this.t.tr('general.updated'));
      this.pubSub.publish('entity:updated', null);
    } else {
      const { data } = await this.mooringOfferService.createOffer(this.model);
      this.toastService.showSuccess(this.t.tr('general.created'));
      this.router.navigate(`mooring/offers/${data.Id}`);
    }
    this.onCancel();
  }

  onCancel() {
    void this.dialogControl.cancel();
  }

  protected async getCustomerInfo(id: number) {
    const customer = await this.customerService.get(id);
    return {
      id: customer.Id,
      title: customer.Name,
    };
  }

  protected async findCustomer(searchText: string) {
    const customers = await this.customerService.getAll(`?$filter=contains(toLower(Name), '${searchText}')`);
    return customers
      .filter((c) => c.Name.includes(searchText))
      .map((c) => ({
        id: c.Id,
        title: c.Name,
      }))
      .sort((a, b) => a.title.localeCompare(b.title));
  }

  protected customerSelected(customer: { id: number }) {
    this.model.CustomerId = customer.id;
    this.model.ContactId = undefined;
  }

  protected async getContactInfo(id: number) {
    const contact = await this.contactService.get(id);
    return {
      id: contact.Id,
      title: contact.Name,
    };
  }

  protected async findContact(searchText: string) {
    const contacts = await this.contactService.getAll(`?$filter=CustomerId eq ${this.model.CustomerId}`);
    return contacts
      .filter((c) => c.Name.toLowerCase().includes(searchText.toLowerCase()))
      .map((c) => ({
        id: c.Id,
        title: c.Name,
      }))
      .sort((a, b) => a.title.localeCompare(b.title));
  }

  protected contactSelected(contact: { id: number }) {
    this.model.ContactId = contact.id;
  }

  @AsyncErrorHandler
  protected async getAnalysisCompanyInfo(id: number) {
    const { data: analysisCompany } = await this.analysisCompanyService.getCompany(id);
    return {
      id: analysisCompany.Id,
      title: analysisCompany.Name,
    };
  }

  @AsyncErrorHandler
  protected async findAnalysisCompany(searchText: string) {
    const { data: analysisCompanys } = await this.analysisCompanyService.getCompanies();
    return analysisCompanys
      .filter((c) => c.Name.toLowerCase().includes(searchText.toLowerCase()))
      .map((c) => ({
        id: c.Id,
        title: c.Name,
      }))
      .sort((a, b) => a.title.localeCompare(b.title));
  }

  protected analysisCompanySelected(analysisCompany: { id: number }) {
    this.model.AnalysisCompanyId = analysisCompany.id;
  }

  @AsyncErrorHandler
  protected async getSellerInfo(id: string) {
    const { data: sellers } = await this.userService.getMooringSellers();
    const seller = sellers.find((x) => x.Id == id);
    return {
      id: seller.Id,
      title: seller.Name,
    };
  }

  private sellers: UserModels.UserSimple[] = [];

  @AsyncErrorHandler
  protected async findSeller(searchText: string) {
    if (!this.sellers?.length) {
      const { data: sellers } = await this.userService.getMooringSellers();
      this.sellers = sellers;
    }
    return this.sellers
      .filter((c) => c.Name.toLowerCase().includes(searchText.toLowerCase()))
      .map((c) => ({
        id: c.Id,
        title: c.Name,
      }))
      .sort((a, b) => a.title.localeCompare(b.title));
  }

  protected sellerSelected(seller: { id: string }) {
    this.model.SalesPersonId = seller.id;
  }

  @AsyncErrorHandler
  protected async getSiteInfo(id: number) {
    const site = await this.siteService.get(id);
    return {
      id: site.Id,
      title: site.Name,
    };
  }

  protected async findSite(searchText: string) {
    let customerIdFilter = '';
    if (this.model.CustomerId) {
      customerIdFilter = ` AND CustomerId eq ${this.model.CustomerId}`;
    }
    const sites = await this.siteService.getAll(`?$filter=contains(toLower(Name), '${searchText}')${customerIdFilter}`);
    return sites
      .filter((s) => s.Name.includes(searchText))
      .map((s) => ({
        id: s.Id,
        title: s.Name,
        returns: s.CustomerId,
      }))
      .sort((a, b) => a.title.localeCompare(b.title));
  }

  protected siteSelected(site: { id: number; returns: number }) {
    this.model.SiteId = site.id;
    if (site.returns && this.model.CustomerId != site.returns) {
      this.customerSelected({ id: site.returns });
    }
  }

  protected editCustomer() {
    if (!this.model.CustomerId) return;
    void this.dialogSerivce
      .open({
        viewModel: CustomerDialog,
        model: {
          customerId: this.model.CustomerId,
        },
        position: () => {
          return {
            top: 0,
          };
        },
      })
      .whenClosed(() => {});
  }

  private createValidationRules() {
    this.ruleBuilder
      .with(this.model)
      .required('Title')
      .required('CustomerId')
      .required('SiteId')
      .required('AnalysisCompanyId')
      .min('Hs', 0)
      .max('Hs', 10)
      .min('Vc', 0)
      .max('Vc', 10)
      .done();

    this.ruleBuilder
      .with(this.model.SiteSetup)
      .required('NoCagesLength')
      .min('NoCagesLength', 0)
      .max('NoCagesLength', 20)
      .required('NoCagesWidth')
      .min('NoCagesWidth', 0)
      .max('NoCagesWidth', 20)
      .required('CageSize')
      .min('CageSize', 20)
      .max('CageSize', 300)
      .required('GridLineLength')
      .min('GridLineLength', 1)
      .max('GridLineLength', 1000)
      .required('MooringDepth')
      .min('MooringDepth', 1)
      .max('MooringDepth', 1000)
      .required('NetDepth')
      .min('NetDepth', 1)
      .max('NetDepth', 250)
      .required('NoAnchorLines')
      .min('NoAnchorLines', 1)
      .max('NoAnchorLines', 150)
      .required('NoBridles')
      .min('NoBridles', 1)
      .max('NoBridles', 10)
      .required('PenCircumference')
      .min('PenCircumference', 1)
      .max('PenCircumference', 350)
      .required('MiddleGapWidth')
      .min('MiddleGapWidth', 0)
      .max('MiddleGapWidth', 50)
      .done();
  }
}
